import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { MdOutlineClose } from 'react-icons/md';

import { Surveys } from 'src/Api';
import { HomeContext } from 'src/Contexts';

import Auth from '../../../Constants/Auth';

const SurveyModal = ({ status, questions, closeModal, surveyTitle }) => {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    return status === 'completed'
      ? questions?.surveyData.selectedOptions ||
          Array(questions?.surveyData?.questions?.length).fill('')
      : Array(questions?.length)?.fill('');
  });

  const context = useContext(HomeContext);

  const isCompletedSurvey = status === 'completed';

  const surveyQuestions = isCompletedSurvey
    ? questions?.surveyData?.surveyQuestions
    : questions;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(
    isCompletedSurvey ? false : true,
  );
  const [cookies] = useCookies([Auth.JWT_TOKEN]);
  const token = cookies[Auth.JWT_TOKEN];
  const handleOptionChange = (event) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[currentQuestionIndex] = event.target.value;
    setSelectedOptions(updatedSelectedOptions);
    setIsNextDisabled(false);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < surveyQuestions?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (isCompletedSurvey) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    } else {
      handleAPI({ surveyQuestions, selectedOptions, title: surveyTitle });
      closeModal();
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsNextDisabled(false);
      selectedOptions[currentQuestionIndex] = '';
    }
  };

  const handleAPI = (data) => {
    // Here, you can implement the logic to send the data (questions and selected options) to your API
    if (status === 'completed') {
      Surveys.updateStatus(questions?.id, token, data)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          context.fetchSubmittedSurveys();
        });
    } else {
      Surveys.createSurvey(token, data)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          context.fetchSubmittedSurveys();
        });
    }
  };

  return (
    <div className='fixed inset-0 flex items-center justify-center z-50'>
      <div
        className='absolute inset-0 bg-gray-900 opacity-50'
        onClick={closeModal}
      />
      <div className='bg-white w-full mx-5 md:w-[550px] p-6 rounded-lg shadow-lg relative'>
        <button
          className='text-gray-600 hover:text-gray-800 absolute top-2 right-2'
          onClick={closeModal}
        >
          <MdOutlineClose size={24} />
        </button>
        <p className='text-2xl font-semibold mt-2'>
          {surveyQuestions?.[currentQuestionIndex]?.question}
        </p>
        <div className='mt-2'>
          {surveyQuestions?.[currentQuestionIndex]?.options?.map(
            (option, index) => (
              <label className='flex items-center' key={index}>
                <input
                  type='radio'
                  value={option}
                  checked={selectedOptions[currentQuestionIndex] === option}
                  onChange={handleOptionChange}
                />
                <span className='ml-2'>{option}</span>
              </label>
            ),
          )}
        </div>
        <div className='flex justify-between'>
          <button
            className={`my-3 border-2 text-DarkBluePurple rounded-lg py-2 px-3 ${
              currentQuestionIndex === 0 && 'opacity-50 cursor-not-allowed'
            }`}
            onClick={handlePrevQuestion}
            disabled={currentQuestionIndex === 0}
          >
            Back
          </button>
          <button
            className={`my-3 border-2 text-DarkBluePurple rounded-lg py-2 px-3 ${
              isNextDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextQuestion}
            disabled={isNextDisabled}
          >
            {currentQuestionIndex < surveyQuestions?.length - 1
              ? 'Next'
              : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyModal;
