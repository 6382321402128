import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import BasicPane from '../Profile/BasicPane';
import SkillsUpdateSection from '../SkillsUpdateSection';

const SkillsInputForm = (props) => {
  return (
    <Grid
      component={BasicPane}
      placeholderText={props.placeholderText}
      placeholderImage={props.placeholderImage}
      icon={<AddIcon fontSize={'small'} color='secondary' />}
      title={'Skills'}
      onAction={() => props.onOpen()}
      container
    >
      <Typography variant='h4'>Rate your skills</Typography>
      <SkillsUpdateSection
        data={props.data}
        onChange={(newData) => props.onChange(newData)}
      />
    </Grid>
  );
};

SkillsInputForm.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

SkillsInputForm.defaultProps = {
  data: [],
  onChange: () => {},
};

export default SkillsInputForm;
