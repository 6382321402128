import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NotificationItem from '../../../Components/NotificationItem';
import Card from '../../../Components/Profile/Card';
import { HomeContext } from '../../../Contexts';

const useStyles = makeStyles(() => ({
  content: {
    padding: '4vh 4vw',
  },
  contentContainer: {
    paddingTop: '8vh',
  },
  header: {
    fontSize: '1.5rem',
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    color: '#505050',
    margin: '2vh 0',
  },
  notifContainer: {
    margin: '4vh 0',
  },
}));

const Details = (props) => {
  const classes = useStyles();

  const context = useContext(HomeContext);

  useEffect(() => {
    context.markRead(props.location.state.notification);
  }, [props.location.state.notification]);

  const [notification, setNotification] = useState(
    props.location.state.notification,
  );

  useEffect(() => {
    setNotification(props.location.state.notification);
  }, [props.location.state.notification]);

  return (
    <Grid direction='row' container>
      <Grid xs={1} md={3} item />
      <Grid xs={10} className={classes.contentContainer} md={6} item>
        <Card variant={'contained'}>
          <Grid className={classes.content} container>
            <Grid xs={12} item>
              <Typography className={classes.header} variant='h4'>
                Notifications
              </Typography>
            </Grid>
            <Grid xs={12} className={classes.notifContainer} item>
              <NotificationItem
                id={props.match.params.section}
                dateTime={notification.date}
                description={notification.description}
                type={notification.type}
                title={notification.title}
                wrapped={false}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid xs={1} md={3} item />
    </Grid>
  );
};

Details.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default withRouter(Details);
