import React, { useContext, useEffect, useState } from 'react';
import array from 'lodash/array';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { COMMON } from 'src/Constants';
import { AppContext } from 'src/Contexts';

import Button from './Button';
import ChipsInputForm from './ChipsInputForm';
import Dialog from './Dialog';

const InterestInputDialog = (props) => {
  const handleDelete = (id, data, setter) => {
    setter(data.filter((item) => item.id !== id));
  };

  const [interestsState, setInterestsState] = useState(props.interests);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(true);
  const context = useContext(AppContext);

  useEffect(() => {
    if (props.interests?.length) setInterestsState(props.interests);
  }, [props.interests, loader, error]);

  const addNewInterest = (item, data, setter) => {
    if (array.findIndex(data, ['id', item.id]) !== -1) return;
    setter([...data, item]);
  };

  const onSubmit = async () => {
    if (interestsState.length === 0) {
      return context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
    await props.onSubmitted(interestsState);
    setLoader(!loader);
  };

  const onClose = () => {
    // setInterestsState([]);
    props.onClose();
  };

  return (
    <Dialog
      title='Choose Your Area of Interest'
      open={props.open}
      onClose={onClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <ChipsInputForm
            variant='interests'
            onNewItem={(item) => {
              setError(false);
              addNewInterest(item, interestsState, setInterestsState);
            }}
            inputPlaceholder={'Add Interest'}
            data={interestsState}
            onDeleteItem={(id) =>
              handleDelete(id, interestsState, setInterestsState)
            }
          />
        </Grid>
        <Grid
          // style={{ padding: '0 5%' }}
          xs={12}
          direction='row'
          justify='flex-end'
          item
          container
        >
          <Button onClick={onSubmit} title='Save' />
        </Grid>
      </Grid>
    </Dialog>
  );
};

InterestInputDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
  interests: PropTypes.array,
};

InterestInputDialog.defaultProps = {
  interests: [],
};

export default InterestInputDialog;
