import React, { useContext } from 'react';

import PathwayCard from 'src/Components/v1/PathwayCard';
import { HomeContext } from 'src/Contexts';
const RecommendedPathways = () => {
  const { userPathways } = useContext(HomeContext);

  return (
    <>
      <div
        className={`${
          userPathways?.length
            ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
            : 'flex flex-wrap'
        } mt-5`}
      >
        {userPathways?.length ? (
          userPathways?.map((pathway, index) => (
            <>
              <div key={index} className=' my-3 '>
                <PathwayCard pathway={pathway} index={index} />
              </div>
            </>
          ))
        ) : (
          <div className='mx-auto my-40 flex'>
            <h1 className='text-xl mx-5 font-semibold'>Coming Soon....</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default RecommendedPathways;
