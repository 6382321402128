import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import notifGeneral from '../Assets/images/notif-general.svg';
import notifSkillscore from '../Assets/images/notif-skillscore.svg';
import notifSurvey from '../Assets/images/notif-survey.svg';

const useStyles = makeStyles(() => ({
  container: {
    flexWrap: 'inherit',
  },
  infoContainer: {
    flex: 1,
    margin: '0 1vw',
  },
  titleStyle: {
    fontFamily: 'Avenir-Heavy',
    fontSize: '16px',
    fontWeight: '900',
  },
  dateStyle: {
    fontFamily: 'Avenir-Roman',
    fontSize: '14px',
    color: '#8c8c8c',
  },
  subtitleStyle: (props) => ({
    fontSize: '14px',
    fontFamily: 'Avenir-Roman',
    color: '#8c8c8c',
    margin: '4px 0',
    textAlign: 'left',
    height: props.wrapped ? '2.5rem' : 'auto',
    overflow: props.wrapped ? 'hidden' : 'visible',
  }),
}));

const Variant = {
  SkillScore: 'skillscore',
  General: 'general',
  Survey: 'survey',
};

const Icon = {
  [Variant.SkillScore]: notifSkillscore,
  [Variant.General]: notifGeneral,
  [Variant.Survey]: notifSurvey,
};

const NotificationItem = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      direction='row'
      alignItems='flex-start'
      onClick={() => props.onAction(props.id)}
      className={classes.container}
      container
    >
      <Grid className={classes.imageContainer} item>
        <img
          style={{ width: '46px', height: '46px' }}
          alt={`${props.type}`}
          src={Icon[props.type]}
        />
      </Grid>
      <Grid className={classes.infoContainer} item>
        <Grid
          alignItems='flex-start'
          justify='flex-start'
          direction='column'
          container
        >
          <Grid style={{ width: '100%' }} item>
            <Grid justify='space-between' direction='row' container>
              <Grid item>
                <Typography className={classes.titleStyle} variant='h5'>
                  {props.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.dateStyle} variant='h5'>
                  {props.dateTime}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.subtitleStyle} variant='subtitle1'>
              {props.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  type: PropTypes.oneOf([Object.values(Variant)]).isRequired,
  onAction: PropTypes.func,
  wrapped: PropTypes.bool,
};

NotificationItem.defaultProps = {
  wrapped: true,
};

export default withRouter(NotificationItem);
