/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import avatar from 'src/Assets/images/v1/icons/courses.webp';

import View from '../../Assets/images/v1/icons/view.svg';
import Button from '../Button';

import Modal from './Modal/Modal';
const PathwayCard = ({ pathway, index }) => {
  const [showModal, setShowModal] = useState(-1);

  const toggleModal = (index) => {
    setShowModal(index);
  };
  return (
    <>
      <div
        className={
          'border font-[karla-regular] h-full bg-white md:p-0 roundedCard'
        }
      >
        <img
          className='w-full h-56 roundedCard'
          src={pathway.image_url ?? avatar}
        />
        <div className='px-2 h-28'>
          <h1 className='my-4 overflow-hidden text-lg w-full truncate overflow-ellipsis text-gray-900 font-bold'>
            {pathway?.title}
          </h1>
          {pathway?.category ? (
            <p className=' w-full truncate overflow-ellipsis'>
              Category:{' '}
              <span className='font-bold capitalize'>{pathway?.category}</span>
            </p>
          ) : null}
          {pathway?.description ? (
            <p className='w-full truncate overflow-ellipsis'>
              Description:{' '}
              <span className='font-bold' title={pathway?.description}>
                {pathway?.description}
              </span>
            </p>
          ) : null}
        </div>
        <hr className='my-2' />
        <div className='flex justify-end my-3'>
          <button
            className='mx-3 md:px-4 px-2 border-2 rounded-lg py-2  bg-DarkBluePurple text-white'
            onClick={() => toggleModal(index)}
          >
            <div className='flex '>
              <img className='mr-1' src={View} alt='' />
              <p>View</p>
            </div>
          </button>
        </div>
      </div>
      <Modal isOpen={showModal === index} className='lg:!w-[50%]'>
        <div className='w-full md:w-fit xl:w-full py-6 font-[karla-regular] rounded-lg bg-white'>
          <div className='px-4 md:px-6'>
            <div className='flex flex-col md:flex-row justify-between mt-6'>
              <div className='flex-1 w-full md:w-[40%]'>
                <img
                  className='w-full min-h-[200px] h-56 roundedCard'
                  src={pathway.image_url ?? avatar}
                />
              </div>
              <div className='flex-1 md:w-[60%]'>
                <div className='mx-3 overflow-hidden'>
                  <h1 className='text-2xl w-full mt-6 md:mt-0 text-DarkBluePurple font-bold'>
                    {pathway.title}
                  </h1>
                </div>
                <div className='mx-3 mt-3 mb-1'>
                  <h1 className='text-lg'>Learn the following resources.</h1>
                </div>
                <div className='mx-3 overflow-y-scroll h-36'>
                  {pathway?.resources_url?.map(({ name, url }, index) => (
                    <div key={index}>
                      <a
                        href={url}
                        target='_blank'
                        rel='noreferrer'
                        className='text-DarkBluePurple'
                      >
                        <span className='font-semibold'>{index + 1}.</span>{' '}
                        <span className=' hover:underline  font-semibold'>
                          {name}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className=' flex justify-end mt-4'>
              <Button
                onClick={() => {
                  toggleModal(-1);
                }}
                title='Close'
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PathwayCard;
