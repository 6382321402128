import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'Avenir-Roman',
    fontSize: '1rem',
    textAlign: 'center',
    color: '#505050',
  },
  icon: {
    margin: '1vh 0',
  },
  ctaAction: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const EmptyPlaceholder = (props) => {
  const classes = useStyles(props);

  return (
    <Grid direction='column' justify='center' alignItems='center' container>
      <Grid xs={12}>
        <img className={classes.icon} src={props.image} alt={props.text} />
      </Grid>
      <Grid>
        <Typography className={classes.text}>
          {props.text}{' '}
          <span
            className={classes.ctaAction}
            disabled={!props?.ctaAction}
            onClick={props.ctaAction}
          >
            {props.ctaLabel}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

EmptyPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default EmptyPlaceholder;
