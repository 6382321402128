import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from './Dialog';

const useStyles = makeStyles(() => ({
  container: {
    fontSize: '16px',
    color: '#505050',
    fontFamily: 'Avenir',
  },
}));

const CareerOpportunityDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      dashboard={true}
      open={props.open}
      onClose={props.onClose}
      title='Geographic Opportunities'
    >
      <Grid className={classes.container}>
        <p>
          In the dynamic realm of job hunting, geographic opportunities play a
          pivotal role in shaping your career path. To enhance your Geographic
          Opportunities Score and open doors to exciting prospects:
        </p>
        <br />
        <p className='text-lg font-semibold text-DarkBluePurple'>
          Improve Your Geographic Opportunities Score
        </p>
        <br />

        <div>
          <div className='font-semibold text-DarkBluePurple '>
            1. Research and Targeting:
          </div>
          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Identify regions or cities aligned with your career goals and
              industry preferences.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Narrow down your job search to specific geographical regions for
              focused efforts.
            </p>
          </div>
          <p></p>
        </div>
        <br />
        <div>
          <div className='font-semibold text-DarkBluePurple '>
            2. Network and Connect:
          </div>
          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Build a solid professional network in your chosen geographic area.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Connect with local professionals on LinkedIn for job insights and
              to expand your network.
            </p>
          </div>
          <div className='flex'>
            <span>c.</span>
            <p className='ml-1'>
              Leverage your network for insights on job openings, company
              cultures, and the local job market.
            </p>
          </div>
        </div>
        <br />
        <div>
          <div className='font-semibold text-DarkBluePurple '>
            3. Skill Enhancement:
          </div>

          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Tailor your skill set to meet local job market demands.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Identify skills or certifications in high demand in your target
              area.
            </p>
          </div>
          <div className='flex'>
            <span>c.</span>
            <p className='ml-1'>
              Invest in professional development and online courses to enhance
              qualifications.
            </p>
          </div>
        </div>
      </Grid>
    </Dialog>
  );
};

CareerOpportunityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CareerOpportunityDialog.defaultProps = {};

export default CareerOpportunityDialog;
