import React from 'react';
import { TiStar } from 'react-icons/ti';

const StarRating = ({
  rating,
  setRating,
  enableRating,
  iconSize,
  ratedStarsColor = '',
}) => {
  const getColorClass = (index) => {
    const ratedColor = ratedStarsColor ? ratedStarsColor : 'text-yellow-400';
    const unRatedColor = 'text-gray-400';
    return index < rating ? ratedColor : unRatedColor;
  };

  const handleRatingClick = (index) => {
    if (enableRating && setRating) {
      setRating(index + 1);
    }
  };
  const stars = Array.from({ length: 5 }, (_, index) => (
    <TiStar
      key={index}
      size={iconSize}
      className={getColorClass(index)}
      onClick={() => handleRatingClick(index)}
    />
  ));

  return (
    <div className={`${enableRating ? 'flex cursor-pointer' : 'flex'}`}>
      {stars}
    </div>
  );
};

export default StarRating;
