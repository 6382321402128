import React, { useContext, useEffect, useState } from 'react';
// import { useCookies } from 'react-cookie';
import { useHistory, withRouter } from 'react-router-dom';

import { PAYMENT_STATUS } from 'src/Constants/Common';
// import UserJobs from 'src/Api/GetJobs';
import { AppContext, HomeContext } from 'src/Contexts';

import TabbedContainer from '../../Components/TabbedContainer';

// import { Auth as AuthConstants } from '../../Constants';
import RecommendedJobs from './RecommendedJobs';
import SavedJobs from './SavedJobs';

const Jobs = () => {
  // const [userJobs, setUserJobs] = useState(null);
  const [jobText, setJobText] = useState(
    'Career opportunities tailored just for you',
  );
  const { user, userPayment } = useContext(AppContext);
  const homeContext = useContext(HomeContext);
  const verifiedEmail = user?.email_verified;
  const history = useHistory();
  const paymentHistory = userPayment?.payment_status;

  useEffect(() => {
    homeContext.getSavedJobs();
  }, []);

  useEffect(() => {
    if (!verifiedEmail || paymentHistory === PAYMENT_STATUS.NOT_PAID) {
      return history.push('/home');
    }
  }, [verifiedEmail, paymentHistory]);

  useEffect(() => {
    if (homeContext?.userProfile) {
      homeContext.getUserJobs();
    }
  }, [homeContext?.userProfile]);

  return (
    <div className='lg:ml-80 '>
      <h1 className='text-3xl font-bold my-9 lg:mx-10 mx-3'>Jobs</h1>
      <h2 className='text-xl font-semibold text-SSBlack lg:mx-10 mx-3'>
        {jobText}
      </h2>
      <div className='mx-2 lg:mx-8'>
        <TabbedContainer
          tabs={[
            {
              name: 'Recommended Jobs',
              content: (
                <RecommendedJobs
                  userJobs={homeContext.userJobs}
                  jobDescription={{
                    city: homeContext.userJobs?.city,
                    title: homeContext.userJobs?.field,
                  }}
                />
              ),
            },
            {
              name: 'Favourite Jobs',
              content: (
                <SavedJobs
                  userJobs={homeContext.userJobs}
                  jobDescription={{
                    city: homeContext.userJobs?.city,
                    title: homeContext.userJobs?.field,
                  }}
                />
              ),
            },
          ]}
          setState={setJobText}
          name='job'
        />
      </div>
    </div>
  );
};

export default withRouter(Jobs);
