import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const UploadFile = (props) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        props.onImageUploaded({
          file: acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
      }
    },
    className: 'dropzone',
    activeClassName: 'active-dropzone',
    multiple: false,
    accept: 'image/*',
  });
  return (
    <Grid {...getRootProps()} className='flex flex-col' container>
      <input {...getInputProps()} />
      {props.image ? (
        <Box>
          <img
            alt='Image'
            src={props.image?.preview ? props.image.preview : props.image}
            className={`h-24 w-24 object-cover ${props.imageStyle} `}
          />
        </Box>
      ) : (
        <Box>
          <CameraAltIcon color='action' className=' cursor-pointer' />
        </Box>
      )}
      {props.typography}
    </Grid>
  );
};

UploadFile.propTypes = {
  typography: PropTypes.any,
  image: PropTypes.any,
  onImageUploaded: PropTypes.any,
};

export default UploadFile;
