import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  container: {
    '&:hover $buttonContainer': {
      display: 'flex',
    },
  },
  infoContainer: {
    flex: 1,
    margin: '0 1vw',
  },
  image: {
    width: '3vw',
    height: '3vw',
  },
  dateStyle: {
    opacity: 0.5,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#505050',
    marginTop: '4px',
  },
  titleStyle: {
    fontFamily: 'Avenir-Heavy',
    fontSize: '1em',
    fontWeight: '900',
  },
  subtitleStyle: {
    fontSize: '14px',
    fontFamily: 'Avenir-Roman',
    color: '#505050',
    marginTop: '4px',
  },
  buttonContainer: (props) => ({
    display: props.isSticky ? 'flex' : 'none',
    padding: '0.3vw',
    marginLeft: '0.2vw',
    borderRadius: '50%',
    border: '1px solid #D5D2D6',
    zIndex: 3,
    color: '#A9A9A9',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}));

const ProjectsItem = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      direction='row'
      alignItems='center'
      className={classes.container}
      container
    >
      <Grid item>
        <img
          style={{ width: '3vw', height: '3vw' }}
          alt={`${props.title}`}
          src={
            props.image && props.image.length > 0
              ? props.image
              : 'https://via.placeholder.com/200/556270/FFFFFF?text=No+Image'
          }
        />
      </Grid>
      <Grid className={classes.infoContainer} direction='column' item>
        <Grid direction='column' container>
          <Typography className={classes.titleStyle} variant='h5'>
            {props.title}
          </Typography>
          <Typography className={classes.subtitleStyle} variant='subtitle1'>
            {!props.subtitle || props.subtitle === 'null' ? '' : props.subtitle}
          </Typography>
          {props?.startYear ? (
            <div className=' text-gray-500'>
              {props.startYear}- {!props.endYear ? 'On Going' : props.endYear}
            </div>
          ) : null}
          {props.link ? (
            <Typography className={classes.dateStyle} variant='subtitle1'>
              URL: {props.link}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item>
        <Grid direction='row' container>
          <ButtonBase
            className={classes.buttonContainer}
            onClick={() => props.onEdit(props.id)}
          >
            <CreateTwoToneIcon color='inherit' />
          </ButtonBase>
          <ButtonBase
            className={classes.buttonContainer}
            onClick={() => props.onDelete(props.id)}
          >
            <DeleteIcon color='inherit' />
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectsItem.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  isSticky: PropTypes.bool,
};

ProjectsItem.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
  isSticky: false,
};

export default ProjectsItem;
