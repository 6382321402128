import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Dialog from './Dialog';

const DeletionDialog = (props) => {
  const HandleCancel = () => {
    props.onNegativeAction();
    props.onClose();
  };
  const HandleDelete = () => {
    props.onPositiveAction();
    props.onClose();
  };
  return (
    <Dialog title='Delete' open={props.open} onClose={props.onClose}>
      <div>
        <h1>Are you sure you want to delete this item?</h1>
        <div className='flex justify-end'>
          <div className='mx-3'>
            <Button onClick={HandleCancel} title='Cancel' />
          </div>
          <Button onClick={HandleDelete} title='Delete' />
        </div>
      </div>
    </Dialog>
  );
};

DeletionDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPositiveAction: PropTypes.func,
  onNegativeAction: PropTypes.func,
};

DeletionDialog.defaultProps = {
  onPositiveAction: () => {},
  onNegativeAction: () => {},
};

export default DeletionDialog;
