import Api from './index';

const Projects = {
  allProjects: (user_id) => {
    return Api.post('/projects', { user_id });
  },

  updateProjects: (body) => {
    return Api.post('/projects/submitAssessment', body);
  },
};

export default Projects;
