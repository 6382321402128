import React from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  slider: {
    margin: '0 8px',
  },
  text: {
    fontSize: '14px',
    fontFamily: 'Avenir-Roman',
    color: '#2b2b2b',
  },
}));

const SkillsInputItem = (props) => {
  const classes = useStyles();

  return (
    <Grid alignItems='center' container>
      <Grid xs={4}>
        <Typography className={classes.text}>• {props.title}</Typography>
      </Grid>
      <Grid xs={6}>
        {/* <Slider
          aria-labelledby='discrete-slider-always'
          className={classes.slider}
          step={1}
          value={props.rate}
          onChange={(event, value) => props.onChange(value)}
          marks={true}
          min={1}
          max={5}
          valueLabelDisplay='auto'
        /> */}
      </Grid>
      <Grid xs={1} item />
      <Grid xs={1} justify='flex-end' onClick={props.onDelete} item container>
        <IconButton>
          <CancelIcon color='action' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

SkillsInputItem.propTypes = {
  title: PropTypes.string.isRequired,
  rate: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

SkillsInputItem.defaultProps = {
  rate: 1,
  onDelete: () => {},
  onChange: () => {},
};

export default SkillsInputItem;
