import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import Chip from '../Chip';

import BasicPane from './BasicPane';

const useStyles = makeStyles(() => ({
  desc: {
    lineHeight: '1.2em',
  },
}));

const ChipsPane = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      component={BasicPane}
      placeholderText={props.placeholderText}
      placeholderImage={props.placeholderImage}
      icon={<AddIcon fontSize={'small'} color='secondary' />}
      title={props.title}
      onEditAction={props.onEditAction}
      onAction={props.onAction}
      container
    >
      {props.data && props.data.length > 0 ? (
        <Grid
          component={Box}
          className={classes.desc}
          style={{ overflow: 'visible', height: 'auto' }}
          item
        >
          <Grid direction='row' container>
            {props.data.map((item, index) => (
              <Grid item key={index}>
                <Chip
                  label={`${item.name}`}
                  onDelete={() => props.onDeleteItem(item)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

ChipsPane.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  placeholderText: PropTypes.string,
  placeholderImage: PropTypes.string,
  onAction: PropTypes.func,
  onEditAction: PropTypes.func,
  onSubmitted: PropTypes.func,
  onDeleteItem: PropTypes.func,
};

export default ChipsPane;
