import React from 'react';
import PropTypes from 'prop-types';

import { Button as MuiButton, Grid } from '@material-ui/core';

import Button from '../Button';

const Footer = (props) => {
  return (
    <Grid direction='row' justify='space-between' alignItems='center' container>
      <MuiButton
        size='medium'
        variant='containedSecondary'
        color='primary'
        onClick={props.onBack}
      >
        {props.backText}
      </MuiButton>
      <Button
        size='medium'
        variant='contained'
        color='primary'
        performAction={async () => await Promise.resolve(props.onNext())}
      >
        {props.nextText}
      </Button>
    </Grid>
  );
};

Footer.propTypes = {
  onNext: PropTypes.func.isRequired,
  backText: PropTypes.string.isRequired,
  nextText: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  backText: 'Back',
  nextText: 'Next',
};

export default Footer;
