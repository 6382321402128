import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import SummaryForm from './SummaryForm';

const SummaryInputDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} title='Summary'>
      <SummaryForm
        isNewSummary={props.isNewSummary}
        summary={props.summary}
        onSubmitted={() => props.onClose()}
      />
    </Dialog>
  );
};

SummaryForm.propTypes = {
  summary: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isNewSummary: PropTypes.bool.isRequired,
  onSubmitted: PropTypes.func,
};

export default SummaryInputDialog;
