/* eslint-disable max-lines */
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isToday, parseISO } from 'date-fns';

import FavoriteIcon from '@material-ui/icons/Favorite';

import UserJobs from 'src/Api/GetJobs';
import { HomeContext } from 'src/Contexts';
import { formatJobDate, getLocalUser } from 'src/Utils/utils';

import activeIcon from '../../Assets/images/jobs/active-icon.svg';
import earlyIcon from '../../Assets/images/jobs/early-icon.svg';
import avatar from '../../Assets/images/v1/icons/job-icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import LoaderButton from '../LoaderButton';

import JobDescriptionModal from './JobDescriptionModal';

const JobsCard = ({
  jobs: job,
  saved,
  index,
  jobDescription,
  isSearchableJob,
}) => {
  const [showModal, setShowModal] = useState(-1);
  const homeContext = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const isJobPostedToday = job?.jobPosting
    ? isToday(parseISO(job?.jobPosting))
    : false;

  const toggleModal = (index) => {
    setShowModal(index);
  };

  const savedJobHandler = async (link) => {
    const parsedData = await getLocalUser();
    UserJobs.save_job(token, {
      link: link,
      userId: parsedData.user.id,
    })
      .then(() => {
        homeContext.getUserJobs();
        homeContext.getSavedJobs();
      })
      .catch(() => {});
  };

  const gettingJobDescription = async (link) => {
    await UserJobs.getJobDescription(token, {
      link: link,
    })
      .then((response) => {
        if (response?.data?.data)
          setDescription(response?.data?.data?.description);
      })
      .catch(() => {});
  };

  const isSaved = homeContext?.userSavedJobs?.some(
    (savedJob) => savedJob.link === job.href,
  );

  return (
    <div className='border h-full font-[karla-regular] rounded-lg mx-0 bg-white p-3 '>
      <div>
        <div className='flex flex-col md:flex-row my-3 md:justify-between'>
          <div className='flex flex-col justify-between sm:flex-row w-full'>
            <div className='flex'>
              <img
                className='h-12 rounded-full bg-DarkBluePurple '
                src={job?.image || avatar}
              />
              <div className='mx-3 h-28'>
                <h2
                  className='text-xl font-semi-bold h-7 overflow-hidden !truncate !overflow-ellipsis'
                  title={job?.jobTitle}
                >
                  {job?.jobTitle?.length > 22 ? (
                    <>{job?.jobTitle.substring(0, 22)}..... </>
                  ) : (
                    job?.jobTitle
                  )}
                </h2>
                <p className='text-gray-500'>{job?.companyName}</p>
                <p className='text-gray-500'>
                  {job?.location || jobDescription?.location}
                </p>
              </div>
            </div>
            {isJobPostedToday ? (
              <p className='rounded-2xl whitespace-nowrap border-2 px-2 h-7 border-BlueColorButton bg-lightBlueButton text-center text-BlueColorButton'>
                New job
              </p>
            ) : null}
          </div>
        </div>
        {job?.jobPosting && (
          <p className='text-lighterGrey'>{formatJobDate(job?.jobPosting)}</p>
        )}

        <div className=' flex justify-center border-2 border-gray-200 truncate overflow-ellipsis rounded-xl whitespace-nowrap text-center w-full p-1 my-2'>
          <img
            src={job?.tag === 'Actively Hiring' ? activeIcon : earlyIcon}
            alt='icon'
          />
          <p className='ml-2'>{job?.tag || 'Be an early applicant'}</p>
        </div>

        <p className='w-full text-justify overflow-hidden h-full'>
          {
            'In order to view job description in detail, please visit the site by clicking on "Apply on site" button.'
          }
        </p>
      </div>
      <hr className='my-5' />

      <div className='flex mx-3 md:mx-0 h-10 items-center md:justify-end my-2'>
        {!isSearchableJob && (
          <div>
            {saved ? (
              <div>
                <FavoriteIcon
                  onClick={() => {
                    savedJobHandler(job.href);
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }}
                  className={`mx-3 cursor-pointer ${
                    isSaved ? 'text-DarkBluePurple' : 'text-gray-400'
                  }`}
                  fontSize={'large'}
                />
              </div>
            ) : (
              <div className='flex items-center'>
                <button
                  className={`${
                    isSaved ? 'text-DarkBluePurple' : 'text-gray-400'
                  }`}
                  color={isSaved ? 'text-DarkBluePurple' : 'action'}
                  onClick={() => {
                    savedJobHandler(job.href);
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 1000);
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <LoaderButton />
                  ) : (
                    <FavoriteIcon
                      className={`mx-3 cursor-pointer ${
                        isSaved || saved
                          ? 'text-DarkBluePurple'
                          : 'text-gray-400'
                      }`}
                      fontSize={'large'}
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        )}

        <button
          onClick={() => {
            toggleModal(index);
            gettingJobDescription(job.href);
          }}
          className='mx-3 border-2 bg-DarkBluePurple text-white rounded-lg px-5 py-2'
        >
          Apply
        </button>
      </div>

      <JobDescriptionModal
        {...{
          showModal,
          index,
          setShowModal,
          setDescription,
          description,
          job,
          toggleModal,
        }}
      />
    </div>
  );
};
export default JobsCard;
