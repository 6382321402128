import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Ubuntu',
    fontWeight: '600',
    fontSize: '1.25rem',
    color: 'inherit',
  },
}));

const Title = (props) => {
  const classes = useStyles(props);

  return (
    <Typography className={classes.text} variant='h3'>
      {props.children}
    </Typography>
  );
};

export default Title;
