import React, { useContext, useState } from 'react';

import Refer from 'src/Api/Refer';
import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';
import SearchInput from 'src/Components/v1/SearchInput';
import { HomeContext } from 'src/Contexts';

const PromoCodeDialog = ({
  isOpen,
  onClose,
  setDiscountStatus,
  setPromoCodeModal,
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [discountError, setDiscountError] = useState(false);
  const { token } = useContext(HomeContext);

  const handleCoupon = async () => {
    setDiscountError(false);
    try {
      const response = await Refer.couponStatus(token, promoCode);
      setDiscountStatus(response?.data?.data?.percent_off);
      if (!response?.data?.data?.valid) {
        setDiscountError(true);
      } else {
        const discountResponse = await Refer.updateDiscount(
          token,
          response?.data?.data?.percent_off,
          response?.data?.data?.id,
        );
        setPromoCodeModal(false);

        if (discountResponse.data.data.data.url) {
          window.location.href = discountResponse.data.data.data.url;
        }
      }
      setPromoCode('');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      title='Add Promo Code'
      open={isOpen}
      onClose={() => {
        onClose();
        setDiscountError(false);
        setPromoCode('');
      }}
    >
      <div>
        <div className='w-full'>
          <SearchInput
            inputStyle='!w-full'
            placeholder='Promo Code'
            value={promoCode}
            onChange={setPromoCode}
          />
          {discountError && (
            <p className='text-red-500 text-sm font-semibold ml-1'>
              Invalid Code
            </p>
          )}
        </div>
        <div className='flex justify-end'>
          <Button onClick={handleCoupon} title='Add' />
        </div>
      </div>
    </Dialog>
  );
};

export default PromoCodeDialog;
