import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import DeleteIcon from '@material-ui/icons/Delete';

import { AppContext } from 'src/Contexts';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import skillsCancelIcon from '../../Assets/images/v1/icons/profile cancel icon.svg';
import skillsSelect from '../../Assets/images/v1/icons/skill-select.svg';
import { Auth as AuthConstants } from '../../Constants';
import Button from '../Button';
import Dialog from '../Dialog';
import IconButton from '../IconButton';
import InterestInputDialog from '../InterestInputDialog';
import Loader from '../Loader';

import ProfileEmptyCard from './ProfileEmptyCard';

const InterestCard = ({ text, onDelete, className, isSelected }) => {
  return (
    <div
      onClick={() => onDelete()}
      className={`border mx-2 my-2 cursor-pointer py-1 border-gray-300  px-2 rounded-lg flex justify-between ${className}`}
    >
      <h1 className='mx-1'>{text}</h1>
      <img src={isSelected ? skillsSelect : skillsCancelIcon} alt='' />
    </div>
  );
};

const ProfileInterestCard = ({
  user,
  getProfile,
  loader,
  placeholderImage,
  placeholderText,
}) => {
  const [openInterest, setOpenInterest] = useState(false);
  const [interests, setInterests] = useState([]);
  const [interestSelected, setInterestSelected] = useState([]);
  const [deleteInterestModal, setDeleteInterestModal] = useState(false);

  const context = useContext(AppContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const updateUser = () => context.updateUser(token);
  useEffect(() => {
    setInterests(
      user?.area_of_interest?.length ? JSON.parse(user?.area_of_interest) : [],
    );
    // user?.user_interests?.map((interest) => setInterests([interest]));
  }, [user]);

  const refreshInterests = (data) => {
    const interests = [];
    data?.forEach((interest) => {
      interests.push(interest);
    });
    setInterests(interests);
    updateUser();
    getProfile();
  };

  const handleInterestsUpdate = async (interests) => {
    try {
      await User.updateInterests(interests, token);
      refreshInterests(interests);
      setOpenInterest(false);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  // const handleDeleteInterests = (data) => {
  //   User.deleteInterests(data.id, token).then(
  //     (res) => {
  //       refreshInterests(res.data.data);
  //     },
  //     () => {},
  //   );
  // };

  const onSelectDelete = (interest) => {
    if (
      interestSelected?.some(
        (selectedInterest) => selectedInterest.id === interest.id,
      )
    ) {
      setInterestSelected(
        interestSelected?.filter(
          (selectedInterest) => selectedInterest.id !== interest.id,
        ),
      );
    } else {
      setInterestSelected([...interestSelected, interest]);
    }
  };

  const renderInterest = () => {
    if (interests?.length) {
      return (
        <div className='flex justify-between items-end'>
          <div className='flex flex-wrap w-10/12'>
            {interests?.map((interest, index) => {
              const isSelected = interestSelected.some(
                (selectedInterest) => selectedInterest.id === interest.id,
              );
              return (
                <InterestCard
                  key={index}
                  onDelete={() => onSelectDelete(interest)}
                  text={interest.name}
                  className={isSelected ? 'bg-DarkBluePurple text-white' : {}}
                  isSelected={isSelected}
                />
              );
            })}
          </div>
          {interestSelected?.length ? (
            <IconButton onClick={() => setDeleteInterestModal(true)}>
              <DeleteIcon className='-mt-1' color='inherit' fontSize='small' />
            </IconButton>
          ) : null}
        </div>
      );
    }
    return <ProfileEmptyCard {...{ placeholderText, placeholderImage }} />;
  };

  const deleteInterestUpdate = async () => {
    const updatedInterest = interests.filter((skill) => {
      return !interestSelected.some(
        (selectedSkill) => selectedSkill.id === skill.id,
      );
    });

    await handleInterestsUpdate([...updatedInterest]);
    setDeleteInterestModal(false);
    setInterestSelected([]);
  };

  const selectAllHandler = () => {
    setInterestSelected([...interests]);
  };

  return (
    <div id='interests' className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>Area of Interests</h1>
        <div className='flex items-center'>
          {interests?.length ? (
            <p
              className='border mx-2 my-1 cursor-pointer border-lightBlue text-lightBlue  px-2 rounded-lg flex justify-between'
              onClick={selectAllHandler}
            >
              Select All
            </p>
          ) : null}

          <img
            className='cursor-pointer'
            onClick={() => setOpenInterest(true)}
            src={addIcon}
            alt=''
          />
        </div>
      </div>
      {loader ? (
        <div className='flex justify-center'>
          <Loader />
          <h1 className='mt-1'>Getting your Interests</h1>
        </div>
      ) : (
        renderInterest()
      )}
      <InterestInputDialog
        onSubmitted={handleInterestsUpdate}
        interests={interests}
        open={openInterest}
        onClose={() => setOpenInterest(false)}
      />
      <Dialog
        title='Delete'
        open={deleteInterestModal}
        onClose={() => setDeleteInterestModal(false)}
      >
        <div>
          <h1>
            Are you sure you want to delete{' '}
            {interestSelected.length > 1 ? 'these items' : 'this item'}.
          </h1>

          <div className='flex justify-end'>
            <div className='mx-3'>
              <Button
                onClick={() => setDeleteInterestModal(false)}
                title='Cancel'
              />
            </div>
            <Button onClick={deleteInterestUpdate} title='Delete' />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ProfileInterestCard;
