import { useContext, useState } from 'react';

import Feedback from 'src/Api/Feedback';
import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';
import { HomeContext } from 'src/Contexts';

import StarRating from './star';

const AddReviewModal = ({ open, onClose, updateFeedbackHandler }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const { token } = useContext(HomeContext);

  const reviewHandler = async () => {
    const response = await Feedback.addFeedback(token, {
      message: review,
      rating,
    });
    if (response) {
      setReview('');
      setRating(0);
      onClose();
      updateFeedbackHandler();
    }
  };

  const closeHandler = () => {
    setReview('');
    onClose();
    setRating(0);
  };

  return (
    <Dialog title='Rate & Review' open={open} onClose={closeHandler}>
      <div>
        <div className='my-1'>
          <p className='font-semibold mb-1 text-xl'>Give a rating</p>
          <StarRating
            rating={rating}
            setRating={setRating}
            enableRating
            iconSize={35}
            ratedStarsColor={'text-DarkBluePurple'}
          />
        </div>
        <div>
          <p className='font-semibold my-3 text-xl'>Give a review</p>
          <textarea
            value={review}
            placeholder='Write your review'
            onChange={(e) => {
              if (e.target.value.length <= 500) {
                setReview(e.target.value);
              }
            }}
            className='border w-full rounded-md h-32 p-2.5 text-sm focus:outline-none resize-none font-libreBaskerville'
            // disabled={loading}
          />
          <div className='text-xs text-right text-gray-500 mt-2 '>
            {review.length}/{500}
          </div>
        </div>
        <div className='flex justify-end'>
          <Button
            disabled={!review || !rating}
            onClick={reviewHandler}
            title='Submit'
            className='disabled:cursor-not-allowed disabled:opacity-80'
          />
        </div>
      </div>
    </Dialog>
  );
};

export default AddReviewModal;
