import React from 'react';

import Dialog from '../../../Components/Dialog';

import Update from './Update';

const ProfileDialog = (props) => {
  const { onClose } = props;

  return (
    <Dialog isProfileClose open={props.open} onClose={onClose} width='100%'>
      <div>
        <Update isProfileCompletion={true} />
      </div>
    </Dialog>
  );
};

export default ProfileDialog;
