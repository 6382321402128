import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';
import Dialog from '../Dialog';
import SkillsUpdateSection from '../SkillsUpdateSection';

const useStyles = makeStyles(() => ({
  textCaseTransform: {
    textTransform: 'uppercase',
  },
  heading: {
    fontSize: '16px',
    fontFamily: 'Avenir',
    fontWeight: '900',
    color: '#505050',
  },
}));

const SkillsInputForm = (props) => {
  const classes = useStyles();
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onSubmit = async () => {
    await props.onChange(data);
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      title='Edit Skills'
    >
      <Grid direction='column' alignItems='stretch' container>
        {/* <Typography className={classes.heading} variant='subtitle1'>
          Rate your skills
        </Typography> */}
        <SkillsUpdateSection
          data={data}
          onChange={(newData) => setData(newData)}
        />
        <Grid justify='flex-end' direction='row' item container>
          <Button
            fullWidth={false}
            size='medium'
            variant='contained'
            color='primary'
            onClick={async () => await onSubmit()}
            className={classes.textCaseTransform}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

SkillsInputForm.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

SkillsInputForm.defaultProps = {
  data: [],
  open: false,
};

export default SkillsInputForm;
