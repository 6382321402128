import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HiEllipsisVertical } from 'react-icons/hi2';
import { MdDownload, MdReceiptLong } from 'react-icons/md';
import moment from 'moment';

import { EasyPaisa, Stripe } from 'src/Assets/images/dashboard';
import { PAYMENT_METHODS, PREMIUM_PLAN } from 'src/Constants/Common';
import { formatDate } from 'src/Utils/utils';

const PaymentList = ({
  startDate,
  endDate,
  total,
  link,
  download,
  planId,
  isLastIndex,
  discount,
  paymentType,
  currency,
  isActive,
  canEasyPaisaBeActive,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const plans = PREMIUM_PLAN.find((plan) => plan.priceId === planId);

  const [duration, unit] = useMemo(
    () => plans?.duration.split(' ') || [],
    [plans],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isEasyPaisaPlan = paymentType === PAYMENT_METHODS.EASY_PAISA;
  let totalAmount;
  let discountAmount;
  const currencySymbol = useMemo(() => {
    return currency && currency.toUpperCase() === 'PKR' ? 'Rs.' : '$';
  }, []);

  if (isEasyPaisaPlan) {
    totalAmount = `Rs.${total}`;
    discountAmount = `Rs.${discount}`;
  } else {
    totalAmount = `${currencySymbol}${total / 100}`;
    discountAmount = `${currencySymbol}${discount / 100}`;
  }

  return (
    <div className='flex items-center bg-white rounded-lg p-3 shadow-lg hover:shadow-xl w-[900px] md:w-full transition duration-300 relative'>
      {/* if isActive show active badge */}
      {isActive && (
        <div className='hidden lg:flex justify-center items-center absolute right-5 '>
          <h5 className='text-sm bg-orange-400 text-primaryBg font-semibold rounded-xl px-3 py-1'>
            Active
          </h5>
        </div>
      )}
      {isEasyPaisaPlan &&
        canEasyPaisaBeActive &&
        moment(startDate * 1000)
          .add(parseInt(duration), unit === 'Year' ? 'years' : 'months')
          .isAfter(moment(Date.now())) && (
          <div className='hidden lg:flex justify-center items-center absolute right-5 '>
            <h5 className='text-sm bg-orange-400 text-primaryBg font-semibold rounded-xl px-3 py-1'>
              Active
            </h5>
          </div>
        )}
      <div className='font-semibold w-[13%]'>
        {plans && plans.title
          ? plans?.title.replace('Plan', '')
          : 'Discontinued Plan'}
      </div>
      <div className='font-semibold w-[15%] capitalize text-green-400'>
        <div
          className={`bg-green-100 w-24 px-2 py-1 flex justify-center rounded-full ${
            isEasyPaisaPlan ? 'bg-green-100' : 'bg-purple-100'
          }`}
        >
          {isEasyPaisaPlan ? (
            <img src={EasyPaisa} className='w-24  ' />
          ) : (
            <img src={Stripe} className='w-24 h-6' />
          )}
        </div>
      </div>
      <div
        className={`font-semibold pl-3 w-[14%] ${
          isEasyPaisaPlan ? 'text-green-600' : 'text-primaryBg'
        }`}
      >
        {totalAmount}
      </div>
      <div
        className={`font-semibold pl-8 w-[14%] ${
          isEasyPaisaPlan ? 'text-green-600' : 'text-primaryBg'
        }`}
      >
        {discountAmount}
      </div>
      <div className='font-semibold pl-4 w-[14%]'>{formatDate(startDate)}</div>
      <div className='font-semibold pl-5 w-[14%]'>
        {duration
          ? moment(startDate * 1000)
              .add(parseInt(duration), unit === 'Year' ? 'years' : 'months')
              .format('MMMM D, YYYY')
          : moment(endDate * 1000).format('MMMM D, YYYY')}
      </div>

      <div className='relative' ref={dropdownRef}>
        <div
          onClick={() => (isEasyPaisaPlan ? {} : setOpen(!open))}
          className={`text-primaryBg mx-2 pl-6  hover:underline ml-3 ${
            isEasyPaisaPlan ? ' cursor-not-allowed' : 'cursor-pointer'
          }`}
        >
          <HiEllipsisVertical size={28} />
        </div>
        {open ? (
          <div
            className={`absolute right-0 left-6 w-44 z-10 bg-white border border-gray-200 rounded shadow-lg ${
              isLastIndex ? 'bottom-6' : 'top-6'
            } `}
          >
            <div onClick={() => setOpen(false)}>
              <a
                href={link}
                target='_blank'
                className='flex px-4 py-2 text-primaryBg'
                rel='noreferrer'
              >
                <MdReceiptLong size={24} />
                <span className='mx-2'>View Receipt</span>
              </a>
            </div>
            <div onClick={() => setOpen(false)}>
              <a href={download} className='flex px-4 py-2 text-primaryBg'>
                <MdDownload size={24} /> <span className='mx-2'>Download</span>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentList;
