import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Feedback from 'src/Api/Feedback';
import Button from 'src/Components/Button';
import Loader from 'src/Components/Loader';
import { HomeContext } from 'src/Contexts';

import AddReviewModal from './AddReviewModal';
import ReviewCard from './ReviewCard';

const Reviews = () => {
  const [addReviewModal, setAddReviewModal] = useState(false);
  const [page, setPage] = useState(1);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, userProfile } = useContext(HomeContext);

  const loadMore = () => setPage((prevPage) => prevPage + 1);

  const reviewsFilter = feedback?.filter(
    (user) => userProfile?.role === 'admin' || user.user_id === userProfile?.id,
  );
  const reviewList = reviewsFilter?.map((data, index) => (
    <div key={index} className='mb-4'>
      <ReviewCard
        name={data?.user?.name}
        picture={data?.user?.image_url}
        rating={data.rating}
        date={data.createdAt}
        message={data.message}
      />
    </div>
  ));

  const updateFeedbackHandler = async () => {
    const response = await Feedback.get(token, { page: 1 });
    setFeedback(response.rows);
  };

  const getFeedback = async () => {
    try {
      setLoading(true);
      const response = await Feedback.get(token, { page });
      setFeedback((prevFeedback) => [...prevFeedback, ...response.rows]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeedback();
  }, [page]);

  return (
    <InfiniteScroll
      dataLength={feedback?.length}
      next={loadMore}
      hasMore={true}
      loader={loading}
    >
      <div className='flex lg:ml-80 flex-col lg:flex-row'>
        <div className='w-full lg:w-[calc(100% - 240px)] px-3 lg:px-10 py-4'>
          <div className='flex justify-between mx-2 items-center'>
            <div>
              <p className='text-4xl my-2 md:my-9 font-semibold'>Reviews</p>
            </div>
            <div>
              <Button
                onClick={() => {
                  setAddReviewModal(true);
                }}
                title='Submit Review'
                className='mb-4'
              />
            </div>
          </div>
          {loading && page === 1 ? (
            <div className='flex justify-center items-center w-full h-[70vh]'>
              <Loader />
            </div>
          ) : (
            <div>
              {reviewsFilter?.length ? (
                <div className='flex gap-4 my-4 lg:flex-row md:flex-col-reverse sm:flex-col-reverse max-sm:flex-col-reverse'>
                  <div className='bg-white w-full lg:w-[calc(100% - 10px)] rounded-[20px] py-4 flex flex-col justify-between'>
                    {reviewList}
                  </div>
                </div>
              ) : (
                <div className=' h-[600px]  flex justify-center items-center'>
                  <p className=' text-xl font-semibold'>No Reviews Found</p>
                </div>
              )}
            </div>
          )}
          <div></div>
        </div>

        <AddReviewModal
          open={addReviewModal}
          onClose={() => setAddReviewModal(false)}
          updateFeedbackHandler={updateFeedbackHandler}
        />
      </div>
    </InfiniteScroll>
  );
};

export default Reviews;
