import Api from './index';

const Tests = {
  allTests: (user_id) => {
    return Api.post('/tests', { user_id });
  },
  updateTests: (body) => {
    return Api.post('/tests/submitAssessment', body);
  },
};

export default Tests;
