import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import AssessmentProjectDetails from './AssessmentProjectDetail';

const useStyles = makeStyles(() => ({
  dialogContent: {
    marginRight: '2vw',
    marginLeft: '2vw',
    marginBottom: '4vh',
  },
  dialogTitle: {
    marginLeft: '2vw',
    marginTop: '4vh',
  },
  titleStyle: {
    fontFamily: 'Ubuntu',
    fontSize: '33px',
    fontWeight: '500',
    color: '#707070',
  },
}));

const AssessmentProjectDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      BackdropProps={{ style: { opacity: '0.5' } }}
      PaperProps={{ style: { minWidth: '75vw', borderRadius: '0.5vw' } }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
        <Grid
          container
          direction={'row'}
          justify={'space-between'}
          alignItems='flex-start'
        >
          <Grid style={{ flex: 1 }} item>
            <Typography className={classes.titleStyle} variant='h2'>
              {props.title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <AssessmentProjectDetails
          isTest={props.isTest}
          onSubmitProject={props.onSubmitProject}
          onAction={props.onVisit}
          description={props.description}
          skills={props.skills}
          image={props.image}
          link={props.link}
          duration={props.duration}
          author={props.author}
          skillLevel={props.skillLevel}
          {...props}
        />
      </DialogContent>
    </Dialog>
  );
};

AssessmentProjectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  skillLevel: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
  skills: PropTypes.array,
  title: PropTypes.string,
  onVisit: PropTypes.func,
};
AssessmentProjectDialog.defaultProps = {
  image: null,
};

export default AssessmentProjectDialog;
