import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card as MuiCard } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: () => ({
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    boxShadow: '0 5px 24px 0 rgba(0, 0, 0, 0.08)',
  }),
}));

const Card = (props) => {
  const classes = useStyles(props);

  return (
    <Box component={MuiCard} className={classes.card} item>
      {props.children}
    </Box>
  );
};

Card.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined']),
};

Card.defaultProps = {};

export default Card;
