import React from 'react';

const Avatar = ({ text, image }) => {
  if (image) {
    return <img className='h-10 w-10 rounded-full' src={image} alt='avatar' />;
  }
  const words = text?.split(' ');
  const Initials = words?.slice(0, 2).map((word) => word[0]?.toUpperCase());
  return (
    <div className='border border-gray-200 bg-gray-100 font-semibold text-gray-400 h-10 w-10 flex justify-center items-center rounded-full'>
      {Initials}
    </div>
  );
};
export default Avatar;
