import React, { useContext, useMemo } from 'react';

import EmptyCard from 'src/Components/v1/EmptyCard';
import { PAYMENT_CARD_HEADINGS } from 'src/Constants';
import PaymentList from 'src/Containers/Payment/PaymentList';
import { AppContext } from 'src/Contexts';

const Heading = ({ heading, invoice }) => {
  return (
    <h1
      className={`${
        invoice ? 'w-1/4' : 'w-1/5'
      } text-base md:text-lg text-white font-semibold`}
    >
      {heading}
    </h1>
  );
};

const PaymentHistory = () => {
  const { userPayment } = useContext(AppContext);
  const payment = userPayment?.invoices || [];
  const userSubId = useMemo(() => {
    return userPayment?.subscription_id;
  }, [userPayment]);
  const easyPaisaPaymentHistory =
    userPayment?.easy_paisa_transaction_history || [];
  const paymentHistory = [...payment, ...easyPaisaPaymentHistory];
  const canEasyPaisaBeActive = useMemo(() => {
    return userPayment.payment_method !== 'stripe';
  }, [userPayment]);
  const paymentHistoryTable = () => {
    if (paymentHistory?.length) {
      paymentHistory.sort((a, b) => b.startDate - a.startDate);
      return paymentHistory?.map((item, index) => {
        const isLastIndex = index === paymentHistory?.length - 1;
        const isActive = userSubId === item.subscription_id;
        return (
          <div className='my-3 py-2' key={index}>
            <PaymentList
              {...item}
              isLastIndex={isLastIndex}
              isActive={isActive}
              canEasyPaisaBeActive={canEasyPaisaBeActive}
            />
          </div>
        );
      });
    }
    return (
      <div className='h-[400px] flex justify-center items-center'>
        <EmptyCard text='No Payment History Found' />
      </div>
    );
  };

  return (
    <div className='mt-5 overflow-x-scroll '>
      <div className='flex bg-primaryBg text-white rounded-t-lg p-3  w-[900px] md:w-full'>
        {PAYMENT_CARD_HEADINGS?.map((heading, index) => (
          <Heading key={index} heading={heading} />
        ))}
      </div>
      <div>{paymentHistoryTable()}</div>
    </div>
  );
};

export default PaymentHistory;
