import React, { useContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import { AppContext } from 'src/Contexts';
import { mapEducation } from 'src/Utils/utils';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import DeletionDialog from '../DeletionDialog';
import EducationInputDialog from '../EducationInputDialog';
import Loader from '../Loader';

import DesignationCard from './DesignationCard';
import ProfileEmptyCard from './ProfileEmptyCard';

const ProfileEducationCard = ({
  user,
  getProfile,
  loader,
  placeholderImage,
  placeholderText,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingLoader, setDeletingLoader] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState(null);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const context = useContext(AppContext);
  const updateUser = () => context.updateUser(token);

  const educations = useMemo(
    () => (Array.isArray(user?.education) ? mapEducation(user.education) : []),
    [user?.education],
  );

  const refreshEducation = () => {
    updateUser();
    getProfile();
  };

  const handleEducationUpdate = async (data) => {
    try {
      const res = await User.updateEducation(data, token, { id: data.id });
      refreshEducation(res?.data?.data);
    } catch (e) {
      console.error('Failed:', e);
    }
  };
  const handleEdit = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };

  const handleDeleteEducation = (data) => {
    setDeletingLoader(true);

    User.deleteEducation(data.id, token)
      .then((res) => {
        refreshEducation(res?.data?.data);
        setDeletingLoader(false);
        setDeletingIndex(null);
      })
      .catch((err) => {
        setDeletingLoader(false);
        setDeletingIndex(null);
        console.error('Delete Education: ', err);
      });
  };
  const renderEducation = () => {
    if (educations?.length) {
      return educations?.map((data, index) => {
        return (
          <DesignationCard
            key={index}
            title={data.degree_name}
            field={data.field_name}
            grade={data.grade}
            description={data.university_name}
            location={data.location}
            startYear={data.start_year}
            endYear={data.end_year}
            loader={deletingLoader}
            isDeleting={deletingIndex === index}
            onEdit={() => handleEdit(data)}
            onDelete={() => {
              setConfirmModalOpen(true);
              setSelectedItem(data);
              setDeletingIndex(index);
            }}
          />
        );
      });
    }
    return <ProfileEmptyCard {...{ placeholderImage, placeholderText }} />;
  };

  return (
    <div id='education' className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>Education</h1>
        <img
          className='cursor-pointer'
          onClick={() => setOpen(true)}
          src={addIcon}
        />
      </div>
      {loader ? (
        <div className='flex justify-center'>
          <Loader />
          <h1 className='mt-1'>Getting your Education</h1>
        </div>
      ) : (
        renderEducation()
      )}

      {confirmModalOpen ? (
        <DeletionDialog
          title='Delete'
          description='Are you sure you want to delete this item?'
          open={confirmModalOpen}
          onClose={() => {
            setSelectedItem(null);
            setConfirmModalOpen(false);
          }}
          onPositiveAction={() => handleDeleteEducation(selectedItem)}
        />
      ) : null}

      {open ? (
        <EducationInputDialog
          {...selectedItem}
          isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
          open={open}
          onClose={() => {
            setSelectedItem(null);
            setOpen(false);
          }}
          onSubmitted={(data) => {
            setSelectedItem(null);
            handleEducationUpdate(data);
            setOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default ProfileEducationCard;
