import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Routes from 'src/Utils/Routes';

import hamburger from '../../Assets/images/v1/icons/hamburger.svg';
import logo from '../../Assets/images/v1/icons/logo.svg';
import Drawer from '../Drawer';

const Link = ({ label, onClick, containerStyle }) => {
  return (
    <a
      onClick={onClick}
      className={`mx-3 mt-1 lg:mt-0 text-base hover:text-BluePurple cursor-pointer text-white ${containerStyle}`}
    >
      {label}
    </a>
  );
};
const Button = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className='px-2 py-1 w-40 bg-lightBg hover:bg-BluePurple hover:text-white text-secondary rounded-md mt-1 transition-all duration-300 ease-in-out  hover:transform hover:translate-x-2'
    >
      {label}
    </button>
  );
};

const Header = () => {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <nav className='flex flex-wrap items-center justify-between px-2 py-6'>
        <div className='container mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <div onClick={() => history.push(Routes.Landing)}>
              <img src={logo} alt='skill-score-logo' />
            </div>

            <Drawer isOpen={navbarOpen} setIsOpen={setNavbarOpen}>
              <div className='flex justify-between mx-5 mt-10'>
                <div className='mx-5'>
                  <img src={logo} />
                </div>
                <div>
                  <button
                    className=' text-white rounded  cursor-pointer px-4 py-1'
                    onClick={() => setNavbarOpen(false)}
                  >
                    <img src={hamburger} />
                  </button>
                </div>
              </div>
              <div className='flex flex-col items-center mt-4 '>
                {/* <Link containerStyle='my-2' label='About' />
                <Link containerStyle='my-2' label='How it works?' />
                <Link containerStyle='my-2' label='Who is it for?' />
                <Link containerStyle='my-2' label='Contact us' /> */}

                <Button
                  label='Sign Up'
                  onClick={() => history.push('/sign-up')}
                />

                <Link
                  label='Sign In'
                  onClick={() => history.push('/sign-in')}
                  containerStyle='mt-2'
                />
                <Link
                  label='Careers'
                  onClick={() => history.push(Routes.Career)}
                  containerStyle='my-0'
                />
                <Link
                  label='Pricing'
                  onClick={() => history.push(Routes.Pricing)}
                  containerStyle='my-0'
                />
                <Link
                  label='Blogs'
                  onClick={() => history.push(Routes.Blogs)}
                  containerStyle='my-0'
                />
                <Link
                  label='How it Works'
                  onClick={() => history.push(Routes.HowItsWork)}
                  containerStyle='my-0'
                />
              </div>
            </Drawer>

            <button
              className='text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <img src={hamburger} alt='hamburger' />
            </button>
          </div>
          <div className='lg:flex hidden items-center mt-4 lg:mt-0'>
            {/* <Link label='About' />
            <Link label='How it works?' />
            <Link label='Who is it for?' />
            <Link label='Contact us' /> */}

            <Link
              label='How it Works'
              onClick={() => {
                history.push(Routes.HowItsWork);
              }}
              containerStyle='mr-2'
            />
            <Link
              label='Blogs'
              onClick={() => {
                history.push(Routes.Blogs);
              }}
              containerStyle='mr-2'
            />
            <Link
              label='Pricing'
              onClick={() => {
                history.push(Routes.Pricing);
              }}
              containerStyle='mr-2'
            />
            <Link
              label='Careers'
              onClick={() => {
                history.push(Routes.Career);
              }}
              containerStyle='mr-2'
            />
            <Link
              label='Sign In'
              onClick={() => history.push('/sign-in')}
              containerStyle='mr-6'
            />
            <Button label='Sign Up' onClick={() => history.push('/sign-up')} />
          </div>
        </div>
      </nav>
    </>
  );
};
export { Header };
