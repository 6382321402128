/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { TextareaAutosize } from '@material-ui/core';

import { User } from 'src/Api';
import { HomeContext } from 'src/Contexts';

import { Auth as AuthConstants } from '../../Constants';
import Button from '../Button';
import Dialog from '../Dialog';

const DeleteAccountCard = () => {
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const [feedback, setFeedback] = useState('');
  const { token } = useContext(HomeContext);
  const [, , removeCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const history = useHistory();

  const deleteUserHandler = async () => {
    const response = await User.deleteAccount(token, feedback);
    if (response) {
      removeCookie(AuthConstants.JWT_TOKEN, {
        path: '/',
      });
      localStorage.removeItem('loginData');
      localStorage.removeItem('login');
      removeCookie(AuthConstants.PROFILE_STATUS, {
        path: '/',
      });
      history.push('/sign-in');
      setDeleteProfileModal(false);
    }
  };

  return (
    <div id='certificates' className='bg-white my-10 py-7 px-5'>
      <div className='flex flex-col md:flex-row justify-start items-start md:justify-between flex-wrap'>
        <div>
          <h2 className='text-2xl font-semibold text-gray-900'>
            Delete Account
          </h2>
          <p className='text-gray-400 font-medium text-start md:text-center mt-2'>
            After deleting your account, you'll be logout automatically.
          </p>
        </div>

        <div className='mt-4 lg:mt-0 flex justify-center'>
          <Button
            onClick={() => setDeleteProfileModal(true)}
            title='Delete Account'
            className='bg-red-700 !mt-0 !shadow-none'
          />
        </div>
      </div>

      <Dialog
        title='Delete Account'
        open={deleteProfileModal}
        onClose={() => setDeleteProfileModal(false)}
        bottonButtonStyle='!py-2'
        titleStyle='text-xl md:text-2xl'
      >
        <div>
          <p>Are you sure you want to delete your SkilledScore account?</p>
          <p>
            We're sad to see you leaving. &#x1F614; If you have a moment, could
            you please share with us the reason for deleting your account?
          </p>
          <div className='mt-4'>
            <TextareaAutosize
              fullWidth={true}
              margin='dense'
              notched={false}
              value={feedback}
              labelWidth={200}
              onChange={(event) => setFeedback(event.target.value)}
              placeholder='Feedback'
              maxRows='5'
              minRows='2'
              className='h-full w-full border rounded'
              maxLength={500}
            />
            <div className=' flex justify-end'>
              <p className='font-sm'>{`${feedback.length}`}/500</p>
            </div>
          </div>

          <div className='flex justify-end'>
            <div className='mx-3'>
              <Button
                onClick={deleteUserHandler}
                title='Delete'
                className='bg-red-700'
              />
            </div>
            <Button
              onClick={() => setDeleteProfileModal(false)}
              title='Cancel'
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteAccountCard;
