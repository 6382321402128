import Avatar from 'react-avatar';

const UserAvatar = ({
  name = '',
  image = '',
  onClick,
  size = '35',
  color = '#fdeecb',
}) => (
  <Avatar
    round
    size={size}
    name={name}
    src={image}
    color={color}
    onClick={onClick}
    fgColor={'#fff'}
  />
);

export default UserAvatar;
