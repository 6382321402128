import React from 'react';
import { withRouter } from 'react-router-dom';

import Details from '../Notifications/Details';
import List from '../Notifications/List';

const Notifications = (props) => {
  return props.match.params.section ? <Details /> : <List />;
};

Notifications.propTypes = {};

export default withRouter(Notifications);
