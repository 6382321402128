import * as React from 'react';
const SvgComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    viewBox='0 0 462.994 462.994'
    {...props}
    fill='#fff'
  >
    <path d='M375.497 15.994H263.498c-.24 0-.477.014-.711.036C256.298 7.275 246.5 1.241 235.351.178c-11.119-1.055-22.206 2.615-30.42 10.083a39.33 39.33 0 0 0-5.175 5.746c-.086-.003-.17-.013-.257-.013H87.497c-21.78 0-39.5 17.72-39.5 39.5v368c0 21.78 17.72 39.5 39.5 39.5h288c21.78 0 39.5-17.72 39.5-39.5v-368c0-21.78-17.72-39.5-39.5-39.5zm-168.501 39.5v-16a24.562 24.562 0 0 1 8.026-18.134c5.168-4.699 11.877-6.922 18.905-6.249 12.375 1.18 22.069 12.494 22.069 25.758v14.625a7.5 7.5 0 0 0 7.5 7.5h32c4.687 0 8.5 3.813 8.5 8.5v16c0 4.687-3.813 8.5-8.5 8.5h-128c-4.687 0-8.5-3.813-8.5-8.5v-16c0-4.687 3.813-8.5 8.5-8.5h32a7.5 7.5 0 0 0 7.5-7.5zm-39.499 55.5h128c12.958 0 23.5-10.542 23.5-23.5v-.5h49v329h-273v-329h49v.5c0 12.958 10.542 23.5 23.5 23.5zm232.5 312.5c0 13.509-10.99 24.5-24.5 24.5h-288c-13.51 0-24.5-10.991-24.5-24.5v-368c0-13.509 10.99-24.5 24.5-24.5h105.437a39.36 39.36 0 0 0-.937 8.5v8.5h-24.5c-12.958 0-23.5 10.542-23.5 23.5v.5h-56.5a7.5 7.5 0 0 0-7.5 7.5v344a7.5 7.5 0 0 0 7.5 7.5h288a7.5 7.5 0 0 0 7.5-7.5v-344a7.5 7.5 0 0 0-7.5-7.5h-56.5v-.5c0-12.958-10.542-23.5-23.5-23.5h-24.5v-7.125a41.62 41.62 0 0 0-1.193-9.875h105.693c13.51 0 24.5 10.991 24.5 24.5v368z' />
    <path d='M231.497 62.994a7.5 7.5 0 0 0 7.5-7.5v-16c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v16a7.5 7.5 0 0 0 7.5 7.5zM111.997 135.494v96a7.5 7.5 0 0 0 7.5 7.5h96a7.5 7.5 0 0 0 7.5-7.5v-96a7.5 7.5 0 0 0-7.5-7.5h-96a7.5 7.5 0 0 0-7.5 7.5zm32.178 88.5c3.176-9.851 12.428-17 23.322-17s20.146 7.149 23.321 17h-46.643zm14.822-40.5v-8c0-4.687 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5v8c0 4.687-3.813 8.5-8.5 8.5s-8.5-3.813-8.5-8.5zm-32-40.5h81v81h-1.723c-2.24-11.6-9.567-21.404-19.563-26.995a23.361 23.361 0 0 0 4.286-13.505v-8c0-12.958-10.542-23.5-23.5-23.5s-23.5 10.542-23.5 23.5v8c0 5.024 1.59 9.681 4.286 13.505-9.996 5.59-17.323 15.394-19.563 26.995h-1.723v-81zM247.497 142.994h96a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-96a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5zM343.497 271.994h-176a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h176a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM343.497 303.994h-176a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h176a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM343.497 335.994h-144a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h144a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM343.497 367.994h-144a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h144a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM135.497 271.994h-16a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h16a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM135.497 303.994h-16a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h16a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5zM247.497 206.994h96a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-96a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5zM247.497 238.994h96a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-96a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5zM247.497 174.994h96a7.5 7.5 0 0 0 7.5-7.5 7.5 7.5 0 0 0-7.5-7.5h-96a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5z' />
  </svg>
);
export default SvgComponent;
