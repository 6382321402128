import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import Payment from 'src/Api/UserPayment';

import { Auth as AuthConstants } from '../../Constants';

const SuccessPayment = () => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const paymentHandler = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userData = await localStorage.getItem('loginData');
    const parsedData = await JSON.parse(userData);

    const sessionId = urlParams.get('session_id');

    if (sessionId) {
      Payment.paymentHistory(token, {
        sessionId,
        email: parsedData.user.email,
        userId: parsedData.user.id,
      })
        .then(() => {})
        .catch();
    }
  };

  useEffect(() => {
    paymentHandler();
  }, []);

  return <div className='lg:ml-80'>You Payment has been successfully</div>;
};

export default SuccessPayment;
