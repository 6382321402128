import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@material-ui/core';

import avatar from 'src/Assets/images/v1/icons/male-avatar.svg';

import Button from '../Button';
import UploadFile from '../Profile/UploadFile';

const ProfileImageUpload = ({
  image,
  setDeleted,
  setImage,
  handleProfileUpdate,
}) => {
  return (
    <div>
      <div>
        <div className='w-fit mx-auto my-5'>
          <UploadFile
            typography={
              <div className='cursor-pointer'>
                {image ? (
                  <div className='mt-4 flex items-center justify-center'>
                    <div className='text-blue-800 hover:underline '>
                      {' '}
                      Change Photo
                    </div>
                    <div
                      className='text-red-600 ml-3 hover:underline '
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleted(true);
                        setImage(null);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                ) : (
                  <div className='flex mt-4 items-center justify-center'>
                    <Link className='!text-blue-800 underline text-center w-fit'>
                      {' '}
                      Add Photo
                    </Link>
                  </div>
                )}
              </div>
            }
            image={image || avatar}
            onImageUploaded={(image) => setImage(image)}
            imageStyle='w-40 h-40 md:!w-60 md:!h-60 rounded-full'
          />
        </div>
      </div>
      <div className='flex justify-end items-end'>
        <Button onClick={handleProfileUpdate} title='Save' />
      </div>
    </div>
  );
};

ProfileImageUpload.propTypes = {
  image: PropTypes.string,
  onImageUploaded: PropTypes.func.isRequired,
  setDeleted: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  handleProfileUpdate: PropTypes.func.isRequired,
};

export default ProfileImageUpload;
