/* eslint-disable simple-import-sort/imports */

import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { FiDownload } from 'react-icons/fi';

import { AppContext, HomeContext } from 'src/Contexts';

import { Auth as AuthConstants } from '../../../Constants';
import { User } from 'src/Api';

const Header = ({ title, dashboard, isPremium }) => {
  const context = useContext(AppContext);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const {
    skills,
    total,
    future,
    iloLevel,
    employability,
    careerTrajectory,
    userProfile,
  } = useContext(HomeContext);

  const phaseMessage = (total) => {
    if (total >= 0 && total <= 30) {
      return { phase: 'Learning Phase', color: '#1E90FF' };
    } else if (total > 30 && total <= 70) {
      return { phase: 'Growth Phase', color: '#3CB371' };
    } else if (total > 70) {
      return { phase: 'Impact Phase', color: '#DAA520' };
    }
  };

  const generatePDFHandler = async () => {
    try {
      const payload = {
        name: userProfile?.name,
        total,
        future,
        skills,
        iloLevel: total > 80 ? 4 : iloLevel,
        employability,
        careerSummary: careerTrajectory[2]?.content,
        careerPath: careerTrajectory[3]?.content,
        phase: phaseMessage(total).phase,
        phaseColor: phaseMessage(total).color,
        profession: userProfile?.profession?.name,
      };

      const response = await User.generatePdf(token, payload);

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'skilled-score-profile.pdf';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Failed to generate PDF:', response.statusText);
        // Handle error message or display to the user
      }
    } catch (error) {
      console.error('Error downloading PDF:', error.message);
      // Handle error or display to the user
    }
  };

  useEffect(() => {
    context.updateUser(token);
  }, []);

  const homeContext = useContext(HomeContext);

  return (
    <div
      className={`m-5 mx-5 ${title === 'Dashboard' ? 'md:mx-0' : 'md:mx-10'} `}
    >
      {homeContext?.isFetchingScore ? null : (
        <div className='flex justify-between'>
          <div className='ml-1'>
            <h1 className='text-3xl font-semibold'>{title}</h1>
            <h3 className='text-base font-medium text-gray-600'>
              Welcome {context?.user?.name ?? 'Guest'}
            </h3>
          </div>
          <div>
            {dashboard && isPremium && Number(total) !== 0 ? (
              <button
                className='bg-primaryBg flex justify-center items-center shadow-md shadow-gray-400 text-white px-3 py-2 rounded-lg '
                onClick={generatePDFHandler}
              >
                <p className=' text-xs capitalize'>Download Report</p>
                <FiDownload className='ml-1' />
              </button>
            ) : null}
          </div>
          {/* Todo we need these buttons in future */}
        </div>
      )}
    </div>
  );
};

export default Header;
