import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Loader from '../Loader';

import EmptyPlaceholder from './EmptyPlaceholder';

const useStyles = makeStyles(() => ({
  placeholderStyle: {
    height: '100%',
  },
  skeletonStyle: {
    marginTop: '15%',
  },
}));

const ItemsGrid = (props) => {
  const classes = useStyles(props);

  if (props?.data?.length) {
    return props.children;
  }
  if (props.loader) {
    return (
      <Grid
        xs={12}
        justifyContent='center'
        className={classes.skeletonStyle}
        container
      >
        <Loader />
      </Grid>
    );
  }

  return (
    <Grid
      xs={12}
      alignItems='center'
      className={classes.placeholderStyle}
      container
    >
      <EmptyPlaceholder
        text={props.placeholderText}
        image={props.placeholderImage}
        ctaLabel={props.ctaLabel}
        ctaAction={props.ctaAction}
      />
    </Grid>
  );
};

ItemsGrid.propTypes = {
  data: PropTypes.bool.isRequired,
  placeholderImage: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
};

export default ItemsGrid;
