import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { HomeContext } from 'src/Contexts';

import InformationAvatar from '../../../Assets/images/v1/icons/information.svg';

const InformationSection = ({ completion }) => {
  const history = useHistory();
  const homeContext = useContext(HomeContext);

  const ParsedCompletion = parseInt(completion);

  return (
    <>
      {homeContext?.isFetchingScore ? null : (
        <div className='bg-white w-full xl:w-2/5 py-5 px-8 relative roundedCard'>
          <h2 className='font-semibold text-base md:text-lg pb-2'>
            {ParsedCompletion === 100
              ? 'Your Information'
              : 'We need Information'}
          </h2>
          <img
            src={InformationAvatar}
            alt='Information Avatar'
            className='w-32 h-32 md:w-auto md:h-auto mx-auto mb-4'
          />
          <div className='py-4'>
            <div className='flex flex-row justify-between'>
              <h2 className='text-Gray font-[500] text-sm md:text-base'>
                Profile Completion
              </h2>
              <h2 className='text-Gray font-[500] text-sm md:text-base'>
                {ParsedCompletion}%
              </h2>
            </div>
            <div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
              <div
                className='bg-BaseBlue h-2.5 rounded-full'
                style={{ width: `${completion}%` }}
              ></div>
            </div>
          </div>
          <div className='flex justify-end'>
            <button
              onClick={() =>
                history.push(ParsedCompletion === 100 ? '#' : '/home/profile')
              }
              type='button'
              className={` ${
                ParsedCompletion === 100
                  ? 'cursor-not-allowed bg-gray-200 text-gray-400'
                  : 'cursor-pointer hover:text-blue-700 hover:bg-gray-100 bg-white text-gray-900'
              }  py-2.5 px-5 mr-2 mb-2 text-sm md:text-base font-medium  focus:outline-none rounded-lg border  border-gray-200  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700`}
            >
              {ParsedCompletion === 100 ? 'Completed' : 'Complete your Profile'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InformationSection;
