export const SectionWithContent = ({
  heading,
  content,
  searchLoader,
  searchMessage,
  renderContent,
  sectionIndex,
}) => {
  return (
    <div>
      {content?.length && !searchMessage ? (
        <>
          <h1 className='md:text-2xl sm:text-xl font-bold mb-2 mt-8 ml-1  '>
            {heading}
          </h1>
          <div
            className={`${
              content?.length && !searchLoader && !searchMessage
                ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
                : 'flex flex-wrap'
            } mt-5`}
          >
            {renderContent(content, sectionIndex)}
          </div>
        </>
      ) : null}
    </div>
  );
};
