import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import TabbedContainer from 'src/Components/TabbedContainer';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

import Available from './Available';
// import Completed from './Completed';

const Survey = () => {
  const { user, userPayment } = useContext(AppContext);
  const { fetchSubmittedSurveys, fetchSurveys } = useContext(HomeContext);
  const paymentHistory = userPayment?.payment_status;

  const verifiedEmail = user?.email_verified;
  const history = useHistory();

  const [surveyText, setSurveyText] = useState(
    ' All your surveys are here in one place',
  );

  useEffect(() => {
    if (!verifiedEmail || paymentHistory === PAYMENT_STATUS.NOT_PAID) {
      return history.push('/home');
    }
  }, [verifiedEmail, paymentHistory]);

  useEffect(() => {
    fetchSubmittedSurveys();
    fetchSurveys();
  }, []);

  return (
    <div className='lg:ml-80'>
      <h1 className='text-3xl font-bold my-9 lg:mx-10 mx-3'>Surveys</h1>
      <h2 className='text-xl font-semibold text-SSBlack lg:mx-10 mx-3'>
        {surveyText}
      </h2>
      <div className='lg:ml-8 w-11/12 mx-auto '>
        <TabbedContainer
          tabs={[
            {
              name: 'Surveys',
              content: <Available />,
            },
            // {
            //   name: 'Completed Surveys',
            //   content: <Completed />,
            // },
          ]}
          setState={setSurveyText}
          name='survey'
        />
      </div>
    </div>
  );
};

export default withRouter(Survey);
