/* eslint-disable simple-import-sort/imports */

import { Auth, User } from 'src/Api';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import overlayImage from 'src/Assets/images/v1/icons/overlay-image.svg';
// import AdsBanner from 'src/Components/v1/AdsBanner';
import CareerSummary from 'src/Components/DashboardNew/CareerSummary';
import Header from 'src/Components/DashboardNew/Header';
import InformationSection from 'src/Components/DashboardNew/InformationSection';
import InterestInputDialog from 'src/Components/InterestInputDialog';
// import InterestInputDialog from 'src/Components/InterestInputDialog';
import JobSection from 'src/Components/DashboardNew/JobSection';
import RecommendedCourses from 'src/Components/DashboardNew/RecommendedCourses';
import ScoreCard from 'src/Components/DashboardNew/ScoreCard';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { walkthroughSteps } from 'src/Utils/utils';

const Dashboard = () => {
  const { user, userPayment, showError } = useContext(AppContext);
  const homeContext = useContext(HomeContext);
  const [openInterest, setOpenInterest] = useState(true);
  const [componentMounted, setComponentMounted] = useState(false);

  const {
    total,
    isFetchingScore,
    setIsWalkthroughRunning,
    getCareerTrajectory,
    getAreaOfInterest,
    userProfile,
    token,
    isWalkthroughRunning,
  } = homeContext;

  const isEmailVerified = user?.email_verified;
  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const checkAreaOfInterestExist = userProfile?.area_of_interest?.length
    ? JSON.parse(userProfile?.area_of_interest)
    : [];

  const resendEmailHandler = async () => {
    const response = await Auth.sendEmailVerification(user?.email);
    if (response.status === 200) {
      showError('Resend Verification Email');
    }
  };

  const handleInterestsUpdate = async (interests) => {
    try {
      await User.updateInterests(interests, token);
      setOpenInterest(false);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setComponentMounted(true);
    }, 1000);
    homeContext.fetchUserScore();
    if (isPremium) {
      getAreaOfInterest();
    }
  }, [isPremium]);

  useEffect(() => {
    if (
      !localStorage.getItem('walkthroughSeen') &&
      Number(total) &&
      (window.location.pathname === '/home' ||
        window.location.pathname === '/home/')
    ) {
      setTimeout(() => {
        walkthroughSteps(isPremium, setIsWalkthroughRunning).drive();
        setIsWalkthroughRunning(true);
      }, 100);
    }
  }, [isPremium, total]);

  useEffect(() => {
    if (isPremium) {
      getCareerTrajectory();
    }
  }, [isPremium]);

  return (
    <div
      className={`flex lg:ml-80 flex-col lg:flex-row  ${
        !isEmailVerified || !Number(total) ? 'overflow-y-hidden h-screen' : ''
      }`}
    >
      <div
        className={`w-full lg:w-[calc(100% - 240px)] px-5 lg:px-10 py-4 ${
          isEmailVerified ? '' : 'blur-content'
        }`}
      >
        <Header title='Dashboard' dashboard={true} isPremium={isPremium} />
        <ScoreCard />
        <>
          <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
            <CareerSummary title='Career Summary' id='career-summary' />
            <CareerSummary title='Career Path' id='career-path' />
          </div>

          <div className='flex flex-col mt-4 xl:flex-row gap-2 lg:gap-4 lg:mx-0'>
            <InformationSection
              completion={user?.percentage ? user?.percentage : 0}
            />
            <JobSection />
          </div>
          <RecommendedCourses />
          {/* <AdsBanner
            className='mt-6'
            title='Ads Area'
            image={ZindabhagHorizontal}
            url={BANNER_LINK?.[1]}
          /> */}
        </>
      </div>

      {componentMounted &&
        isPremium &&
        isEmailVerified &&
        !checkAreaOfInterestExist?.length &&
        !isWalkthroughRunning && (
          <InterestInputDialog
            onSubmitted={handleInterestsUpdate}
            interests={[]}
            open={openInterest}
            onClose={() => setOpenInterest(false)}
          />
        )}

      {!isEmailVerified && (
        <div className='fixed top-28 w-full h-full flex justify-start items-start  bg-white bg-opacity-90'>
          <div className='mx-10 my-16 p-4'>
            <h2 className='text-2xl mb font-semibold'>
              Please verify your email
            </h2>
            <p>
              {"You're "}almost there! We sent an email to {user?.email}
            </p>
            <p>Just click on the link in the email to verify your account.</p>
            <p>
              If you {"didn't"} receive the email, please click the button below
              to resend it.
            </p>
            <button
              className=' bg-primaryBg text-white px-3 py-1 rounded-lg mt-2 font-semibold'
              onClick={resendEmailHandler}
            >
              Resend Verification Email
            </button>
            <img className='my-5' src={overlayImage} alt='' />
          </div>
        </div>
      )}
      {isEmailVerified && isFetchingScore === false
        ? Number(total) === 0 && (
            <div className='fixed top-28 w-full h-full flex justify-start items-start  bg-white bg-opacity-90'>
              <div className='mx-10 my-16 p-4'>
                <h2 className='text-2xl mb font-semibold'>
                  <Link className='text-blue-500' to='/home/profile'>
                    Complete your profile
                  </Link>{' '}
                  or{' '}
                  <Link className='text-blue-500' to='/home/upload-cv'>
                    upload resume
                  </Link>{' '}
                  in order <br /> to calculate your assessment score.
                </h2>
                <img className='my-5' src={overlayImage} alt='' />
              </div>
            </div>
          )
        : null}
    </div>
  );
};

export default Dashboard;
