import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    fontWeight: '500',
    color: '#505050',
  },
}));

const EmptyPlaceholder = (props) => {
  const classes = useStyles();

  return (
    <Grid
      justify='center'
      alignItems='center'
      direction='column'
      xs={12}
      container
    >
      <Grid className={classes.imageContainer} item>
        <img src={props.image} alt={props.text} />
      </Grid>
      <Grid item>
        <Typography className={classes.text}>{props.text}</Typography>
      </Grid>
    </Grid>
  );
};

EmptyPlaceholder.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmptyPlaceholder;
