const Routes = {
  Landing: '/',
  TermsAndCondition: '/terms-and-condition',
  PrivacyPolicy: '/privacy-policy',
  CookiesPolicy: '/cookies-policy',
  ForgetPassword: '/forget-password',
  ResetPassword: '/reset-password',
  VerifyEmail: '/verify',
  Login: '/sign-in',
  SignUp: '/sign-up',
  OurTeam: '/our-team',
  Pricing: '/pricing-plan',
  Blogs: '/blogs',
  HowItsWork: '/how-it-works',
  Career: '/careers',
  ProfileCompletion: '/sign-up/profile/:step',
  Home: '/home/:tab?/:section?',
  UploadCV: '/home/upload-cv',
  Profile: '/home/profile',
  ReferAFriend: '/home/refer-a-friend',
};

export default Routes;
