import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import UserCourses from 'src/Api/GetCourses';
import Loader from 'src/Components/Loader';
import { SectionWithContent } from 'src/Components/v1/CoursesSection';
import EmptyCard from 'src/Components/v1/EmptyCard';
import Pagination from 'src/Components/v1/Pagination';
import Search from 'src/Components/v1/Search';
import { Auth as AuthConstants } from 'src/Constants';
import { HomeContext } from 'src/Contexts';
import { facebookEvent, getEmptyCardText } from 'src/Utils/utils';

import UpskillCard from '../../Components/v1/UpskillsCard';

const RecommendedCourses = ({ userCourses, savedCourses }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCourses, setSearchCourses] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchMessage, setSearchMessage] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const { getUserCourses, coursesCount } = useContext(HomeContext);

  const renderCourses = [
    { title: 'Based on Upskilling', content: userCourses?.skillsCourses },
    {
      title: 'Emerging Technologies',
      content: userCourses?.futureSkillsCourses,
    },
    { title: '', content: userCourses?.otherSkillsCourses },
  ];

  useEffect(() => {
    setSearchCourses([]);
    setSearchMessage('');
  }, [!searchQuery]);

  const startIndex = (pageNumber - 1) * 21;
  const endIndex = startIndex + 18;
  const slicedCourses = searchCourses?.slice(startIndex, endIndex);

  const getSearchCourses = async (event) => {
    facebookEvent('Search');
    if (searchQuery) {
      setSearchLoader(true);
      event.preventDefault();
      try {
        const data = await UserCourses.get_search_courses(token, {
          searchQuery: searchQuery,
        });
        if (data) {
          setSearchMessage(data?.data?.data?.message);
          setSearchCourses(data?.data?.data?.userSkillsCourses);
        }
        setPageNumber(1);
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoader(false);
      }
    }
  };

  const renderCoursesCards = (coursesToMap) => {
    return coursesToMap?.map((courses, index) => (
      <>
        <div key={index} className='my-3'>
          <UpskillCard
            upskill={courses}
            index={index}
            savedCourses={savedCourses}
          />
        </div>
      </>
    ));
  };

  const renderContent = (courses) => {
    if (userCourses === null || searchLoader) {
      return (
        <div className='flex justify-center items-center w-full h-[70vh]'>
          <Loader />
        </div>
      );
    }
    if (searchQuery && searchCourses?.length) {
      return renderCoursesCards(slicedCourses);
    }

    if (courses?.length && !searchMessage) {
      return renderCoursesCards(courses);
    }
    return (
      <EmptyCard
        text={
          searchMessage ??
          getEmptyCardText(
            userCourses?.skillsCourses?.length,
            searchQuery,
            'No Recommended Upskills to show',
          )
        }
      />
    );
  };

  const getPageNumber = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <>
      <form onSubmit={(e) => getSearchCourses(e)}>
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearch={getSearchCourses}
          placeholder='Search courses by skills'
          label='Search Courses :'
        />
      </form>
      {userCourses === null ? (
        <div className='flex justify-center items-center w-full h-[70vh]'>
          <Loader />
        </div>
      ) : null}
      {searchCourses?.length || searchMessage ? (
        <div
          className={`${
            searchCourses?.length
              ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
              : 'flex flex-wrap'
          } max-w-screen-2xl mt-5`}
        >
          {renderContent()}
        </div>
      ) : (
        <>
          {renderCourses.map((category, index) => (
            <div key={index}>
              <SectionWithContent
                heading={category.title}
                content={category.content}
                searchLoader={searchLoader}
                searchMessage={searchMessage}
                renderContent={renderContent}
              />
            </div>
          ))}
        </>
      )}

      {searchCourses?.length ||
      !userCourses?.futureSkillsCourses?.length ||
      searchMessage ? null : (
        <Pagination
          totalCourses={coursesCount}
          onPageChange={(pageNumber) => getPageNumber(pageNumber)}
          getUserCourses={getUserCourses}
        />
      )}

      {searchCourses?.length ? (
        <Pagination
          totalCourses={searchCourses?.length}
          onPageChange={(pageNumber) => getPageNumber(pageNumber)}
        />
      ) : null}
    </>
  );
};

export default RecommendedCourses;
