import React, { useContext, useEffect, useState } from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import Refer from 'src/Api/Refer';
import { ReferAFriend as ReferFriend } from 'src/Assets/images/dashboard';
import { HomeContext } from 'src/Contexts';

const ReferAFriend = () => {
  const [copyStatus, setCopyStatus] = useState('Copy');
  const [referralDetails, setReferralDetails] = useState('');
  const { token } = useContext(HomeContext);

  const renderStatistic = (title, value, tooltip) => (
    <div className='mt-1 text-center' key={title}>
      <div className='flex items-center justify-center'>
        <h2 className='font-semibold text-DarkBluePurple'>{title}</h2>
        <IoMdInformationCircleOutline
          title={tooltip}
          className='ml-1 text-DarkBluePurple'
          size={18}
        />
      </div>

      <p className='text-lg font-bold'>
        {value}
        {title === 'Available Discount' ? '%' : null}
      </p>
    </div>
  );

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    setCopyStatus('Copied');
    setTimeout(() => setCopyStatus('Copy'), 2000);
  };

  const getReferLinkHandler = async () => {
    try {
      const response = await Refer.getReferDetails(token);
      setReferralDetails(response?.data?.data);
    } catch (error) {
      console.error('Failed to fetch referral details:', error);
    }
  };

  useEffect(() => {
    getReferLinkHandler();
  }, [token]);

  return (
    <div className='lg:ml-80'>
      <h1 className='text-3xl my-9 lg:mx-10 mx-3 font-semibold'>
        Refer a Friend
      </h1>
      <hr />
      <div className='mx-4'>
        <p className='bg-lighterBlue text-PurpleBlack font-semibold mt-5 p-3 rounded-md'>
          Avail your discount on the next subscription.
        </p>
      </div>

      <div className='w-11/12 md:w-[650px] lg:w-[750px] mx-auto px-6 py-4 h-full rounded-2xl mt-10 bg-white shadow-xl'>
        <div>
          <p className='my-2 text-center text-lg'>
            Exciting news! If you refer to your friends, both you and your
            friend will get <br /> awesome discounts🌟
          </p>
        </div>
        <div className='flex flex-col md:flex-row justify-around mt-5'>
          {renderStatistic(
            'Referrants',
            referralDetails?.registerByLink || 0,
            'Users who have signed up using your referral link',
          )}
          {renderStatistic(
            'Affiliates',
            referralDetails?.premiumReferralUsers || 0,
            'Users who have subscribed through your referral link',
          )}
          {renderStatistic(
            'Available Discount',
            referralDetails?.referralDiscount || 0,
            'Discount you can avail on your next subscription',
          )}
        </div>
        <hr className='mt-2' />
        <div className='flex justify-center'>
          <img src={ReferFriend} className='w-80 h-64' alt='Refer a Friend' />
        </div>
        <div className='bg-slate-100 rounded-lg p-2 mt-2 flex items-center justify-between'>
          <div className='w-full'>
            <p className='text-sm md:text-base font-semibold text-gray-600 overflow-hidden overflow-ellipsis'>
              {referralDetails?.referLink}
            </p>
          </div>

          <p
            className='font-semibold text-gray-600 text-sm mr-1 cursor-pointer'
            onClick={() => copyToClipboard(referralDetails?.referLink)}
          >
            {copyStatus}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReferAFriend;
