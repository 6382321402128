import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Grid,
  OutlinedInput,
  TextareaAutosize,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { COMMON } from 'src/Constants';
import { AppContext } from 'src/Contexts';

import ProfessionDropdown from './OnBoarding/ProfessionDropdown';
import Button from './Button';
import CitySelect from './CitySelect';
import CountrySelect from './CountrySelect';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
  },
  propDiv: {
    marginBottom: '30px',
  },
  anchorStyle: {
    marginLeft: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  input: {
    border: '1px solid #ced4da',
    margin: theme.spacing(1),
    width: '34.25vw',
  },
  dropdownLabel: {
    font: 'inherit',
    marginTop: '-10px',
  },
  summaryTextBox: {
    height: '100%',
    width: '100%',
    border: '1px solid #ced4da',
    borderRadius: 5,
  },
}));

const PrimaryInfoForm = (props) => {
  const classes = useStyles();

  const [name, setFullName] = useState(props.name);
  const [profession, setProfession] = useState(props.profession);
  const [country, setCountry] = useState(props.country);
  const [city, setCity] = useState(props.city);
  const [summary, setSummary] = useState(props.summary);
  const [image, setImage] = useState(props.image);
  const context = useContext(AppContext);

  useEffect(() => {
    setFullName(props?.name);
    setProfession(props?.profession);
    setSummary(props?.summary);
    setCity(props?.city);
    setCountry(props?.country);
    setImage(props?.image_url);
  }, [props]);

  const onClick = async () => {
    if (name && profession?.id && country && city && summary) {
      await props.onSubmitted({
        name,
        profession,
        country,
        city,
        summary,
        image,
      });
    } else {
      context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
  };

  return (
    <div className={classes.container}>
      <FormGroup>
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <OutlinedInput
              autoFocus={true}
              fullWidth={true}
              value={name}
              onChange={(event) => setFullName(event.target.value)}
              placeholder='Full Name'
              margin='dense'
              notched={false}
              labelWidth={200}
            />
          </Grid>
          <Grid xs={6} item>
            <ProfessionDropdown
              exact={true}
              value={profession}
              onChange={(value) => setProfession(value)}
            />
          </Grid>
          <Grid xs={6} item>
            <CountrySelect
              onChange={(value) => {
                setCountry(value);
                setCity(null);
              }}
              value={country}
            />
          </Grid>
          <Grid xs={6} item>
            <CitySelect
              onChange={(value) => setCity(value)}
              countryId={country ? country.id : null}
              value={city}
            />
          </Grid>
          <Grid xs={12} item>
            <TextareaAutosize
              fullWidth={true}
              margin='dense'
              notched={false}
              value={summary}
              labelWidth={200}
              onChange={(event) => setSummary(event.target.value)}
              placeholder='Write Summary'
              maxRows='5'
              className={classes.summaryTextBox}
            />
          </Grid>
          <Grid xs={12} direction='row' justify='flex-end' item container>
            <Button onClick={onClick} title='Save' />
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
};

PrimaryInfoForm.propTypes = {
  name: PropTypes.string,
  profession: PropTypes.string,
  image: PropTypes.any,
  country: PropTypes.string,
  city: PropTypes.string,
  summary: PropTypes.string,
  onSubmitted: PropTypes.func,
};

PrimaryInfoForm.defaultProps = {
  name: '',
  profession: '',
  image: null,
  country: '',
  city: '',
  summary: '',
  onSubmitted: () => {},
};

export default withRouter(PrimaryInfoForm);
