import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';

import PrimaryInfoInputDialog from 'src/Components/PrimaryInfoInputDialog';
import { AppContext } from 'src/Contexts';

import { User } from '../../Api';
import ProfileSummaryIcon from '../../Assets/images/v1/icons/profile summary icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import Loader from '../Loader';

import ProfileEmptyCard from './ProfileEmptyCard';

const ProfileSummaryCard = ({
  user,
  getProfile,
  loader,
  placeholderText,
  placeholderImage,
}) => {
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [image, setImage] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [profession, setProfession] = useState({});
  const context = useContext(AppContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const { state } = useLocation();

  const [openPrimaryInfo, setOpenPrimaryInfo] = useState(state?.profileDialog);

  const updateUser = () => context.updateUser(token);

  useEffect(() => {
    setName(user.name);
    setProfession(user.profession);
    setSummary(user.description);
    setCity(user.city);
    setCountry(user.country);
    setImage(user.image_url);
  }, [user]);

  const handleProfileUpdate = async (data) => {
    try {
      const res = await User.updateGeneralProfile(data, token);
      const user = res.data.data;

      setName(user.name);
      setProfession({ id: user.profession_id, name: user.profession_name });
      setImage(user.image_url ? user.image_url : image);
      setSummary(user.description);
      setOpenPrimaryInfo(false);
      setCity(user.city);
      setCountry(user.country);
      getProfile();
      updateUser();
    } catch (e) {
      console.error('Failed:', e);
    }
  };

  const readMoreProps = {
    variant: 'subtitle1',
    className: 'cursor-pointer text-blue-600',
  };

  const renderSummary = () => {
    if (summary) {
      return (
        <ShowMoreText
          lines={3}
          more={<p {...readMoreProps}>Read more</p>}
          less={<p {...readMoreProps}>Read less</p>}
          anchorClass=''
        >
          <p className='text-gray-600  '>{summary}</p>
        </ShowMoreText>
      );
    }
    return <ProfileEmptyCard {...{ placeholderImage, placeholderText }} />;
  };

  return (
    <div className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold'>Summary</h1>
        <img
          onClick={() => setOpenPrimaryInfo(true)}
          className='cursor-pointer'
          src={ProfileSummaryIcon}
          alt=''
        />
      </div>
      {loader ? (
        <div className='flex justify-center'>
          <Loader />
          <h1 className='mt-1'>Getting your Summary</h1>
        </div>
      ) : (
        renderSummary()
      )}
      <PrimaryInfoInputDialog
        data={{ name, image, city, country, profession, summary }}
        open={openPrimaryInfo}
        onClose={() => setOpenPrimaryInfo(false)}
        onSubmitted={handleProfileUpdate}
      />
    </div>
  );
};

export default ProfileSummaryCard;
