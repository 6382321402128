import React, { useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { USER_PROFILE } from 'src/Constants/Common';

import placeholder from '../../Assets/images/profile/summary-placeholder.svg';
import SummaryInputDialog from '../SummaryInputDialog';

import BasicPane from './BasicPane';

const useStyles = makeStyles((theme) => ({
  desc: {
    'lineHeight': '1.2em',
    'color': '#58595b',
    '& a': {
      textDecoration: 'none',
    },
  },
  readMoreText: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    cursor: 'pointer',
  },
}));

const SummaryPane = (props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const readMoreProps = {
    variant: 'subtitle1',
    className: classes.readMoreText,
  };

  return (
    <Grid
      component={BasicPane}
      placeholderText={USER_PROFILE.NO_SUMMARY}
      placeholderImage={placeholder}
      title={'Summary'}
      container
    >
      {props.summary && props.summary.length > 0 ? (
        <>
          <SummaryInputDialog
            isNewSummary={props.isNewSummary}
            summary={props.summary}
            open={open}
            onClose={() => setOpen(false)}
          />
          <Grid component={Box} className={classes.desc} item>
            <ShowMoreText
              lines={3}
              more={<Typography {...readMoreProps}>Read more</Typography>}
              less={<Typography {...readMoreProps}>Read less</Typography>}
              anchorClass=''
              onClick={() => setExpanded(!expanded)}
              expanded={expanded}
            >
              {props.summary}
            </ShowMoreText>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};
SummaryPane.propTypes = {
  summary: PropTypes.string.isRequired,
  isNewSummary: PropTypes.bool.isRequired,
  onAction: PropTypes.func,
  onSubmitted: PropTypes.func,
};

export default SummaryPane;
