import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import iloImage from '../Assets/images/iso-image.jpg';

// import Button from './Button';
import Dialog from './Dialog';

const useStyles = makeStyles(() => ({
  container: {
    fontSize: '16px',
    color: '#505050',
    fontFamily: 'Avenir',
  },

  image: {
    backgroundImage: `url(${iloImage})`,
    height: '25vh',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '50px',
  },
}));

const ILODialog = (props) => {
  const classes = useStyles();

  const data = {
    groups1: [
      'Armed Forces Occupations',
      'Managers',
      'Professionals',
      'Technicians and Associate Professionals',
      'Clerical Support Workers',
    ],
    groups2: [
      'Services And Sales Workers',
      'Skilled Agricultural, Forestry and Fishery Workers',
      'Craft and Related Trades Workers',
      'Plant and Machine Operators and Assemblers',
      'Elementary Occupations',
    ],
  };

  return (
    <Dialog
      dashboard={true}
      open={props.open}
      onClose={props.onClose}
      title='International Standard Classification of Occupations (ISCO)'
    >
      <Grid className={classes.container}>
        <p>
          The International Standard Classification of Occupations is an
          International Labour Organization classification structure for
          organizing information on labour and jobs. It is part of the
          international family of economic and social classifications of the
          United Nations.
        </p>
        <br />
        <p>
          The International Standard Classification of Occupations (ISCO) is a
          four-level classification of occupation groups managed by the
          International Labour Organisation (ILO). Its structure follows a
          grouping by education level. The two latest versions of ISCO are
          ISCO-88 (from 1988) and ISCO-08 (from 2008).
        </p>
        <br />
        <Box className={classes.image} />
        <p className='ml-10 mr-20 text-center'>
          This drawing illustrates the role of ISCO 08 in the hierarchical
          structure of the ESCO occupations pillar:
        </p>
        <p className='text-xl mt-2 font-semibold text-DarkBluePurple'>
          ISCO Level 1: Major Groups
        </p>
        <Grid container>
          <Grid xs={6} item>
            <Grid direction='column' container>
              {data.groups1.map((group, index) => (
                <Grid item key={index}>
                  {index}&nbsp;&nbsp;{group}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid>
            <Grid direction='column' container>
              {data.groups2.map((group, index) => (
                <Grid item key={index}>
                  {index + data.groups2.length}&nbsp;&nbsp;{group}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid xs={12} item>
          <Grid
            direction='column'
            justify='flex-end'
            alignItems='flex-end'
            alignContent='flex-end'
            container
          >
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={() => props.onClose()}
              className={classes.textCaseTransform}
              title="GOT IT"
            />
          </Grid>
        </Grid> */}
      </Grid>
    </Dialog>
  );
};

ILODialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ILODialog.defaultProps = {};

export default ILODialog;
