import React from 'react';
import { format } from 'date-fns';

import { PAYMENT_METHODS, PAYMENT_STATUS } from 'src/Constants/Common';
import { formatDate } from 'src/Utils/utils';

const PlanBanner = ({ userPayment, planName, cancelationHistory }) => {
  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;
  const isStripePlanSubscription =
    userPayment?.payment_method !== PAYMENT_METHODS.EASY_PAISA;

  const planDurationMessage = () => {
    if (isPremium && !isStripePlanSubscription) {
      return ' Your plan will end on';
    } else if (isStripePlanSubscription && userPayment?.planPeriod?.endDate) {
      return ' Your next payment is scheduled for';
    }
    return '';
  };

  if (cancelationHistory?.cancel_at) {
    const cancelationDate = new Date(cancelationHistory?.cancel_at * 1000);
    const currentDate = new Date();

    if (cancelationDate >= currentDate) {
      const formattedEndDate = formatDate(cancelationDate / 1000);

      return (
        <p className='bg-lighterBlue text-PurpleBlack font-medium mt-5 p-3 rounded-md'>
          Your subscription has been canceled, and your subscription plan
          remains active until{' '}
          <span className='font-bold'>{formattedEndDate}</span>.
        </p>
      );
    }
  }

  return (
    <p className='bg-lighterBlue text-PurpleBlack font-medium mt-5 p-3 rounded-md'>
      You are using
      <span className='font-bold ml-1'>
        {isPremium
          ? `${
              planName && planName.title
                ? planName.title
                : 'A Discontinued Plan'
            }. `
          : 'Free Plan.'}
      </span>
      <span className='font-medium'>
        {planDurationMessage()}{' '}
        <span className='font-bold'>
          {userPayment?.planPeriod?.endDate
            ? `${format(
                new Date(userPayment?.planPeriod?.endDate),
                'dd MMMM yyyy',
              )}.`
            : null}
        </span>
      </span>
    </p>
  );
};

export default PlanBanner;
