import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loader from 'src/Components/Loader';
import OverlayCard from 'src/Components/OverlayCard';
import UpskillCard from 'src/Components/v1/UpskillsCard';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

const RecommendedCourses = () => {
  const {
    userProfile,
    getUserCourses,
    skillsLoader,
    userCourses,
    isFetchingScore,
  } = useContext(HomeContext);
  const { user, userPayment } = useContext(AppContext);
  const { isWalkthroughRunning } = useContext(HomeContext);
  const isEmailVerified = user?.email_verified;

  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  useEffect(() => {
    if (userProfile && isPremium) {
      getUserCourses(1, 'forDashboard');
    }
  }, [userProfile, isPremium]);

  const renderCard = () => {
    if (skillsLoader && isPremium) {
      return (
        <div className='flex my-32 mx-auto justify-center'>
          <Loader />
        </div>
      );
    } else if (!isPremium) {
      return (
        <div className='mx-auto bg-white w-full px-2 py-10 roundedCard'>
          <div className='text-center text-BluePurple font-bold flex justify-center h-full items-center'>
            <Link to='/home/payment' className=' text-lg'>
              Upgrade to premium for viewing courses.
            </Link>
          </div>
        </div>
      );
    } else if (userCourses?.skillsCourses?.length) {
      return userCourses?.skillsCourses.slice(0, 2)?.map((courses, index) => (
        <div key={index} className='my-3 w-full'>
          <UpskillCard
            className='md:!w-full'
            descriptionStyle='md:!w-full'
            isNotSaved
            upskill={courses}
            index={index}
            savedCourses={[]}
          />
        </div>
      ));
    }
    return (
      <div className='mx-auto bg-white w-full px-2 py-10 roundedCard'>
        <div className='text-center text-BluePurple font-bold flex justify-center h-full items-center'>
          <Link to='/home/profile'>
            Sorry no courses found for your skills.
            <br /> Update your skills and check again
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isFetchingScore || isFetchingScore === null ? null : (
        <div>
          {isWalkthroughRunning ? null : (
            <div className='flex lg:justify-between'>
              <h1 className='md:text-2xl sm:text-xl font-semibold mb-2 mt-8 ml-1'>
                Recommended Courses for you
              </h1>
            </div>
          )}

          <div
            id='recent-courses'
            className={
              userCourses?.skillsCourses?.length && isPremium
                ? 'grid grid-cols-1 md:grid-cols-2 gap-3 relative'
                : `flex flex-wrap relative ${
                    isWalkthroughRunning ? 'h-[460px]' : 'h-80'
                  } `
            }
          >
            {renderCard()}
            {isEmailVerified && !isWalkthroughRunning && !isPremium ? (
              <OverlayCard />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecommendedCourses;
