import { useEffect } from 'react';

const Modal = ({ isOpen, children, className }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);
  if (!isOpen) return null;

  return (
    <div className='fixed top-0 left-0 w-full h-full flex z-30 items-center  justify-center bg-opacity-50 bg-gray-800'>
      <div
        className={`bg-white mx-5  w-fit md:w-2/3 lg:w-2/5 rounded-lg shadow-lg ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
export default Modal;
