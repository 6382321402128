/* eslint-disable no-inner-declarations */
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

import { getILOScore, getPercentage } from 'src/Utils/utils';

const FUTURE_READINESS_WEIGHT = 0.1;
const SKILLS_READINESS_WEIGHT = 0.4;
const ILO_WEIGHT = 0.2;
const EMPLOYABILITY_WEIGHT = 0.3;

ChartJS.register(ArcElement, Tooltip, Legend);

const getChartOptions = (employability, skills, future, ilo, total) => {
  const iloScore = getPercentage(getILOScore(ilo || 0), ILO_WEIGHT);
  const employabilityScore = getPercentage(employability, EMPLOYABILITY_WEIGHT);
  const skillsScore = getPercentage(skills, SKILLS_READINESS_WEIGHT);
  const futureScore = getPercentage(future, FUTURE_READINESS_WEIGHT);
  return {
    series: [
      employabilityScore,
      skillsScore,
      futureScore,
      iloScore,
      100 - total,
    ],
    colors: ['#7E44E4', '#222B6D', '#FF7E6B', '#2DBEB6', '#E6E5E6'],
    chart: {
      height: 190,
      width: 190,
      type: 'donut',
    },
    stroke: {
      colors: ['transparent'],
      lineCap: '',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            name: {
              show: false,
            },
            total: {
              showAlways: true,
              show: false,
              label: 'Total Score',
              fontFamily: 'Inter, sans-serif',
              formatter: function (w) {
                const sum = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                return `${sum}`;
              },
            },
            value: {
              show: false,
              fontFamily: 'Inter, sans-serif',
              offsetY: -20,
              formatter: function (k) {
                return `${k}`;
              },
            },
          },
          size: '80%',
        },
      },
    },
    grid: {
      padding: {
        top: -2,
      },
    },
    labels: [
      'Geographic Opportunities Percentage',
      'Skills Readiness Percentage',
      'Future Readiness Percentage',
      'ILO Level Percentage',
      'Remaining Score',
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'bottom',
      fontFamily: 'Inter, sans-serif',
    },
    yaxis: {
      labels: {
        formatter: function (k) {
          return `${k}`;
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        fontFamily: 'Inter, sans-serif',
      },
    },
    xaxis: {
      labels: {
        formatter: function () {
          return `${0}`;
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        fontFamily: 'Inter, sans-serif',
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
  };
};

export default function ChartView(props) {
  const { colors, employability, skills, future, ilo, total } = props;
  useEffect(() => {
    if (
      document.getElementById(`chart-key${props.key}`) &&
      typeof ApexCharts !== 'undefined'
    ) {
      const sourceElement = document.getElementById(`chart-key${props.key}`);
      while (sourceElement.hasChildNodes())
        sourceElement.removeChild(sourceElement.firstChild);
      const chart = new ApexCharts(
        document.getElementById(`chart-key${props.key}`),
        getChartOptions(employability, skills, future, ilo, total),
      );

      chart.render();
    }
  }, [colors, employability, skills, future, ilo]);

  return <div className='py-6' id={`chart-key${props.uniqueKey}`} />;
}
