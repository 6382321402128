import React from 'react';

const Tabs = (props) => {
  const { items, value, onChange } = props;

  const handleTabClick = (index) => {
    localStorage.setItem('tab', index);
    onChange(value, index);
  };

  return (
    <div className='flex sm:flex-wrap border rounded-lg mt-5 bg-gray-50 border-gray-200 overflow-x-scroll'>
      {items.map((tab, index) => (
        <div
          key={index}
          onClick={() => handleTabClick(index)}
          className={`${
            value === index
              ? 'box-shadow bg-white my-1 text-black font-semibold'
              : ' text-gray-500 my-1 font-semibold'
          } cursor-pointer px-4  py-2 rounded-lg mr-2`}
        >
          <p>{tab.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
