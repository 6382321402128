import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import closeIcon from 'src/Assets/images/v1/icons/close-icon-black.svg';
import Loader from 'src/Components/Loader';
import OverlayCard from 'src/Components/OverlayCard';
import Modal from 'src/Components/v1/Modal/Modal';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import Routes from 'src/Utils/Routes';

const CareerSummary = ({ title, id }) => {
  const history = useHistory();

  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const homeContext = useContext(HomeContext);
  const { userPayment, user: User } = useContext(AppContext);

  const isEmailVerified = User?.email_verified;

  const {
    careerTrajectory,
    isWalkthroughRunning,
    userProfile,
    getCareerTrajectory,
    isFetchingScore,
  } = homeContext;

  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  let summaryCallCount = 0;

  const isSummaryGenerating =
    isPremium &&
    !message &&
    userProfile?.profession.id &&
    (userProfile?.user_skills?.length ||
      userProfile?.education?.length ||
      userProfile?.experience?.length);

  const fetchLocalStorage = async () => {
    const data = localStorage.getItem('loginData');
    const parsedData = await JSON.parse(data);
    setUser(parsedData?.user);
  };

  const loadCareerTrajectory = () => {
    if (!message) {
      getCareerTrajectory();
      summaryCallCount++;
      if (summaryCallCount < 3) {
        setTimeout(loadCareerTrajectory, 15000);
      }
    }
  };

  const getUserSummary = () => {
    setLoader(true);

    const assistantResponse = careerTrajectory?.find(
      (chat) => chat?.title === title && chat?.sender === 'assistant',
    );
    setMessage(assistantResponse?.content);
    setLoader(false);
  };

  useEffect(() => {
    fetchLocalStorage();
    if (isPremium) loadCareerTrajectory();
  }, []);

  useEffect(() => {
    if (user) {
      setThreadId(user?.thread_id);
    }
  }, [user]);

  useEffect(() => {
    if (threadId) {
      getUserSummary(threadId);
    }
  }, [threadId, careerTrajectory]);

  const renderSummary = () => {
    if (isSummaryGenerating) {
      return (
        <div className=' text-left h-28 flex justify-center items-center text-gray-500 font-semibold my-10'>
          <span>
            We are generating your
            <span className='lowercase ml-1'>{title}</span>...
          </span>
        </div>
      );
    } else if (message) {
      return (
        <div className='mt-3 mb-8'>
          <div className='text-left '>
            {message?.length > 400 ? (
              <p className='text-[14px]'>
                {message?.substring(0, 400)}...{' '}
                <span
                  className='text-BluePurple  cursor-pointer'
                  onClick={() => setOpen(true)}
                >
                  Read more
                </span>
              </p>
            ) : (
              message
            )}
          </div>
        </div>
      );
    }
    return (
      <div
        className='text-center h-24 flex justify-center items-center text-BluePurple font-semibold my-10 cursor-pointer'
        onClick={() => history.push(Routes.Profile)}
      >
        To view the <span className='lowercase ml-1'> {title}</span>, complete
        your profile or upload resume.
      </div>
    );
  };

  const renderLoader = () => {
    return loader ? (
      <div className='flex my-10 justify-center items-center'>
        Generating {title} Please Wait
        <Loader className='mx-2' />
      </div>
    ) : null;
  };

  const renderPremiumContent = () => {
    return (
      <>
        {renderSummary()}
        {renderLoader()}
      </>
    );
  };

  return (
    <div
      className={`bg-white px-8 pt-5 roundedCard ${
        isWalkthroughRunning && 'relative'
      }`}
      id={id}
    >
      <h1 className='text-xl font-medium mt-3'>{title}</h1>
      {!isFetchingScore ? (
        <div className={`${!isWalkthroughRunning && 'relative'}`}>
          {isPremium ? (
            renderPremiumContent()
          ) : (
            <div
              className='text-center h-28 flex justify-center items-center text-BluePurple font-semibold my-10 cursor-pointer'
              onClick={() => history.push('/home/payment')}
            >
              Upgrade to premium for viewing{' '}
              <span className='lowercase ml-1'>{title}</span>.
            </div>
          )}

          <Modal isOpen={open}>
            <div className=' my-5'>
              <div className='flex w-full justify-between px-6'>
                <h1 className='text-primaryBg text-2xl font-bold mt-3'>
                  {title}
                </h1>
                <img
                  className='cursor-pointer'
                  src={closeIcon}
                  onClick={() => setOpen(false)}
                />
              </div>
              <div>
                <div className='mt-3 mb-8 px-3'>
                  <p className='text-justify max-h-80 overflow-auto px-4'>
                    {message?.split('\n')?.map((textPart, i) => (
                      <p
                        key={i}
                        className={i === 0 ? 'paragraph-style' : 'my-5'}
                      >
                        {textPart}
                      </p>
                    ))}
                  </p>
                  <div className='flex justify-between w-full'>
                    <div></div>
                    <button
                      onClick={() => setOpen(false)}
                      className='bg-primaryBg shadow-md shadow-gray-400 text-white px-8 mt-5 p-3 rounded-lg'
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {isEmailVerified && !isPremium ? <OverlayCard /> : null}
        </div>
      ) : null}
    </div>
  );
};

export default CareerSummary;
