import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Avenir',
    fontSize: '14px',
    fontWeight: '900',
    height: '22px',
    overflow: 'hidden',
    margin: '4px 0',
    color: '#505050',
  },
  content: () => ({
    padding: '4px 4%',
    cursor: 'pointer',
  }),
  footerContent: {
    padding: '2% 2%',
  },
  changeText: {
    textTransform: 'uppercase',
    height: '36px',
    fontSize: '14px',
    fontWeight: '600',
    paddingTop: '10px',
  },
  description: ({ visited }) => ({
    margin: '8px 0',
    fontSize: '14px',
    lineHeight: '1.5',
    color: '#707070',
    fontFamily: 'Avenir-Roman',
    overflow: 'hidden',
    height: visited ? '40px' : '108px',
  }),
  visitedIcon: {
    padding: '0 4px',
  },
  visitedPrompt: {
    'backgroundColor': '#dfe2e9',
    'padding': '4%',
    '& .MuiButtonBase-root': {
      color: '#4f4f4f',
      fontWeight: '900',
      marginRight: '16px',
      fontFamily: 'Avenir',
      fontSize: '16px',
    },
  },
}));

const AssessmentCard = (props) => {
  const buttonText = {
    view: 'View',
    in_review: 'In Review',
  };
  const [visited, setVisited] = useState(props.visited);
  const [variant, setVariant] = useState(
    props.isSubmitted ? 'in_review' : 'view',
  );

  useEffect(() => {
    setVariant(props.isSubmitted ? 'in_review' : 'view');
  }, [props.isSubmitted]);

  useEffect(() => {
    setVisited(props.visited);
  }, [props.saved, props.enrolled, props.completed, props.visited]);

  const classes = useStyles({ props, visited });

  useEffect(() => {
    setVisited(props.visited);
  }, [props.visited]);

  return (
    <Grid
      component={Card}
      alignItems='stretch'
      direction='column'
      justify='center'
      container
    >
      <Grid
        onClick={() => props.onViewDetails()}
        className={classes.content}
        item
      >
        <Grid direction='column' container>
          {props.loading ? (
            <Skeleton variant='text' />
          ) : (
            <Typography className={classes.title} onClick={props.onViewDetails}>
              {props.title}
            </Typography>
          )}
          <Divider orientation={'horizontal'} />
          {props.loading ? (
            <Skeleton variant='rect' height='100px' />
          ) : (
            <Typography className={classes.description}>
              {props.description}
            </Typography>
          )}
          <Grid item>
            <Divider orientation={'horizontal'} />
            <Typography color={'primary'} className={classes.changeText}>
              {buttonText[variant]}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AssessmentCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  position: PropTypes.string,
  expanded: PropTypes.bool,
  maxHeight: PropTypes.string,
  description: PropTypes.string,
  onViewDetails: PropTypes.func,
  onComplete: PropTypes.func,
  onStart: PropTypes.func,
  saved: PropTypes.bool,
  visited: PropTypes.bool,
  enrolled: PropTypes.bool,
  onDelete: PropTypes.func,
  onSaved: PropTypes.func,
  onAnswer: PropTypes.func,
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['saved', 'recommended', 'enrolled', 'completed']),
};

AssessmentCard.defaultProps = {
  image: null,
  saved: null,
  onDelete: null,
  position: 'absolute',
  expanded: false,
  maxHeight: '180px',
  loading: false,
  variant: 'recommended',
};

export default AssessmentCard;
