import React from 'react';

import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';

const CancelSubscriptionDialog = ({ isOpen, onClose, onCancel }) => {
  return (
    <Dialog title='Cancel Subscription' open={isOpen} onClose={() => onClose()}>
      <div>
        <h1>Are you sure to cancel your subscription?</h1>
        <div className='flex justify-end'>
          <div className='mx-3'>
            <Button onClick={() => onClose()} title='No' />
          </div>
          <Button onClick={onCancel} title='Yes' />
        </div>
      </div>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
