import React, { useContext, useEffect, useState } from 'react';

import Payment from 'src/Api/UserPayment';
// import { GufhtuguHorizontal } from 'src/Assets/images/banner';
import TabbedContainer from 'src/Components/TabbedContainer';
// import AdsBanner from 'src/Components/v1/AdsBanner';
import { COMMON } from 'src/Constants';
import { PAYMENT_METHODS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

// import { BANNER_LINK } from 'src/Constants/Common';
import PaymentHistory from './PaymentHistory';
import PaymentPlan from './PaymentPlan';

const PaymentPage = () => {
  const [paymentText, setPaymentText] = useState(COMMON.paymentText);
  const [shouldChangeRoute, setShouldChangeRoute] = useState(true);
  const [easyPaisaPaymentHistory, setEasyPaisaPaymentHistory] = useState(null);

  const { token } = useContext(HomeContext);
  const { userPayment } = useContext(AppContext);

  const getEasyPaisaPaymentHistory = async () => {
    const response = await Payment.getEasyPaisaPaymentHistory(
      token,
      userPayment?.order_id,
    );
    setEasyPaisaPaymentHistory(response?.data?.data);
  };

  useEffect(() => {
    if (
      userPayment &&
      userPayment?.payment_method === PAYMENT_METHODS.EASY_PAISA
    ) {
      getEasyPaisaPaymentHistory();
    }
  }, [userPayment]);

  return (
    <div className='lg:ml-80'>
      <h1 className='text-3xl my-9 lg:mx-10 mx-3 font-semibold'>Upgrade</h1>
      <h2 className='text-xl font-semibold text-SSBlack lg:mx-10 mx-3'>
        {paymentText}
      </h2>

      <div className='lg:mx-10'>
        <TabbedContainer
          tabs={[
            {
              name: 'Upgrade Plans',
              content: (
                <PaymentPlan
                  shouldChangeRoute={shouldChangeRoute}
                  onRouteChange={() => setShouldChangeRoute(false)}
                />
              ),
            },
            {
              name: 'Payment History',
              content: (
                <PaymentHistory
                  easyPaisaPaymentHistory={easyPaisaPaymentHistory}
                />
              ),
            },
          ]}
          setState={setPaymentText}
          name='payment'
        />
      </div>
      {/* <AdsBanner
        className='mx-10 my-4'
        image={GufhtuguHorizontal}
        url={BANNER_LINK?.[0]}
      /> */}
    </div>
  );
};

export default PaymentPage;
