import { useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';

import UserJobs from 'src/Api/GetJobs';
import Loader from 'src/Components/Loader';
import EmptyCard from 'src/Components/v1/EmptyCard';
import JobsCard from 'src/Components/v1/JobsCard';
import Search from 'src/Components/v1/Search';
import { Auth as AuthConstants } from 'src/Constants';
import { HomeContext } from 'src/Contexts';
import {
  facebookEvent,
  getEmptyCardText,
  sortJobsByDate,
} from 'src/Utils/utils';

const RecommendedJobs = ({ userJobs, jobDescription }) => {
  const { liveJobLoader, userProfile } = useContext(HomeContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchMessage, setSearchMessage] = useState('');
  const [searchJobs, setSearchJobs] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const getSearchJobs = async (event) => {
    facebookEvent('Search');
    if (userProfile?.country && searchQuery) {
      setSearchLoader(true);
      event.preventDefault();
      try {
        const data = await UserJobs.getSearchJobs(token, {
          country: userProfile?.country?.name,
          profession: searchQuery,
        });
        if (data) {
          setSearchMessage(data?.data?.data?.message);
          setSearchJobs(sortJobsByDate(data.data.data?.scraping_data));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoader(false);
      }
    }
  };

  const sortByLocation = (a, b) => {
    const locationA = a?.location?.toLowerCase().replace(/ā/gi, 'a');
    const locationB = b?.location?.toLowerCase().replace(/ā/gi, 'a');
    const userCity = userProfile?.city?.name.toLowerCase();

    const isUserCityA = locationA?.includes(userCity);
    const isUserCityB = locationB?.includes(userCity);

    if (isUserCityA && !isUserCityB) {
      return -1;
    } else if (!isUserCityA && isUserCityB) {
      return 1;
    }
    return 0;
  };

  const filteredJobs = useMemo(() => {
    // return sortJobsByDate(userJobs?.scraping_data);
    return userJobs?.scraping_data?.sort(sortByLocation);
  }, [userJobs?.scraping_data]);

  const renderJobCards = (jobsToMap, isSearchableJob) => {
    return jobsToMap?.map((job, index) => (
      <>
        <div key={index} className='mx-auto h-full w-full'>
          <JobsCard
            jobs={job}
            jobDescription={jobDescription}
            userJobs={userJobs}
            index={index}
            isSearchableJob={isSearchableJob}
          />
        </div>
      </>
    ));
  };

  const renderContent = () => {
    if (liveJobLoader || searchLoader) {
      return (
        <div className='flex justify-center items-center w-full h-[70vh]'>
          <Loader />
        </div>
      );
    }
    if (searchQuery && searchJobs?.length) {
      return renderJobCards(searchJobs, true);
    }
    if (filteredJobs?.length && !searchMessage) {
      return renderJobCards(filteredJobs);
    }

    return (
      <EmptyCard
        text={getEmptyCardText(
          userJobs?.scraping_data?.length,
          filteredJobs,
          searchQuery,
          'No Recommended Jobs to show',
        )}
      />
    );
  };

  useEffect(() => {
    setSearchJobs([]);
    setSearchMessage('');
  }, [!searchQuery]);

  return (
    <>
      <form onSubmit={(e) => getSearchJobs(e)}>
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearch={getSearchJobs}
        />
      </form>

      <div
        className={`${
          filteredJobs?.length && !searchLoader && !searchMessage
            ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
            : 'flex flex-wrap'
        } mt-5`}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default RecommendedJobs;
