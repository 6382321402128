import Api from './index';
import Response from './Response';

const { next } = Response;

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const UploadImage = {
  uploadImage: async (token, imageDataUrl) => {
    const endpoint = '/user/upload-score';

    const config = getConfig(token);

    const formData = new FormData();
    formData.append('file', imageDataUrl);

    try {
      const response = await Api.post(endpoint, formData, config);
      if (response) {
        return response;
      }
      throw 'Image upload failed';
    } catch (e) {
      next(e);
    }
  },
};

export default UploadImage;
