/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';

import Loader from 'src/Components/Loader';
// import BannerSection from 'src/Components/v1/BannerSection';
// import { BANNER_IMAGES, BANNER_LINK } from 'src/Constants/Common';
// import BannerSection from 'src/Components/v1/BannerSection';
// import { BANNER_IMAGES, BANNER_LINK } from 'src/Constants/Common';
import { HomeContext } from 'src/Contexts';

import placeholderImage from '../../Assets/images/jobs/no-recommended-placeholder.svg';
import SurveyCard from '../../Components/v1/SurveyCard';

const Available = () => {
  const context = useContext(HomeContext);
  // let bannerIndex = 0;
  const [renderedSurveys, setRenderedSurveys] = useState(0);

  const questionsArray = context?.surveyData;
  const submittedSurveys = context?.submittedSurveyData?.map(
    (submittedSurvey) => submittedSurvey?.surveyData?.surveyQuestions,
  );

  const matchArrays = (arr1, arr2) => {
    if (arr1?.survey_questions === null) {
      return [arr1, arr2];
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const item1 of arr1?.survey_questions) {
      for (const item2 of arr2) {
        if (item1?.question === item2?.question) {
          return null;
        }
      }
    }
    return [arr1, arr2];
  };

  useEffect(() => {
    const count = questionsArray?.reduce((acc, survey) => {
      for (const sur of submittedSurveys) {
        if (!matchArrays(survey, sur)) return acc;
      }
      return acc + 1;
    }, 0);
    setRenderedSurveys(count);
  }, [submittedSurveys]);

  return (
    <div>
      {renderedSurveys?.length ? (
        <p className='bg-lighterBlue text-PurpleBlack font-medium mt-9 p-3 rounded-md'>
          Please fill these surveys so we can fine-tune your score and
          recommendations
        </p>
      ) : null}
      {questionsArray === null ? (
        <div className='flex justify-center items-center w-full h-[70vh]'>
          <Loader />
        </div>
      ) : null}

      {questionsArray !== null && !questionsArray.length ? (
        <div className='mx-auto flex justify-center items-center h-80 my-20 md:mr-20'>
          <div>
            <img className='mx-auto' src={placeholderImage} alt='' />
            <h1 className='text-xl mt-5 font-semibold'>No Available Surveys</h1>
          </div>
        </div>
      ) : null}

      <div
        className={`${
          questionsArray?.length
            ? 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 '
            : 'flex flex-col md:flex-row md:flex-wrap'
        } `}
      >
        {context.submittedSurveyData === null ? (
          <div className='flex justify-center items-center w-full h-[70vh]'>
            <Loader />
          </div>
        ) : (
          questionsArray?.map((survey, index) => {
            const submittedSurveys = context?.submittedSurveyData?.map(
              (submittedSurvey) => {
                const submittedSurveyQuestions =
                  submittedSurvey?.surveyData?.surveyQuestions;
                return submittedSurveyQuestions;
              },
            );
            for (const sur of submittedSurveys) {
              if (!matchArrays(survey, sur)) return null;
            }

            return (
              <>
                <div
                  key={index}
                  className='md:mr-5 sm:mr-2 my-9 flex flex-wrap'
                >
                  <SurveyCard
                    available
                    questionsData={survey}
                    key={index}
                    questionLength={survey?.length}
                  />
                </div>

                {/* <BannerSection
                  index={index}
                  bannerIndex={bannerIndex}
                  bannerImages={BANNER_IMAGES}
                  bannerLinks={BANNER_LINK}
                  filteredJobsLength={questionsArray?.length}
                />
                <div className=' hidden'>
                  {index % 3 === 2 && bannerIndex++}
                </div> */}
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Available;
