import moment from 'moment';

import { Star } from '@material-ui/icons';

import UserAvatar from 'src/Components/v1/UserAvatar';

const ReviewCard = (props) => {
  const ratingStars = [];

  if (props.rating) {
    for (let i = 1; i <= 5; i++) {
      if (i <= props.rating) {
        ratingStars.push(
          <Star
            className='text-DarkBluePurple text-xs'
            style={{ fontSize: '18px' }}
          />,
        );
      } else {
        ratingStars.push(
          <Star
            className='text-gray-300 text-xs'
            style={{ fontSize: '18px' }}
          />,
        );
      }
    }
  }

  return (
    <div className='review-card p-4'>
      <div className='flex flex-col bg-white shadow-lg rounded-lg p-6 w-full'>
        <div className='flex items-center mb-2'>
          <div className='flex flex-grow-0 mr-2'>
            <UserAvatar
              color={'#222B6D'}
              name={props.name}
              image={props.picture}
              size='48'
            />
          </div>
          <div className='flex flex-grow-[3] flex-col'>
            <span className='text-gray-800 font-bold capitalize'>
              {props.name}
            </span>
            <span className='text-gray-600 text-xs font-semibold'>
              {moment(props?.date).format('MMMM D, YYYY')}
            </span>
          </div>

          <div className='flex flex-col'>
            <div className='flex items-center mb-2'>{ratingStars}</div>
          </div>
        </div>
        <p className='text-gray-600 text-sm mt-3 break-words'>
          {props.message}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
