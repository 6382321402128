/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from './Dialog';

const useStyles = makeStyles(() => ({
  container: {
    fontSize: '16px',
    color: '#505050',
    fontFamily: 'Avenir',
    textAlign: 'justify',
  },
}));

const SkillReadinessDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      dashboard={true}
      open={props.open}
      onClose={props.onClose}
      title='Skills Readiness'
    >
      <Grid className={classes.container}>
        <p>
          In today's job market, the importance of skills readiness cannot be
          overstated. The key unlocks career growth, professional fulfillment,
          and personal advancement. Here's why skills readiness matters and how
          it can supercharge your career:
        </p>
        <br />
        <p className='text-lg font-semibold text-DarkBluePurple'>
          Skills Readiness: Your Path to Career Success
        </p>
        <br />
        <div>
          <div className='font-semibold text-DarkBluePurple '>
            1. Course Selection for Adaptive Skill Development:
          </div>
          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Lifelong Learning: Embrace continuous learning by staying updated
              with industry trends and emerging technologies through courses,
              workshops, and online resources.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Knowledge Expansion: Invest time reading books, articles, and
              research to broaden your knowledge and keep your skills relevant.
            </p>
          </div>
        </div>
        <br />
        <div>
          <div className='font-semibold text-DarkBluePurple '>
            2. Adaptability and Resilience:
          </div>
          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Embracing Change: Develop a mindset that welcomes change, new
              ideas, and technologies, allowing you to adapt swiftly to evolving
              situations.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Resilience Building: View setbacks as opportunities for growth and
              learning, cultivating resilience in facing challenges.
            </p>
          </div>
        </div>
        <br />
        <div>
          <div className='font-semibold text-DarkBluePurple '>
            3 Professional Growth and Networking:
          </div>
          <div className='flex'>
            <span>a.</span>
            <p className='ml-1'>
              Mentorship and Feedback: Seek guidance and feedback from
              experienced professionals and mentors in your field to refine your
              skills and knowledge.
            </p>
          </div>
          <div className='flex'>
            <span>b.</span>
            <p className='ml-1'>
              Networking and Collaboration: Build a solid professional network
              by attending industry events and collaborating with colleagues
              from diverse backgrounds to enhance your teamwork skills.
            </p>
          </div>
        </div>
      </Grid>
    </Dialog>
  );
};

SkillReadinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SkillReadinessDialog.defaultProps = {};

export default SkillReadinessDialog;
