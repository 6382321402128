import React from 'react';
import {
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import twitterIcon from 'src/Assets/images/v1/icons/x-twitter.svg';
import ShareButton from 'src/Components/v1/ShareButton';

const SocialShare = ({ imageUrl, total, snapShotHandler }) => {
  const shareUrl = 'https://www.skilledscore.com';

  const socialTitle = (total) => {
    return `🚀 Exciting News! 🚀 \nI’m pleased to share that I’ve achieved a skilled score of ${total}, reflecting my current career readiness \nVisit www.skilledscore.com to find your score today`;
  };

  return (
    <div className='flex flex-wrap justify-end mx-1 sm:mx-5'>
      <span className='text-BluePurple mx-2 font-semibold mt-0.5'>
        Share Score Via
      </span>
      <div className='mx-1'>
        <ShareButton
          platform='fb'
          imageUrl={imageUrl}
          snapShotHandler={snapShotHandler}
        />
      </div>
      <div className='mx-1'>
        <ShareButton
          platform='linkedin'
          imageUrl={imageUrl}
          snapShotHandler={snapShotHandler}
        />
      </div>
      <div className='mx-1'>
        <TwitterShareButton
          url={shareUrl}
          title={socialTitle(total)}
          hashtag={'#SkilledScore #Skills #ProfessionalGrowth'}
        >
          <img src={twitterIcon} className='w-7 h-7 ' />
        </TwitterShareButton>
      </div>
      <div className='mx-1'>
        <WhatsappShareButton
          url={shareUrl}
          title={socialTitle(total)}
          hashtag={'#SkilledScore #Skills #ProfessionalGrowth'}
        >
          <WhatsappIcon size={30} round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
