import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Tests from '../../../Api/Tests';
import header from '../../../Assets/images/start-course-dialog-header.svg';
import placeholder from '../../../Assets/images/upskills/no-recommended-placeholder.svg';
import AssessmentCard from '../../../Components/AssessmentCard';
import AssessmentProjectDialog from '../../../Components/AssessmentProjectDialog';
import StartCourseDialog from '../../../Components/CourseActionDialog';
import ItemsGrid from '../../../Components/Dashboard/ItemsGrid';
import { HomeContext } from '../../../Contexts';

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: '20px',
    color: '#505050',
    margin: '1% 0px',
  },
  desc: (props) => ({
    overflow: props ? 'visible' : 'hidden',
    height: props ? 'auto' : '3.6em',
  }),
  loadMore: {
    margin: '2rem 0',
  },
}));

const Test = (props) => {
  const classes = useStyles();

  const context = useContext(HomeContext);

  const [test, setTest] = useState(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(false);
  const [selected, setSelected] = useState(0);

  const onCardClick = (index) => {
    setSelected(index);
    setOpen(true);
  };

  const fetchLocalStorage = async () => {
    const data = await localStorage.getItem('loginData');
    const parsedData = await JSON.parse(data);
    setUser(parsedData?.user);
  };

  useEffect(() => {
    fetchLocalStorage();
  }, []);

  const fetchAllTests = () => {
    Tests.allTests(user.id)
      .then((data) => {
        setTest(data.data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (user.id) {
      fetchAllTests(user.id);
    }
  }, [user.id]);

  const onSubmitTest = (test_id) => {
    Tests.updateTests({ user_id: user?.id, test_id }).then(() => {
      fetchAllTests(user.id);
      setOpen(false);
    });
  };

  return (
    <Grid direction='row' justify='center' container>
      <Grid xs={12} item>
        <Typography className={classes.heading} variant='h4'>
          Test
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <ItemsGrid
          data={test}
          placeholderImage={placeholder}
          placeholderText='No Test available'
          loader={test ? false : true}
        >
          <Grid spacing={4} container>
            {test === null
              ? [0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => (
                  <Grid key={index} lg={3} md={4} sm={6} xs={12} item>
                    <AssessmentCard loading={true} />
                  </Grid>
                ))
              : test.map((upSkill, index) => (
                  <Grid key={upSkill.id} lg={3} md={4} sm={6} xs={12} item>
                    <AssessmentCard
                      onViewDetails={() => onCardClick(index)}
                      onDelete={() =>
                        context.removeItem.upskills(upSkill, 'recommended')
                      }
                      onStart={() => {
                        setSelected(index);
                      }}
                      onAnswer={() => setSelected(index)}
                      onSaved={() => {
                        context.markStatusItem.upskills(
                          context.upskills.recommended[index],
                          'saved',
                        );
                      }}
                      variant={'recommended'}
                      {...props}
                      {...upSkill}
                    />
                  </Grid>
                ))}
          </Grid>
        </ItemsGrid>
      </Grid>
      {test > 0 ? (
        <AssessmentProjectDialog
          isTest
          onSubmitProject={onSubmitTest}
          onVisit={() => {
            context.markStatusItem.upskills(
              context.upskills.recommended[selected],
              'visited',
            );
          }}
          {...test[selected]}
          onClose={() => setOpen(false)}
          open={open}
        />
      ) : null}
      <StartCourseDialog
        title='Start a new course'
        image={header}
        description={
          'By clicking on "GOT IT" button SkilledScore will assume that you\'ve started learning this course. You can view this course in enrolled tab.'
        }
        onConfirm={async () => {
          await context.markStatusItem.upskills(
            context.upskills.recommended[selected],
            'enrolled',
          );
        }}
      />
    </Grid>
  );
};

export default Test;
