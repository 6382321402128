import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import backImage from '../../Assets/images/home-back.svg';
import AppBarContainer from '../../Components/AppBarContainer';
import { Auth as AuthConstants } from '../../Constants';
import Assessments from '../../Containers/Home/Assessments';
import Jobs from '../../Containers/JobNew/index';
import Upskill from '../../Containers/upskillNew';
import { AppContext, HomeContext, HomeContextProvider } from '../../Contexts';
import Dashboard from '../DashboardNew';
import Payment from '../Payment';
import Profile from '../ProfileNew/index';
import ReferAFriend from '../ReferAFriend';
import Reviews from '../Reviews/AllReviews';
import SuccessPayment from '../SuccessPayment';
import SurveyNew from '../SurveyNew';

import UploadCV from './Profile/UploadCV';
import Notifications from './Notifications';

import 'driver.js/dist/driver.css';

const setBodyStyle = (document) => {
  document.body.style.backgroundColor = '#f8f8fc';
  document.body.style.backgroundImage = `url(${backImage})`;
  document.body.style.backgroundPosition = 'right bottom';
  document.body.style.backgroundSize = 'auto';
  document.body.style.backgroundRepeat = 'no-repeat';
  document.body.style.backgroundAttachment = 'fixed';
};

const tabs = {
  '/': <Dashboard />,
  'jobs': <Jobs />,
  'upskill': <Upskill />,
  'survey': <SurveyNew />,
  'assessments': <Assessments />,
  'payment': <Payment />,
  'profile': <Profile />,
  'notifications': <Notifications />,
  'payment_success': <SuccessPayment />,
  'upload-cv': <UploadCV />,
  'reviews': <Reviews />,
  'refer-a-friend': <ReferAFriend />,
};

const Home = (props) => {
  const [tab, setTab] = useState('');
  const context = useContext(AppContext);
  const homeContext = useContext(HomeContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const history = useHistory();

  setBodyStyle(document);

  useEffect(() => {
    context.updateUser(token);
  }, [homeContext]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const specifiedTab = props.match.params.tab?.trim() || '/';
    setTab(specifiedTab);

    if (!tabs[specifiedTab]) {
      history.replace('/home');
    }
  }, [props.match.params, history]);

  return (
    <HomeContextProvider key={tab}>
      <Box
        name={context.user.name}
        email={context.user.email}
        profileImage={context.user.image}
        percentage={context.user.percentage}
        component={AppBarContainer}
        tab={tab}
      >
        {tabs[tab]}
      </Box>
    </HomeContextProvider>
  );
};

export default withRouter(Home);
