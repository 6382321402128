import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Divider, Grid, Typography } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';
import { FILE_SIZES, PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { facebookEvent } from 'src/Utils/utils';

import uploadingGif from '../../Assets/images/uploading-animation.gif';

import CVItem from './CVItem';

const UploadPane = (props) => {
  const [isUploading, setUploading] = useState(false);
  const [resumeSizeError, setResumeSizeError] = useState('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { setResumeError, resumeError, resumeEligibility } =
    useContext(HomeContext);
  const { userPayment } = useContext(AppContext);
  let upload;

  const handleAction = () => {
    FirebaseAnalyticsEvent('upload_resume', { via: 'linked_in' });
    facebookEvent('SubmitApplication');
    props.onAction('linkedin');
  };

  const isUserPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;
  const fileSize = isUserPremium
    ? FILE_SIZES.PREMIUM_USER
    : FILE_SIZES.FREE_USER;

  const handleFileChange = (files) => {
    if (!files || files.length === 0) return;
    const [file] = files;

    if (file.size > fileSize * 1024 * 1024) {
      setResumeError('');
      setResumeSizeError(`File size exceeds the ${fileSize}MB limit.`);
      props.onUploadFailed(`File size exceeds the ${fileSize}MB limit.`);
      return;
    }

    if (file.name.split('.').pop() !== 'pdf') {
      props.onUploadFailed('Please select a .pdf format');
      return;
    }

    setSelectedFile(file);
    setConfirmationOpen(true);
  };

  const handleUploadConfirm = () => {
    setConfirmationOpen(false);
    if (!selectedFile) return;

    setUploading(true);
    props
      .onUpload(selectedFile)
      .then(
        (res) => {
          props.onUploadComplete(res);
        },
        () => {
          props.onUploadFailed(
            'Please use a format which is not from LinkedIn',
          );
        },
      )
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Grid>
      <Grid>
        {isUploading ? <img alt='uploading-gif' src={uploadingGif} /> : <></>}
      </Grid>
      {props.prevFiles && props.prevFiles.length > 0 ? (
        <Grid>
          {props.prevFiles.map((file) => (
            <div key={file.key}>
              <CVItem
                onDelete={() => props.onDelete(file)}
                name={file.name}
                url={file.name}
              />
              <Divider variant='fullWidth' />
            </div>
          ))}
        </Grid>
      ) : (
        <></>
      )}
      <Grid className='lg:ml-80'>
        <div className='text-center'>
          <h1 className='font-medium text-gray-700 text-4xl mt-40 mb-8'>
            How would you like to get started?
          </h1>
          <h3 className='text-md text-gray-400 mb-3'>
            Select one of the options below to build your profile.
          </h3>
        </div>

        <input
          id='fileInput'
          type='file'
          accept='application/pdf'
          disabled={resumeEligibility?.isExceed}
          onChange={({ target: { files } }) => handleFileChange(files)}
          ref={(ref) => (upload = ref)}
          style={{ display: 'none' }}
        />

        <div className='flex justify-center'>
          <button
            disabled={isUploading || resumeEligibility?.isExceed}
            className='text-white cursor-pointer font-semibold mx-auto lg:w-[480px] w-72 rounded-md my-3 h-[45px] border bg-[#20254B] disabled:cursor-not-allowed disabled:opacity-80'
            onClick={handleAction}
          >
            {isUploading ? null : (
              <span className='mx-2'>
                <LinkedInIcon />
              </span>
            )}
            Upload your LinkedIn Profile
          </button>
        </div>
        <div className='flex justify-center'>
          <button
            disabled={isUploading || resumeEligibility?.isExceed}
            className='text-[#20254B] cursor-pointer font-semibold lg:w-[480px] w-72 rounded-md h-[35px] mx-2 border border-[#20254B] disabled:cursor-not-allowed disabled:opacity-60'
            onClick={() => {
              FirebaseAnalyticsEvent('upload_resume', { via: 'website' });
              facebookEvent('SubmitApplication');
              upload.click();
            }}
          >
            Upload your Resume
          </button>
        </div>

        {resumeSizeError && (
          <div className='w-[420px] mx-auto text-center mt-3 text-red-500'>
            {resumeSizeError}
          </div>
        )}

        <Typography
          variant={'subtitle1'}
          className='!text-gray-400 !mt-2'
          align={'center'}
        >
          Note: Upload resume up to {fileSize} MB.
        </Typography>

        {resumeError && (
          <div className='w-[420px] mx-auto text-center mt-3 text-red-500'>
            {resumeError}
          </div>
        )}
      </Grid>

      <Dialog
        title='Confirm File Upload'
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
      >
        <div>
          <Typography>
            Are you sure this is the correct File?
            <br />
            <strong>{selectedFile ? selectedFile.name : ''}</strong>
          </Typography>
        </div>
        <div className='flex justify-end'>
          <Button
            onClick={() => setConfirmationOpen(false)}
            title='Cancel'
            className='mr-2'
          />
          <Button onClick={handleUploadConfirm} title='Yes' />
        </div>
      </Dialog>
    </Grid>
  );
};

UploadPane.propTypes = {
  onUpload: PropTypes.any,
  onUploadComplete: PropTypes.func,
  onUploadFailed: PropTypes.func,
  prevFiles: PropTypes.array,
  onDelete: PropTypes.func,
  onAction: PropTypes.func,
};

UploadPane.defaultProps = {
  prevFiles: [],
  onDelete: () => {},
};

export default UploadPane;
