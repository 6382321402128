import React from 'react';

import { COMMON } from 'src/Constants';

const LockOverlay = ({ isIlo }) => {
  return (
    <div
      title={COMMON.PREMIUM_TEXT}
      className={`w-full h-full absolute flex items-center justify-center ${
        isIlo ? 'top-2 left-0' : 'bottom-1'
      }`}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        className='w-6 h-6'
      >
        <rect x='3' y='11' width='18' height='11' rx='2' ry='2' />
        <path d='M7 11V7a5 5 0 0 1 10 0v4' />
      </svg>
    </div>
  );
};

export default LockOverlay;
