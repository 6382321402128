import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { User } from '../../Api';
import addIcon from '../../Assets/images/v1/icons/add-icon.svg';
import certificateCancelIcon from '../../Assets/images/v1/icons/profile cancel icon.svg';
import { Auth as AuthConstants } from '../../Constants';
import { AppContext } from '../../Contexts';
import CertificateAndCourseInputDialog from '../CertificatesAndCoursesInputDialog';
import Loader from '../Loader';

import ProfileEmptyCard from './ProfileEmptyCard';

const CertificateCard = ({ title, onDelete }) => {
  return (
    <div>
      <div className='flex justify-between'>
        <h2 className='font-semibold truncate overflow-ellipsis'>{title}</h2>
        <img
          className='cursor-pointer'
          onClick={() => onDelete()}
          src={certificateCancelIcon}
          alt=''
        />
      </div>
      <hr className='my-5' />
    </div>
  );
};

const ProfileCertificateCard = ({
  user,
  getProfile,
  loader,
  placeholderImage,
  placeholderText,
}) => {
  const context = useContext(AppContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const updateUser = () => context.updateUser(token);

  const [certifications, setCertifications] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const certificates = [];
    user?.certificates?.map((item) =>
      certificates.push({
        title: `${item.title}`,
        // "subtitle": `${item.field_name}, ${item.location?? ''}`,
        // "fieldOfStudy": {"id": item.study_field_id, "title": item.field_name},
        // "locationText": item.location,
        // "startYear": item.start_year,
        // "endYear": item.end_year,
        ...item,
      }),
    );
    setCertifications(certificates);
  }, [user]);

  const refreshCertificates = (data) => {
    const certificates = [];
    data?.map((item) =>
      certificates.push({
        title: `${item.title}`,
        // "subtitle": `${item.field_name}, ${item.location?? ''}`,
        // "fieldOfStudy": {"id": item.study_field_id, "title": item.field_name},
        // "locationText": item.location,
        // "startYear": item.start_year,
        // "endYear": item.end_year,
        ...item,
      }),
    );
    setCertifications(certificates);
    updateUser();
    getProfile();
  };

  const handleCertificateUpdate = async (data) => {
    try {
      const res = await User.updateCertificate(data, token, { id: data.id });
      refreshCertificates(res?.data?.data);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const handleDeleteCertificate = (data) => {
    User.deleteCertificate(data.id, token).then(
      (res) => {
        refreshCertificates(res?.data?.data);
      },
      () => {
        // console.log('Delete Certificate: ', err);
      },
    );
  };

  const renderCertificates = () => {
    if (certifications?.length) {
      return certifications?.slice(0.3)?.map((data, index) => {
        return (
          <CertificateCard
            key={index}
            title={data.title}
            onDelete={() => handleDeleteCertificate(data)}
          />
        );
      });
    }
    return <ProfileEmptyCard {...{ placeholderText, placeholderImage }} />;
  };

  return (
    <div id='certificates' className='bg-white my-10 py-7 px-5'>
      <div className='flex justify-between mb-6'>
        <h1 className='text-2xl font-semibold text-gray-900'>
          Certificates and Courses
        </h1>
        <img
          className='cursor-pointer'
          onClick={() => setOpen(true)}
          src={addIcon}
          alt=''
        />
      </div>
      <div>
        {loader ? (
          <div className='flex justify-center'>
            <Loader />
            <h1 className='mt-1'>Getting your Certificates</h1>
          </div>
        ) : (
          renderCertificates()
        )}
      </div>
      <CertificateAndCourseInputDialog
        onSubmitted={(data) => {
          handleCertificateUpdate(data);
          setOpen(false);
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

export default ProfileCertificateCard;
