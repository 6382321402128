import React, { useState } from 'react';

import Loader from './Loader';

const PaymentCard = ({
  planInfo,
  title,
  amount,
  buttonText,
  disable,
  onClick,
  duration,
  isActive,
  cardStyle,
  dollarToPKR,
  amountPKR,
  userLocation,
  currencyLoading,
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <>
      <div
        className={`w-full mt-8 max-w-sm  border border-gray-200 rounded-lg shadow-sm p-8 dark:bg-gray-800 dark:border-gray-700 mx-4 h-[500px] flex flex-col justify-between transform transition-transform hover:translate-y-[-10px] hover:shadow-2xl ${cardStyle} ${
          isActive ? 'bg-primaryBg' : 'bg-white'
        }`}
      >
        <div>
          <div className='flex justify-between items-center'>
            <h5
              className={`mb-4 text-xl font-bold dark:text-gray-400 ${
                isActive ? 'text-white' : 'text-primaryBg'
              }`}
            >
              {title}
            </h5>

            {isActive ? (
              <div className='flex justify-center items-center'>
                <h5 className='mb-4 text-sm bg-white text-primaryBg font-semibold rounded-xl px-3 py-1'>
                  Subscribed
                </h5>
              </div>
            ) : (
              <div className='flex justify-center items-center'>
                <h5 className='mb-4 text-sm bg-primaryBg text-white font-semibold rounded-xl px-3 py-1'>
                  Without Tax
                </h5>
              </div>
            )}
          </div>

          <div
            className={`flex items-baseline text-primaryBg text-center  w-full dark:text-white ${
              isActive ? 'text-white' : 'text-primaryBg'
            } `}
          >
            {currencyLoading && !userLocation ? (
              <Loader className='!h-5 !w-5' />
            ) : (
              <>
                <span className='text-3xl font-semibold'>
                  {userLocation === 'PK' ? 'Rs' : '$'}
                </span>

                <span className='text-3xl font-extrabold tracking-tight'>
                  {userLocation === 'PK'
                    ? `${
                        Number(amountPKR).toLocaleString() ||
                        Math.floor(dollarToPKR * amount).toLocaleString()
                      }`
                    : `${amount.toLocaleString()}`}
                  <span className='text-lg font-semibold'> / {duration}</span>
                </span>
              </>
            )}
          </div>
          <ul role='list' className='space-y-4 md:space-y-5 my-4'>
            {planInfo.map(({ description }, index) => {
              return (
                <li className='flex space-x-3 items-center' key={index}>
                  <svg
                    className={`flex-shrink-0 w-4 h-4  dark:text-primaryBg ${
                      isActive ? 'text-white' : 'text-primaryBg'
                    } `}
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                  </svg>
                  <span
                    className={`text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ${
                      isActive ? 'text-white' : 'text-primaryBg'
                    }`}
                  >
                    {description}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <button
          type='button'
          disabled={disable}
          onClick={handleOnClick}
          className={`text-white focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center disabled:cursor-not-allowed ${
            isActive ? 'bg-white !text-primaryBg' : 'bg-primaryBg text-white'
          } `}
        >
          {loading ? <Loader className='!h-5 !w-5' /> : buttonText}
        </button>
      </div>
    </>
  );
};

export default PaymentCard;
