import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ClipboardIcon from '../../Assets/images/clipboard-icon';
import header from '../../Assets/images/profile/upload-sv-header.svg';
import uploadIcon from '../../Assets/images/upload-icon.svg';

import BasicPane from './BasicPane';

const useStyles = makeStyles(() => ({
  top: {
    backgroundColor: '#D1E3FF',
    padding: '2vw 0',
  },
  bottom: {
    backgroundColor: '#FFFFFF',
    padding: '2vw',
  },
  prompt: {
    color: '#58595b',
  },
  promptText: {
    color: 'inherit',
    marginBottom: '1vw',
  },
  summaryButton: {
    textTransform: 'uppercase',
    marginTop: '5%',
  },
}));

const UploadCVPane = (props) => {
  const classes = useStyles(props);
  const [user, setUser] = useState({});
  const [disable, setDisable] = useState(true);

  const fetchLocalStorage = () => {
    const data = localStorage.getItem('loginData');
    const parsedData = JSON.parse(data);
    setUser(parsedData?.user);
  };

  useEffect(() => {
    fetchLocalStorage();
  }, []);

  useEffect(() => {
    if (user?.thread_id) {
      setDisable(false);
    }
  }, [user]);

  return (
    <Grid
      component={BasicPane}
      noPadding={true}
      xs={12}
      alignItems='center'
      justify='center'
      container
    >
      <Grid
        alignItems='center'
        justify='center'
        className={classes.top}
        xs={12}
        item
        container
      >
        <img src={header} alt='Upload CV' />
      </Grid>
      <Grid className={classes.bottom} xs={12} item>
        <Grid xs={12} container>
          <Grid className={classes.prompt} xs={12} item>
            <Typography className={classes.promptText} variant='subtitle1'>
              Upload your CV to complete your profile automatically
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Button
              variant='contained'
              color='primary'
              startIcon={<img src={uploadIcon} alt='Upload' />}
              fullWidth={true}
              onClick={() => {
                props.history.push('/home/profile/upload-cv');
              }}
              // disabled
            >
              UPLOAD CV
            </Button>
            <Button
              className={classes.summaryButton}
              variant='contained'
              color='primary'
              disabled={disable}
              startIcon={
                <div style={{ width: '25px', height: '25px' }}>
                  <ClipboardIcon />
                </div>
              }
              fullWidth={true}
              onClick={() => {
                props.history.push('/home/profile/resume');
              }}
            >
              Resume Reviewer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withRouter(UploadCVPane);
