import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SkillsInputItem from './UploadCV/SkillsInputItem';

const useStyles = makeStyles(() => ({
  skillsContainer: {
    margin: '8px 0',
    maxHeight: '250px',
    overflowY: 'scroll',
  },
}));

const SkillsUpdateSection = (props) => {
  const classes = useStyles();

  const deleteItem = (itemIndex) => {
    const newData = props.data.filter((skill, index) => itemIndex !== index);
    props.onChange(newData);
  };

  const updateItem = (index, item) => {
    const newData = [...props.data];
    newData[index] = item;
    props.onChange(newData);
  };

  return (
    <Grid
      className={classes.skillsContainer}
      direction='row'
      container
      item
      xs={12}
      sm={12}
      md={12}
    >
      {props.data.map((item, index) => (
        <Grid key={index.toString()} item md={12} sm={12}>
          <SkillsInputItem
            title={item.name}
            rate={item.rate}
            onDelete={() => deleteItem(index)}
            onChange={(value) => updateItem(index, { ...item, rate: value })}
          />
        </Grid>
      ))}
    </Grid>
  );
};

SkillsUpdateSection.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
};

SkillsUpdateSection.defaultProps = {
  data: [],
};

export default SkillsUpdateSection;
