import React, { useEffect, useRef, useState } from 'react';
import {
  MdCancel,
  MdCheckCircleOutline,
  MdCloudUpload,
  MdDownload,
} from 'react-icons/md';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  'dialogContent': {
    marginRight: '4%',
    marginLeft: '4%',
    marginBottom: '2%',
  },
  'dialogTitle': {
    marginTop: '1%',
  },
  'heading': {
    fontFamily: 'Avenir-Heavy',
    fontSize: '16px',
    fontWeight: '900',
    color: '#707070',
    margin: '0.5vh 0',
    marginTop: '10px',
  },
  'closeButton': {
    color: '#000',
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
  },
  'description': {
    fontFamily: 'Avenir-Roman',
    fontSize: '14px',
    color: '#505050',
    textAlign: 'justify',
  },
  'detailsLogo': {
    marginTop: '-5%',
  },
  'chipStyle': {
    marginRight: '0.5vw',
    marginBottom: '0.5vw',
  },
  'contentContainer': {
    margin: '1vh 0',
  },
  'playButton': {
    fontSize: '10vh',
  },
  'visitSiteButton': {
    width: '100%',
    marginTop: '6%',
    textTransform: 'uppercase',
  },
  'sideHeading': {
    fontSize: '22px',
    fontWeight: '800',
    marginBottom: '5px',
  },
  'file': {
    display: 'none',
  },
  'fileUploadContainer': {
    'marginTop': '10px',
    'border': '2px solid #347aea',
    'height': '140px',
    'width': '100%',
    'borderRadius': '10px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  'uploadIcon': {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'uploadText': {
    fontSize: '14px',
    textAlign: 'center',
  },
  'uploadSpan': {
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    color: '#347aea',
  },
  'hiddenInputField': {
    display: 'none',
  },
  'fileUploader': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    height: '120px',
    borderRadius: '10px',
    width: '90px',
    border: 'blue 1px solid',
  },
  'cancelIcon': {
    marginTop: '-5%',
    marginRight: '-7%',
    height: '15px',
    margin: 'auto',
    backgroundColor: 'white',
  },
  'downloadButton': {
    'color': '#347aea',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  'downloadIcon': {
    color: '#347aea',
    // marginBottom: "-8px",
  },
  'skillContainer': {
    display: 'block',
    overflow: 'auto',
    height: '80px',
    width: '350px',
  },
  'descriptionBox': {
    display: 'block',
    overflow: 'auto',
    height: '350px',
  },

  'tickAnimationContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'descriptionContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  'downloadButtonContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'tickIcon': {
    animation: '$fadeIn 1s ease-in',
    marginTop: 30,
    color: 'green',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  'tickText': {
    marginTop: '10px',
  },
}));

const AssessmentProjectDetails = (props) => {
  const classes = useStyles(props);

  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(props.isSubmitted);

  useEffect(() => {
    setIsUploaded(props.isSubmitted);
  }, [props.isSubmitted]);

  const inputFileRef = useRef(null);

  const onOpenInputField = () => {
    if (inputFileRef?.current) inputFileRef.current.click();
  };

  const handleFileChange = (file) => setFile(file);
  return (
    <Grid
      xs={12}
      justify='space-between'
      spacing={2}
      className={classes.parentContainer}
      container
    >
      <input
        accept={props.isTest ? '.pdf' : '.zip'}
        type='file'
        ref={inputFileRef}
        value={file?.fileName || ''}
        onChange={(e) => handleFileChange(e.target.files?.[0])}
        className={classes.hiddenInputField}
      />
      <Grid xs={12} md={8} item>
        <Grid
          xs={12}
          component={ButtonBase}
          className={classes.imageContainer}
          alignItems='center'
          justify='center'
          alignContent='center'
          container
        ></Grid>
        <Grid
          xs={12}
          alignItems='flex-start'
          className={classes.contentContainer}
          direction='column'
          container
        >
          <div className={classes.descriptionContainer}>
            <Typography className={classes.heading} variant='h5'>
              Description
            </Typography>
            {props.isTest ? (
              <div className={classes.downloadButtonContainer}>
                <Typography className={classes.downloadButton}>
                  Download Test (.pdf)
                </Typography>
                <MdDownload className={classes.downloadIcon} size={24} />
              </div>
            ) : null}
          </div>
          <Box className={classes.descriptionBox}>
            <Typography className={classes.description} variant='subtitle1'>
              <div dangerouslySetInnerHTML={{ __html: props.description }} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {!isUploaded ? (
        <Grid xs={12} md={4} item>
          <Grid className={classes.detailsCardContainer} container>
            <Typography className={classes.sideHeading} variant='h5'>
              Upload and attach files
            </Typography>
            <Typography variant='h5'>
              Upload and attach files to this conversation
            </Typography>
            {!file?.name ? (
              <Grid
                onClick={onOpenInputField}
                className={classes.fileUploadContainer}
              >
                <Grid>
                  <input type='file' className={classes.file} />
                  <Box className={classes.uploadIcon}>
                    <MdCloudUpload size={30} color='gray' />
                  </Box>

                  <Grid className={classes.uploadText}>
                    <span className={classes.uploadSpan}>Click to upload</span>{' '}
                    or drag and drop <br /> {props.isTest ? '.pdf ' : '.zip '}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.fileUploadContainer}>
                <Grid className={classes.fileUploader}>
                  <Typography variant='body2'>{file?.name}</Typography>
                  <MdCancel
                    onClick={() => {
                      setFile(null);
                    }}
                    size={30}
                    className={classes.cancelIcon}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.visitSiteButton}
              onClick={() => props.onSubmitProject(props.id)}
              variant={'contained'}
              color={'primary'}
            >
              UPLOAD
            </Button>
          </Grid>
          {props.skills ? (
            <Grid
              xs={12}
              alignItems='flex-start'
              className={classes.contentContainer}
              direction='column'
              container
            >
              <Typography className={classes.heading} variant='h5'>
                Skills
              </Typography>
              <Grid
                className={classes.skillContainer}
                direction='row'
                container
              >
                {props.skills.map((skill, index) => (
                  <Chip
                    key={index}
                    className={classes.chipStyle}
                    label={skill}
                    variant='outlined'
                    color='primary'
                  />
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <Grid xs={12} md={4} item>
          <div className={classes.tickAnimationContainer}>
            <Typography>
              {props.isTest ? 'Test' : 'Project'} Uploaded Successfully!{' '}
            </Typography>
            <MdCheckCircleOutline size={50} className={classes.tickIcon} />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

AssessmentProjectDetails.propTypes = {
  link: PropTypes.string,
  author: PropTypes.string,
  description: PropTypes.string,
  skills: PropTypes.array,
  duration: PropTypes.string,
  skillLevel: PropTypes.string,
  image: PropTypes.string,
  onAction: PropTypes.func,
};
AssessmentProjectDetails.defaultProps = {};

export default AssessmentProjectDetails;
