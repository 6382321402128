import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import placeholder from '../../Assets/images/profile-image-placeholder.svg';
import HoverEditPane from '../HoverEditPane';

import SummaryPane from './SummaryPane';

const useStyles = makeStyles(() => ({
  topSection: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  topSectionFront: {
    backgroundPosition: 'right bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    gridArea: '1 / 2 / 2 / 3',
  },

  position: {
    fontFamily: 'Avenir-Roman',
    fontSize: '1rem',
    color: 'inherit',
    marginTop: '0.5vh',
  },
  location: {
    fontFamily: 'Avenir-Roman',
    fontSize: '0.8rem',
    color: '#FFFFFF',
  },
  name: {
    fontFamily: 'Ubuntu',
    fontSize: '2rem',
    color: 'inherit',
  },
  imageContainer: {
    padding: '1.5rem',
  },
  infoContainer: {
    color: '#FFFFFF',
    padding: '1.5rem 0',
  },
  image: {
    width: '12vh',
    height: '12vh',
    border: 'solid 2px #fff',
  },
  editContainer: {
    display: 'none',
    padding: '0.5vh',
    borderRadius: '2vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoPane: {
    backgroundColor: 'transparent',
  },
}));

const PrimaryInfo = (props) => {
  const classes = useStyles(props);

  return (
    <div className='bg-primaryBg'>
      <Grid className={classes.container}>
        <Box xs={12} item>
          <div className={classes.topSectionFront} />
          <Grid className={classes.topSectionBack}>
            <Grid>
              <HoverEditPane
                onAction={props.onAction}
                icon={<EditIcon fontSize={'small'} color='secondary' />}
              >
                <Grid direction='row' alignItems='center' container>
                  <Grid component={Box} className={classes.imageContainer} item>
                    <Avatar
                      alt='Profile Image'
                      className={classes.image}
                      src={props.image ? props.image : placeholder}
                    />
                  </Grid>
                  <Grid item>
                    <Grid
                      component={Box}
                      className={classes.infoContainer}
                      direction='column'
                      container
                    >
                      <Typography className={classes.name}>
                        {props.name}
                      </Typography>
                      <Typography
                        className={classes.position}
                        variant='subtitle1'
                      >
                        {props.profession}
                      </Typography>
                      <Typography
                        className={classes.location}
                        variant='subtitle2'
                      >
                        {props.city ? `${props.city.name}, ` : ''}{' '}
                        {props.country ? props.country.name : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </HoverEditPane>
            </Grid>
          </Grid>
        </Box>
        <Grid xs={12} component={Box} className={classes.bottomSection} item>
          <SummaryPane
            isNewSummary={props.isNewSummary}
            summary={props.summary}
          />
        </Grid>
      </Grid>
    </div>
  );
};

PrimaryInfo.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  profession: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  onAction: PropTypes.func,
};

export default PrimaryInfo;
