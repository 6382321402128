import React from 'react';

import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { formatJobDate } from 'src/Utils/utils';

import activeIcon from '../../Assets/images/jobs/active-icon.svg';
import earlyIcon from '../../Assets/images/jobs/early-icon.svg';
import avatar from '../../Assets/images/v1/icons/job-icon.svg';
import Loader from '../Loader';

import Modal from './Modal/Modal';

const JobDescriptionModal = ({
  showModal,
  setDescription,
  description,
  job,
  setShowModal,
  toggleModal,
  index,
}) => {
  return (
    <Modal isOpen={showModal === index}>
      <div className='w-full md:w-fit xl:w-full py-6 font-[karla-regular] rounded-lg bg-white'>
        <div className='px-4  md:px-6'>
          <div className='flex md:flex-row my-3 md:justify-between'>
            <div className='flex'>
              <img
                className='h-12 rounded-full bg-DarkBluePurple w-12'
                src={job?.image || avatar}
                alt='Company Logo'
              />
              <div className='mx-3 overflow-hidden'>
                <h1 className='text-xl md:w-80  font-semi-bold'>
                  {job.jobTitle}
                </h1>
                <p className='text-gray-500'>{job?.companyName}</p>
                <p className='text-gray-500'>{job?.location}</p>
              </div>
            </div>
            <button
              onClick={() => {
                toggleModal(-1);
                setDescription('');
              }}
              className='rounded-2xl whitespace-nowrap border-2 px-4 h-8 border-red-600 text-red-600'
            >
              Close
            </button>
          </div>
          {job?.jobPosting && (
            <p className='text-lighterGrey'>{formatJobDate(job?.jobPosting)}</p>
          )}

          <div className=' flex justify-center border-2 border-gray-200 truncate overflow-ellipsis rounded-xl whitespace-nowrap text-center w-full p-1 my-2'>
            <img
              src={job?.tag === 'Actively Hiring' ? activeIcon : earlyIcon}
              alt=''
            />
            <p className='ml-2'>{job?.tag || 'Be an early applicant'}</p>
          </div>

          <p className='text-xl mb-2'>Job Description :</p>
          <p className='w-full text-left h-40 overflow-y-scroll overflow-x-hidden'>
            {!description ? (
              <div className='flex justify-center items-center w-full h-40'>
                <Loader className='!h-5' />
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </p>
        </div>
        <hr className='md:my-5 my-2' />
        <div className='flex px-4 md:px-6 justify-end'>
          <button
            className='px-2 border-2  bg-DarkBluePurple text-white rounded-lg h-10'
            onClick={() => {
              setShowModal(false);
              FirebaseAnalyticsEvent('apply_on_site');
            }}
          >
            <a target='_blank' href={job?.href} rel='noreferrer'>
              Apply on site
            </a>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default JobDescriptionModal;
