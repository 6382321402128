import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

const ShareButton = ({ platform, imageUrl, snapShotHandler }) => {
  const shareButton = React.useRef(null);
  const [url, setUrl] = React.useState(imageUrl);

  const onClick = async () => {
    if (!url) {
      const newUrl = await snapShotHandler();
      setUrl(newUrl);
    }
  };

  React.useEffect(() => {
    if (url) shareButton.current?.click();
  }, [url, shareButton]);

  return platform === 'fb' ? (
    <FacebookShareButton
      ref={shareButton}
      openShareDialogOnClick={url || false}
      url={
        url ||
        'https://skilledscore.com/static/media/logo.0477e9a39e37f113e3b09babbca37718.svg'
      }
      onClick={onClick}
      hashtag={'#SkilledScore #Skills'}
    >
      <FacebookIcon size={30} round={true} />
    </FacebookShareButton>
  ) : (
    <LinkedinShareButton
      ref={shareButton}
      openShareDialogOnClick={url || false}
      url={
        url ||
        'https://skilledscore.com/static/media/logo.0477e9a39e37f113e3b09babbca37718.svg'
      }
      onClick={onClick}
      hashtag={'#SkilledScore #Skills'}
    >
      <LinkedinIcon size={30} round={true} />
    </LinkedinShareButton>
  );
};

export default ShareButton;
