import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Footer from './Footer';

const useStyles = makeStyles(() => ({
  text: {
    paddingTop: '2vw',
  },
  textChange: {
    textTransform: 'uppercase',
  },
  uploadIcon: {
    margin: 'inherit',
  },
  progressContainer: {
    padding: '4vh 0',
  },
  item: {
    margin: '4vh 0',
  },
  progressText: {
    color: '#3479ea',
    fontSize: '1em',
    fontWeight: '900',
  },
  itemStyle: {
    padding: '0.5vh 0',
  },
}));

const SectionsPane = (props) => {
  const classes = useStyles(props);

  return (
    <Grid
      alignItems={'center'}
      justify='center'
      className={classes.mainContainer}
      container
    >
      <Grid xs={12} item>
        <Box className={classes.progressContainer}>
          <Grid className={classes.itemStyle} item>
            <Typography
              variant='subtitle1'
              className={classes.progressText}
            >{`${props.progress.toPrecision(3)}% Complete`}</Typography>
          </Grid>
          <Grid item>
            <LinearProgress variant='determinate' value={props.progress} />
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} item>
        {props.children}
      </Grid>
      <Grid xs={12} className={classes.item} item>
        <Footer onBack={props.onBack} onNext={props.onNext} />
      </Grid>
    </Grid>
  );
};

SectionsPane.propTypes = {
  progress: PropTypes.number,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

export default SectionsPane;
