import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ButtonSecondary from './ButtonSecondary';

const useStyles = makeStyles({
  card: (props) => ({
    maxWidth: props.width ? props.width : 345,
  }),
  title: {
    fontFamily: 'Ubuntu',
    fontSize: '1.3rem',
    fontWeight: '500',
    color: '#505050',
  },
  content: {
    margin: '2vh 1vw',
  },
  cardMedia: (props) => ({
    backgroundColor: '#f8f8fc',
    paddingTop: props.paddingTop ? props.paddingTop : '12%',
  }),
  description: {
    fontFamily: 'Avenir-Roman',
    color: '#9F9F9F',
  },
  confirmButton: {
    textTransform: 'uppercase',
  },
});

const CourseActionDialog = (props) => {
  const classes = useStyles(props);

  return (
    <Dialog
      BackdropProps={{ style: { opacity: '0.5' } }}
      open={props.open}
      onClose={props.onClose}
      title='Add your education'
    >
      <Card className={classes.card}>
        <CardMedia
          component='img'
          alt='Start Course'
          image={props.image}
          title='Start Course'
          className={classes.cardMedia}
        />
        <div className={classes.content}>
          <CardContent>
            <Typography
              className={classes.title}
              gutterBottom
              variant='h5'
              component='h2'
            >
              {props.title}
            </Typography>
            <Typography
              className={classes.description}
              variant='body2'
              color='textSecondary'
              component='p'
            >
              {props.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Grid justify='flex-end' spacing={2} container>
              <Grid item>
                <ButtonSecondary
                  onClick={() => {
                    props.onCancel();
                    props.onClose();
                  }}
                >
                  {props.cancelText ? props.cancelText : 'Cancel'}
                </ButtonSecondary>
              </Grid>
              <Grid item>
                <Button
                  className={classes.confirmButton}
                  variant='contained'
                  onClick={() => {
                    props.onConfirm();
                    props.onClose();
                  }}
                  color='primary'
                >
                  {props.confirmText ? props.confirmText : 'Got it'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </div>
      </Card>
    </Dialog>
  );
};

CourseActionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  width: PropTypes.string,
};
CourseActionDialog.defaultProps = {
  paddingTop: null,
};

export default CourseActionDialog;
