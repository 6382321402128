/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';

import UserJobs from 'src/Api/GetJobs';
import Loader from 'src/Components/Loader';
import OverlayCard from 'src/Components/OverlayCard';
import JobDescriptionModal from 'src/Components/v1/JobDescriptionModal';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

import avatar from '../../../Assets/images/v1/icons/job-icon.svg';
import { Auth as AuthConstants } from '../../../Constants';

const JobElement = ({ onViewDetails, jobs }) => {
  return (
    <div className='border-b-[1px] hover:cursor-pointer border-0 border-gray-200 py-3 flex flex-col sm:flex-row justify-between items-center'>
      <div className='flex gap-2'>
        <img
          className='h-12 rounded-full bg-DarkBluePurple w-12'
          src={jobs?.image || avatar}
          alt='Company Logo'
        />
        <div>
          <h4 className='text-sm md:text-base text-gray-600'>
            {jobs.jobTitle}
          </h4>
          <p className='text-sm text-gray-600'>{jobs?.companyName}</p>
          <h5 className='text-sm text-gray-500'>{jobs?.location}</h5>
        </div>
      </div>
      <button
        onClick={onViewDetails}
        type='button'
        className='py-2 mt-3 sm:mt-1 w-full sm:w-28 whitespace-nowrap px-4 text-sm md:text-base font-semibold text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700  focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'
      >
        Apply Now
      </button>
    </div>
  );
};

const JobSection = () => {
  const {
    userProfile,
    getUserJobs,
    userJobs,
    liveJobLoader,
    isFetchingScore,
    isWalkthroughRunning,
  } = useContext(HomeContext);
  const { user, userPayment } = useContext(AppContext);
  const [showModal, setShowModal] = useState(-1);
  const [description, setDescription] = useState('');
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const toggleModal = (index) => {
    setShowModal(index);
  };

  useEffect(() => {
    if (userProfile && isPremium) {
      getUserJobs('isScrapping');
    }
  }, [userProfile, isPremium]);

  let sortedArray = userJobs?.scraping_data || [];
  if (sortedArray?.length) {
    sortedArray = sortedArray?.sort((a, b) => {
      const dateA = parseISO(a?.jobPosting);
      const dateB = parseISO(b?.jobPosting);
      return dateA > dateB ? -1 : 1;
    });
  }

  const gettingJobDescription = async (link) => {
    await UserJobs.getJobDescription(token, {
      link: link,
    })
      .then((response) => {
        setDescription(response?.data?.data?.description);
      })
      .catch(() => {});
  };

  const renderCard = () => {
    if (liveJobLoader && isPremium) {
      return (
        <div className='flex my-32 justify-center'>
          <Loader />
        </div>
      );
    } else if (!isPremium) {
      return (
        <div className='w-full h-full text-BluePurple flex justify-center items-center my-40 font-bold text-center'>
          <Link to='/home/payment'>Upgrade to premium for viewing jobs.</Link>
        </div>
      );
    } else if (sortedArray?.length > 0) {
      return sortedArray?.slice(0, 5)?.map((job, index) => {
        return (
          <div key={index}>
            <JobElement
              jobs={job}
              description={userJobs?.city}
              onViewDetails={() => {
                toggleModal(index), gettingJobDescription(job.href);
              }}
            />
            <JobDescriptionModal
              {...{
                showModal,
                index,
                setShowModal,
                setDescription,
                description,
                job,
                toggleModal,
              }}
            />
          </div>
        );
      });
    }
    return (
      <div className='text-center text-BluePurple my-32 font-bold'>
        <Link to='/home/profile'>
          Sorry, no jobs found for your profession and location.
          <br /> Please update your profession and check again.
        </Link>
      </div>
    );
  };

  const isEmailVerified = user?.email_verified;

  return (
    <div
      id='recent-jobs'
      className={`bg-white roundedCard flex-1 px-6 pt-5 ${
        isWalkthroughRunning && 'relative'
      }`}
    >
      <h2 className='font-semibold text-base md:text-lg pb-2'>
        Recent Jobs for you
      </h2>
      {isFetchingScore ? null : (
        <div className={`w-full  ${!isWalkthroughRunning && 'relative'}`}>
          <div className='flex justify-between'></div>
          <div
            className={`py-2 ${
              userJobs?.scraping_data?.length
                ? ''
                : 'flex justify-center items-center'
            }`}
          >
            {renderCard()}
          </div>
          {userJobs?.scraping_data?.length ? (
            <div className='flex justify-end'>
              {userJobs?.scraping_data?.length > 5 && isPremium && (
                <p className='cursor-pointer hover:underline mb-2 text-BluePurple text-sm md:text-base font-semibold'>
                  <Link to='/home/jobs'>See all the Jobs</Link>
                </p>
              )}
            </div>
          ) : null}
          {isEmailVerified && !isPremium ? <OverlayCard /> : null}
        </div>
      )}
    </div>
  );
};

export default JobSection;
