import React, { useEffect, useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import { scrollSmoothTo } from 'src/Utils/utils';

const ProfileInformation = ({ user }) => {
  const [skill, setSkill] = useState([]);
  const [experience, setExperience] = useState([]);
  const [education, setEducation] = useState([]);

  const [interests, setInterests] = useState([]);
  const [projects, setProjects] = useState([]);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    setSkill(user?.user_skills);
    setExperience(user?.experience);
    setEducation(user?.education);
    setInterests(user?.user_interests);
    setProjects(user?.projects);
    setCertificates(user?.certificates);
  }, [user]);

  const handleTabClick = (tabId) => {
    scrollSmoothTo(tabId);
  };

  return (
    <div className='md:mr-2 rounded-lg px-4 py-4 bg-white'>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('basic-information')}
      >
        <span>Basic Information</span>
        <AiOutlineCheckCircle className='min-w-[16px] min-h-[16px]' />
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('skills')}
      >
        <span>Skills</span>
        {skill?.length > 0 ? <AiOutlineCheckCircle /> : null}
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('experience')}
      >
        <span> Experience</span>
        {experience?.length > 0 ? <AiOutlineCheckCircle /> : null}
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('experience')}
      >
        <span> Education</span>
        {education?.length > 0 ? <AiOutlineCheckCircle /> : null}
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('interests')}
      >
        <span> Interests</span>
        {interests?.length > 0 ? <AiOutlineCheckCircle /> : null}
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('projects')}
      >
        <span> Projects</span>
        {projects?.length > 0 ? <AiOutlineCheckCircle /> : null}
      </div>
      <div
        className={
          'my-2 flex items-center space-x-2 p-2 font-medium rounded-md cursor-pointer text-gray-500 '
        }
        onClick={() => handleTabClick('certificates')}
      >
        <span> Certificates and Courses</span>
        {certificates?.length > 0 ? (
          <AiOutlineCheckCircle className='min-w-[16px] min-h-[16px]' />
        ) : null}
      </div>
    </div>
  );
};

export default ProfileInformation;
