import React, { useContext, useState } from 'react';

import Loader from 'src/Components/Loader';
import EmptyCard from 'src/Components/v1/EmptyCard';
import JobsCard from 'src/Components/v1/JobsCard';
import SearchInput from 'src/Components/v1/SearchInput';
import { HomeContext } from 'src/Contexts';
import {
  filterJobsByQuery,
  getEmptyCardText,
  sortJobsByDate,
} from 'src/Utils/utils';

const SavedJobs = ({ userJobs, jobDescription }) => {
  const { jobsLoader, userSavedJobs } = useContext(HomeContext);
  const [savedJob, setSavedJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  React.useEffect(() => {
    const jobs =
      userJobs?.scraping_data?.filter((job) =>
        userSavedJobs?.some((savedJob) => savedJob.link === job.href),
      ) || [];
    setSavedJobs(jobs);
  }, [userJobs, userSavedJobs]);

  const filteredJobs = React.useMemo(() => {
    const sortedJobs = sortJobsByDate(savedJob);
    return searchQuery ? filterJobsByQuery(sortedJobs, searchQuery) : savedJob;
  }, [savedJob, searchQuery]);

  const renderContent = () => {
    if (jobsLoader || userJobs === null) {
      return (
        <div className='flex justify-center items-center w-full h-[70vh]'>
          <Loader />
        </div>
      );
    }
    if (filteredJobs?.length) {
      return filteredJobs.map((job, index) => (
        <>
          <div key={index} className='mx-auto h-full w-full'>
            <JobsCard
              jobs={job}
              jobDescription={jobDescription}
              userJobs={userJobs}
              index={index}
            />
          </div>
        </>
      ));
    }
    return (
      <EmptyCard
        text={getEmptyCardText(
          userJobs,
          filteredJobs,
          searchQuery,
          'No Favorite Jobs to show',
        )}
      />
    );
  };

  return (
    <>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search by job title, company or location.'
        label='Search Jobs :'
      />
      <div
        className={`${
          savedJob.length && filteredJobs.length
            ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
            : 'flex flex-wrap'
        } mt-5`}
      >
        {renderContent()}
      </div>
    </>
  );
};

export default SavedJobs;
