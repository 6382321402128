import Api from './index';

const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Stripe = {
  payment: async (token, userData) => {
    const endpoint = '/payment';
    return await Api.post(endpoint, { userData }, getConfig(token));
  },
  cancelPayment: async (token, userData) => {
    const endpoint = '/payment/cancel-payment';
    return await Api.post(endpoint, { userData }, getConfig(token));
  },
  checkPaymentStatus: async (token) => {
    const endpoint = '/payment/check-subscription-status';
    return await Api.get(endpoint, getConfig(token));
  },
};

export default Stripe;
