import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import PrimaryInfoForm from './PrimaryInfoForm';

const PrimaryInfoInputDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} title='Basic Information'>
      <PrimaryInfoForm
        {...props.data}
        onSubmitted={(data) => props.onSubmitted(data)}
      />
    </Dialog>
  );
};

PrimaryInfoInputDialog.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
};

export default PrimaryInfoInputDialog;
