import {
  GufhtuguHorizontal,
  KangarooHorizontal,
  ZindabhagHorizontal,
} from 'src/Assets/images/banner';
import { SanaRasheed, SirZeeshan } from 'src/Assets/images/dashboard';
import Routes from 'src/Utils/Routes';

const PAYMENT_CARD_HEADINGS = [
  'Plan Type',
  'Payment',
  'Amount',
  'Discount',
  'Payment Date',
  'Expiry Date',
  'Action',
];

const COMMON = {
  REQUIRED_ERROR_MSG: 'Required fields cannot be empty',
  PASSWORD_VALIDATION:
    'Must contain 8 characters, at-least one uppercase, one lowercase and one number.',
  PASSWORD_MAX_LENGTH: 'Password must not exceed 20 characters.',
  PAYMENT_TEXT:
    'Elevate your SkilledScore experience with our enhanced plan for expanded opportunities.',
  PAYMENT_HISTORY: 'View your complete payment history here.',
  SKILL_SCORE_TEXT_1: `Skilled Score is a comprehensive metric that provides valuable
  insights into your career prospects. This score helps you to
  understand your strengths and weaknesses, providing you with the
  necessary information to make informed decisions regarding your career
  path, training, and skill development.`,
  SKILL_SCORE_TEXT_2: `The Skilled Score is a powerful tool that can assist you in planning
  your career and self-assessment. It empowers you to thrive in the
  current job market and prepare you for future opportunities.`,
  DUMMY_TEXT: 'This is an example that you are viewing',
  PREMIUM_TEXT: 'Please upgrade to premium to view your score.',
  NO_REFUND: 'Disclaimer: Non-Refundable Subscription Fee.',
  CHOOSE_PRICING_PLAN: 'Choose your pricing plan',
  OUR_TEAM: 'Our Team',
  BLOGS: 'Blogs',
  PRICING_PLAN_TEXT:
    'Explore our flexible payment plans to find the option that best fits your needs. Achieve your goals with ease as you choose a plan that aligns with your budget and timeline. Discover the possibilities and start your journey with SkilledScore today!',
};

const POLICIES = [
  { name: 'Privacy Policy', path: Routes.PrivacyPolicy },
  { name: 'Terms and Conditions', path: Routes.TermsAndCondition },
  { name: 'Cookie Policy', path: Routes.CookiesPolicy },
];

const HOME = [
  { name: 'Benefits', id: 'benefits', type: 'scroll' },
  { name: 'How to Use?', id: 'how-to-use', type: 'scroll' },
  { name: 'Pricing', id: 'pricing-plan', type: 'link' },
];

const PAYMENT_STATUS = {
  PAID: 'paid',
  NOT_PAID: 'not paid',
};
const ILO_CHART_WIDTH_MAPPER = {
  1: 16,
  2: 25,
  3: 36,
  4: 49,
  5: 62,
  6: 75,
  7: 87,
  8: 100,
  9: 111,
};

const PREMIUM_PLAN = [
  {
    title: 'Monthly Plan',
    amount: '20',
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_KEY,
    duration: '1 Month',
    validity: 30,
  },
  {
    title: 'Quarterly Plan',
    amount: '50',
    priceId: process.env.REACT_APP_STRIPE_QUARTERLY_PRICE_KEY,
    duration: '3 Months',
    validity: 90,
  },
  {
    title: 'One Time Payment',
    amount: '20',
    amountPKR: '3000',
    priceId: process.env.REACT_APP_STRIPE_ONE_TIME_PRICE_KEY,
    duration: '3 Months',
    validity: 90,
  },
  {
    title: 'Yearly Plan',
    amount: '150',
    priceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_KEY,
    duration: '1 Year',
    validity: 365,
  },
];

const PLAN_INFO = [
  { description: 'View profile information.' },
  { description: 'Edit profile information.' },
  {
    description: 'Assessment score calculation.',
  },
  {
    description: 'Recommended jobs.',
  },
  {
    description: 'Recommended courses.',
  },
  {
    description: 'Recommended surveys.',
  },
  {
    description: 'Upload resume upto 5 MB.',
  },
];

const PAYMENT_METHODS = {
  STRIPE: 'stripe',
  EASY_PAISA: 'easyPaisa',
};
const BANNER_LINK = [
  'https://gufhtugu.com/',
  'https://zindabhag.com/',
  'https://www.kangaroo.ventures',
];

const USER_PROFILE = {
  NO_SUMMARY: 'No summary has been added yet',
  NO_SKILLS: 'No skills have been added yet',
  NO_EXPERIENCE: 'No experiences have been added yet',
  NO_EDUCATION: 'No educations have been added yet',
  NO_INTEREST: 'No interests have been added yet',
  NO_PROJECT: 'No projects have been added yet',
  NO_CERTIFICATE: 'No certificates have been added yet',
};
const EASY_PAISA_STATUS = {
  SUCCESS: 'SUCCESS',
  ACCOUNT_DOES_NOT_EXIST: 'ACCOUNT DOES NOT EXIST',
  SYSTEM_ERROR:
    'You either have Unregistered account or incorrect PIN or have not approved transaction from EasyPaisa',
};

const EASY_PAISA_APPROVAL_POINTS = [
  'Ensure that in-app notifications for the EasyPaisa app are enabled.',
  'Login to your EasyPaisa app. Tap on the top-left menu My Account.',
  'To approve the payment under My Approvals. Ensure your EasyPaisa account is Active.',
];

const MESSAGES = [
  'Processing Your Resume...',
  "We're Analyzing Your Skills...",
  'Data Extraction in Progress...',
  'Preparing Your Profile...',
];
const FILE_SIZES = {
  PREMIUM_USER: 5,
  FREE_USER: 2,
};

const BANNER_IMAGES = [
  GufhtuguHorizontal,
  ZindabhagHorizontal,
  KangarooHorizontal,
];

const SKILLED_SCORE_VIDEOS = [
  {
    url: '/1D5mSyL4RjE?si=R-yrB1lqE8COC7UU',
    title: 'SkilledScore Demo (English)',
  },
  {
    url: '/042g0Xxu038?si=myCX-LBwsFiYa7Eo',
    title: 'SkilledScore Demo (Urdu)',
  },
];

const HOW_IT_WORK = [
  {
    HEADING: 'How Does It Work?',
    PARA: "SkilledScore is more than just a piece of software; it's your road map to realizing your greatest potential in the workplace. By utilizing the International Labour Organization's (ILO) extensive information base and data analytics, SkilledScore enables people to make well-informed career selections based on their specific skill sets, objectives, and geographical location.",
  },
  {
    HEADING: 'Precision Scoring From 0 to 100',
    PARA: "With a score between 0 and 100, SkilledScore's scoring system gives users a brief and straightforward rating for their abilities. Using this score as a reference, people may accurately assess their areas of strength and growth. SkilledScore provides insightful information to help you navigate your career path, regardless of your background—you might be a recent graduate looking for entry-level positions or an experienced professional trying to switch to a different industry.",
  },
  {
    HEADING: 'Job Recommendation with SkilledScore',
    PARA: "However, SkilledScore is more than just a skills assessment test; it's also a certified career counselor for advancing your education and navigating the job market. SkilledScore uses a large database and strong algorithms to provide recommendations that are specific to your skill level. SkilledScore gives you everything that you need to succeed in today's competitive environment, whether it's by proposing relevant job vacancies in your area or courses and certifications to expand your skill set.",
  },
];

const TEAM_MEMBERS = [
  {
    name: 'Zeeshan Usmani',
    role: 'CTO/Co-founder',
    description:
      "With 10+ years in data science and marketing analytics, I lead Walee's team in advanced analytics, strategic partnerships, media buying, consumer profiling, and influencer management, driving innovative solutions in social and headless commerce.",
    image: SirZeeshan,
    linkedin: 'https://www.linkedin.com/in/zusmani/',
  },
  {
    name: 'Sana Rasheed',
    role: 'CTO/Co-founder',
    description:
      "I provide value through data science (R, Python), predictive analytics (ML, NLTK, SciKit), visualization (Pentaho, Tableau, QlikView, D3), and deep learning. I'm also versed in blockchain and NFTs (Ethereum, Smart Contracts, Bitcoin) for finance, travel, and supply chain.",
    image: SanaRasheed,
    linkedin: 'https://www.linkedin.com/in/sanarasheed/',
  },
];

export {
  BANNER_IMAGES,
  BANNER_LINK,
  COMMON,
  EASY_PAISA_APPROVAL_POINTS,
  EASY_PAISA_STATUS,
  FILE_SIZES,
  HOME,
  HOW_IT_WORK,
  ILO_CHART_WIDTH_MAPPER,
  MESSAGES,
  PAYMENT_CARD_HEADINGS,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  PLAN_INFO,
  POLICIES,
  PREMIUM_PLAN,
  SKILLED_SCORE_VIDEOS,
  TEAM_MEMBERS,
  USER_PROFILE,
};
