import React from 'react';
import { withRouter } from 'react-router-dom';

import TabbedContainer from '../../../Components/TabbedContainer';

import Projects from './projects';
import Test from './Test';

const Assessments = () => {
  return (
    <TabbedContainer
      tabs={[
        {
          name: 'Test',
          content: <Test />,
        },
        {
          name: 'Projects',
          content: <Projects />,
        },
      ]}
    ></TabbedContainer>
  );
};

export default withRouter(Assessments);
