import React from 'react';

import CreateTwoToneIcon from '@material-ui/icons/CreateTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';

import { SHORT_MONTHS } from 'src/Constants/Months';

import IconButton from '../IconButton';
import Loader from '../Loader';

import Avatar from './Avatar';

const DesignationCard = ({
  title,
  field,
  grade,
  description,
  location,
  image,
  loader,
  isDeleting,
  startYear,
  endYear,
  startMonth,
  endMonth,
  onDelete,
  onEdit,
}) => {
  const generateContent = (title, text1, text2) => {
    let str = title;
    if (text1) str += ` - ${text1}`;
    if (text2) str += `, ${text2}`;
    return str;
  };

  const generateLocationContent = (title, text1) => {
    let str = title;
    if (text1) str += `, ${text1}`;
    return str;
  };

  return (
    <div className='border-b-[1px] hover:cursor-pointer border-0 border-gray-200 py-3 flex justify-between items-center'>
      <div className='flex gap-2'>
        <Avatar image={image} text={title || description} />
        <div>
          <h4 className='text-sm md:text-base ml-1 font-semibold'>
            {generateContent(title, field)}
          </h4>
          <h4 className='text-sm md:text-base ml-1  text-gray-500'>
            {generateLocationContent(description, location)}
          </h4>
          {grade ? (
            <h4 className='text-sm md:text-base ml-1  text-gray-500'>
              Grade: {grade}
            </h4>
          ) : null}

          {startYear ? (
            <div className=' text-sm md:text-base text-gray-400 mx-1 mt-2'>
              {startMonth ? SHORT_MONTHS[Number(startMonth)] : null} {startYear}
              -{' '}
              {endYear ? (
                <span>
                  {endMonth ? SHORT_MONTHS[Number(endMonth)] : null} {endYear}
                </span>
              ) : (
                'On Going'
              )}
            </div>
          ) : null}
        </div>
      </div>

      {loader && isDeleting ? (
        <Loader className='!h-5 mt-4' />
      ) : (
        <div>
          <IconButton onClick={onEdit}>
            <CreateTwoToneIcon
              color='inherit'
              className=' mb-1'
              fontSize='small'
            />
          </IconButton>

          <IconButton onClick={onDelete}>
            <DeleteIcon className='-mt-1' color='inherit' fontSize='small' />
          </IconButton>
        </div>
      )}
    </div>
  );
};
export default DesignationCard;
