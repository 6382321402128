import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({}));

const ProfileProgressIndicator = (props) => {
  const classes = useStyles(props);

  return (
    <Grid spacing={1} component={Box} direction='column' container>
      <Grid item>
        <Grid
          spacing={8}
          component={Box}
          className={classes.content}
          direction='row'
          justify='space-between'
          container
        >
          <Grid item>
            <Typography variant='subtitle1'>Profile</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>{props.percentage}%</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid component={Box} item>
        <LinearProgress variant='determinate' value={props.percentage} />
      </Grid>
    </Grid>
  );
};

ProfileProgressIndicator.propTypes = {
  percentage: PropTypes.number,
};

ProfileProgressIndicator.defaultProps = {
  percentage: 0,
};

export default ProfileProgressIndicator;
