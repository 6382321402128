import React from 'react';
import { format } from 'date-fns';

import { Grid } from '@material-ui/core';

const ResumeMessage = ({ resumeEligibility }) => {
  const formattedEndDate = format(
    new Date(resumeEligibility?.endDate || new Date()),
    'dd MMM yyyy',
  );

  return (
    <Grid className='lg:ml-80'>
      <p className='bg-lighterBlue text-PurpleBlack font-medium mt-3 p-3 rounded-md mx-4 text-center'>
        <span className='font-bold ml-1'>Note : </span>
        You have uploaded{' '}
        <span className='font-bold'>
          {resumeEligibility?.resumeUploaded}
        </span>{' '}
        out of{' '}
        <span className='font-bold'>{resumeEligibility?.resumeLimit}</span>{' '}
        resumes. Once you hit this limit, the {' "Upload Resume" '} button will
        be temporarily disabled for two weeks until
        <span className='font-bold'> {formattedEndDate}</span>.<br /> Afterward,
        you can resume uploading.
      </p>
    </Grid>
  );
};

export default ResumeMessage;
