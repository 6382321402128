import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { COMMON } from 'src/Constants';

import { AppContext } from '../Contexts';

import DegreeDropdown from './OnBoarding/DegreeDropdown';
import StudyFieldsDropdown from './OnBoarding/StudyFieldsDropdown';
import YearSelector from './OnBoarding/YearSelector';
import Button from './Button';

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
  },
  pickerText: {
    '& .MuiInputBase-input': {
      color: '#BCBCBC',
    },
  },
  pickerTextSelected: {
    '& .MuiInputBase-input': {
      color: '#000000',
    },
  },
}));

const EducationForm = (props) => {
  const classes = useStyles();

  const context = useContext(AppContext);
  const [degree, setDegree] = useState(props.degree);
  const [isCurrent, setCurrent] = useState(props.isCurrent);
  const [fieldOfStudy, setFieldOfStudy] = useState(props.fieldOfStudy);
  const [startYear, setStartYear] = useState(
    props.startUTC !== null ? props.startUTC : props.startYear,
  );
  const [endYear, setEndYear] = useState(
    props.endUTC !== null ? props.endUTC : props.endYear,
  );
  const [grade, setGrade] = useState(props.grade);
  const [location, setLocation] = useState(props.locationText);
  const [institute, setInstitute] = useState(props.institute);

  const onClick = async () => {
    if (
      degree !== '' &&
      institute !== '' &&
      fieldOfStudy !== '' &&
      startYear !== null &&
      (endYear !== null || isCurrent) &&
      // grade &&
      location !== ''
    ) {
      await props.onSubmitted({
        id: props.id,
        degree,
        institute,
        fieldOfStudy,
        startYear: new Date(startYear).getFullYear(),
        endYear: endYear ? new Date(endYear).getFullYear() : null,
        startUTC: new Date(startYear).toUTCString(),
        endUTC: endYear ? new Date(endYear).toUTCString() : null,
        grade,
        location,
      });
    } else {
      context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormGroup>
          <Grid spacing={2} container>
            <Grid xs={12} sm={6} item>
              <DegreeDropdown
                onChange={(degree) => setDegree(degree)}
                value={degree}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <StudyFieldsDropdown
                value={fieldOfStudy}
                onChange={(field) => setFieldOfStudy(field)}
                degree_id={degree ? degree.id : null}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                variant='outlined'
                label='School, Institute, college, university*'
                fullWidth={true}
                value={institute}
                onChange={(event) => setInstitute(event.target.value)}
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearSelector
                value={startYear ? startYear.toString() : null}
                placeholder='Start year*'
                onChange={(value) => {
                  setStartYear(value);
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearSelector
                value={endYear ? endYear.toString() : null}
                minDate={new Date(startYear)}
                disabled={isCurrent}
                placeholder='End year*'
                onChange={(value) => {
                  setEndYear(value);
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                variant='outlined'
                label='Grade'
                fullWidth={true}
                value={grade}
                onChange={(event) => setGrade(event.target.value)}
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                variant='outlined'
                label='Location'
                fullWidth={true}
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={isCurrent}
                    onChange={() => {
                      setCurrent(!isCurrent);
                      setEndYear(null);
                    }}
                    value={'current'}
                  />
                }
                label='Currently studying here'
              />
            </Grid>
            <Grid xs={12} direction='row' justify='flex-end' item container>
              <Button
                onClick={onClick}
                title={props.id === -1 ? 'Save' : 'Update'}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </MuiPickersUtilsProvider>
    </div>
  );
};

EducationForm.propTypes = {
  id: PropTypes.number,
  fieldOfStudy: PropTypes.object,
  grade: PropTypes.string,
  institute: PropTypes.string,
  isCurrent: PropTypes.bool,
  degree: PropTypes.object,
  startYear: PropTypes.string,
  startUTC: PropTypes.any,
  endYear: PropTypes.string,
  endUTC: PropTypes.any,
  locationText: PropTypes.string,
  onSubmitted: PropTypes.any,
};

EducationForm.defaultProps = {
  id: -1,
  isCurrent: false,
  fieldOfStudy: '',
  degree: '',
  grade: '',
  institute: '',
  startYear: null,
  startUTC: null,
  endYear: null,
  endUTC: null,
  locationText: '',
  onSubmitted: () => {},
};

export default withRouter(EducationForm);
