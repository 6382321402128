import React from 'react';

import Button from '../Button';
import Dialog from '../Dialog';

const ProfessionalModal = ({ modalOpen, setModalOpen, setOpenPrimaryInfo }) => {
  return (
    <Dialog
      title='Add Profession'
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <div>
        <h1>Please add your profession</h1>
        <div className='flex justify-end'>
          <Button
            onClick={() => {
              setModalOpen(false);
              setOpenPrimaryInfo(true);
            }}
            title='Add Profession'
          />
        </div>
      </div>
    </Dialog>
  );
};

export default ProfessionalModal;
