import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  saveButton: {
    background: '#828282',
    color: '#fff',
    textTransform: 'uppercase',
  },
}));

const ButtonSecondary = (props) => {
  const classes = useStyles();

  return (
    <Button
      style={props.style}
      onClick={props.onClick}
      variant={'contained'}
      size={props.size}
      className={classes.saveButton}
    >
      {props.children}
    </Button>
  );
};

ButtonSecondary.propTypes = {
  size: PropTypes.any,
  style: PropTypes.any,
  onClick: PropTypes.func,
};

ButtonSecondary.defaultProps = {
  size: 'medium',
};

export default ButtonSecondary;
