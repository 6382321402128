import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

import {
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { Notifications } from '../../../Api';
import NotificationItem from '../../../Components/NotificationItem';
import Card from '../../../Components/Profile/Card';
import { Auth as AuthConstants } from '../../../Constants';

const useStyles = makeStyles(() => ({
  cardContainer: {
    marginTop: '3vw',
  },
  card: {
    padding: '4vh 0',
  },
  header: {
    fontSize: '2rem',
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    color: '#505050',
    margin: '2vh 3vw',
  },
  divider: {
    marginTop: '4vh',
  },
  notifContainer: {
    paddingTop: '4vh',
  },
  notification: {
    margin: '0 3vw',
  },
}));

const Index = (props) => {
  const classes = useStyles();

  const [notifications, setNotifications] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [beg, setBeg] = useState(0);
  const [size] = useState(10);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const mapResponseToView = (notification) => ({
    ...notification,
    id: notification.id,
    title: notification.payload.title,
    description: notification.payload.description,
    date: format(new Date(notification.created_at), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    Notifications.list(token, { beg, size }).then(
      (res) => {
        setNotifications(
          res.data.data.notifications.map((item) => {
            return mapResponseToView(item);
          }),
        );
        setBeg(beg + 1);
      },
      () => {
        // console.log('Notifications.list:', err);
      },
    );
  }, []);

  const loadMore = () => {
    Notifications.list(token, { beg: beg + 1, size }).then(
      (res) => {
        if (res.data.data.notifications.length === 0) {
          setShowLoadMore(false);
          return;
        }
        setNotifications([
          ...notifications,
          ...res.data.data.notifications.map((item) => mapResponseToView(item)),
        ]);
        setBeg(beg + 1);
      },
      () => {
        // console.log('Notifications.list:', err);
      },
    );
  };

  return (
    <Grid container>
      <Grid xs={1} md={3} item />
      <Grid xs={10} md={6} className={classes.cardContainer} item>
        <Grid xs={12} component={Card} variant={'contained'} container>
          <Grid xs={12} className={classes.card} container>
            <Grid xs={12} item>
              <Typography className={classes.header} variant='h4'>
                Notifications
              </Typography>
            </Grid>
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <Grid
                  xs={12}
                  key={index}
                  className={classes.notifContainer}
                  component={ButtonBase}
                  direction='row'
                  container
                >
                  <Grid xs={12} className={classes.notification} item>
                    <NotificationItem
                      onAction={(id) =>
                        props.history.push(`/home/notifications/${id}`, {
                          notification,
                        })
                      }
                      id={notification.id}
                      title={notification.title}
                      description={notification.description}
                      dateTime={notification.date}
                      type={notification.type}
                    />
                  </Grid>
                  <Grid className={classes.divider} xs={12} item>
                    {index < notifications.length - 1 ? <Divider /> : <></>}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid alignItems='center' justify='center' container>
                <Typography variant='subtitle1'>
                  No notifications at the moment!
                </Typography>
              </Grid>
            )}
          </Grid>
          {
            // eslint-disable-next-line no-nested-ternary
            notifications === null ? null : showLoadMore > 0 ? (
              <Grid item>
                <Button
                  className={classes.loadMore}
                  onClick={() => {
                    setLoading(true);
                    loadMore();
                  }}
                  color='primary'
                  variant='outlined'
                >
                  {isLoading ? (
                    <CircularProgress size={20} color='primary' />
                  ) : (
                    'Load More'
                  )}
                </Button>
              </Grid>
            ) : null
          }
        </Grid>
      </Grid>
      <Grid xs={1} md={3} />
    </Grid>
  );
};

Index.propTypes = {
  onSubmitted: PropTypes.func,
};

export default withRouter(Index);
