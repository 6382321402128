import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

import TabbedContainer from '../../Components/TabbedContainer';

import RecommendedCourses from './RecommendedCourses';
import RecommendedPathways from './RecommendedPathways';
import SavedCourses from './SavedCourses';

const Upskills = () => {
  const { user, userPayment } = useContext(AppContext);
  const {
    getSavedCourses,
    userProfile,
    getUserPathways,
    getUserCourses,
    userCourses,
    userSavedCourses,
  } = useContext(HomeContext);
  const verifiedEmail = user?.email_verified;
  const history = useHistory();
  const paymentHistory = userPayment?.payment_status;

  useEffect(() => {
    getSavedCourses();
  }, [userProfile]);

  useEffect(() => {
    getUserPathways();
  }, []);

  useEffect(() => {
    if (!verifiedEmail || paymentHistory === PAYMENT_STATUS.NOT_PAID) {
      return history.push('/home');
    }
  }, [verifiedEmail, paymentHistory]);

  useEffect(() => {
    if (userProfile) {
      getUserCourses();
    }
  }, [userProfile]);

  const [upskillText, setUpskillText] = useState(
    'Our AI recommended these valuable courses for your career upgrades',
  );

  return (
    <div className='lg:ml-80'>
      <h1 className='text-3xl font-bold my-9 lg:mx-10 mx-3'>UpSkill</h1>
      <h2 className='text-xl font-semibold text-SSBlack lg:mx-10 mx-3'>
        {upskillText}
      </h2>
      <div className='mx-2 lg:mx-8'>
        <TabbedContainer
          tabs={[
            {
              name: 'Recommended Courses',
              content: (
                <RecommendedCourses
                  userCourses={userCourses}
                  savedCourses={userSavedCourses}
                />
              ),
            },
            {
              name: 'Recommended Pathways',
              content: <RecommendedPathways />,
            },
            {
              name: 'Favourite Courses',
              content: <SavedCourses userCourses={userSavedCourses} />,
            },
          ]}
          setState={setUpskillText}
          name='upskill'
        />
      </div>
    </div>
  );
};

export default withRouter(Upskills);
