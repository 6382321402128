import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: 'none',
  },
  wrapper: {
    'flexDirection': 'row',
    'justifyContent': 'flex-start',
    'alignItems': 'center',
    'fontFamily': 'Avenir-Roman',
    'fontSize': '1.2vw',
    '& > *': {
      margin: '0 1vw',
    },
  },
  indicator: {
    left: 0,
    width: '4px',
  },
  completedIcon: {
    color: '#3277e9',
  },
  progressIcon: {
    color: '#db7171',
  },
  selected: {
    '&$disabled': {
      color: 'none',
    },
  },
}));

const VerticalTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { items, ...restProps } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs
      indicatorColor='primary'
      textColor='primary'
      orientation='vertical'
      variant='fullWidth'
      value={value}
      onChange={handleChange}
      aria-label='Profile Navigation'
      className={classes.root}
      classes={{ indicator: classes.indicator }}
      {...restProps}
    >
      {items.map((tab, index) => (
        <Tab
          classes={{
            wrapper: classes.wrapper,
            textColorPrimary: classes.selected,
          }}
          key={index}
          style={{ borderBottom: '1px solid rgba(192, 192, 192, 0.25)' }}
          label={tab.name}
          icon={
            tab.isCompleted ? (
              <CheckCircleOutlinedIcon className={classes.completedIcon} />
            ) : (
              <ArrowForwardIcon className={classes.progressIcon} />
            )
          }
        />
      ))}
    </Tabs>
  );
};

VerticalTabs.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isCompleted: PropTypes.bool,
    }),
  ),
};

VerticalTabs.defaultProps = {
  value: 0,
};

export default VerticalTabs;
