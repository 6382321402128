import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { User } from 'src/Api';
import { HomeContext } from 'src/Contexts';

import ProfileImageUpload from './v1/ProfileImageUpload';
import Dialog from './Dialog';

const PrimaryImageDialog = ({ user, open, onClose, getProfile }) => {
  const [image, setImage] = useState(user.image_url);
  const [isDeleted, setDeleted] = useState(false);
  const { token } = useContext(HomeContext);

  useEffect(() => {
    setDeleted(false);
    setImage(user.image_url);
  }, [user]);

  const handleProfileUpdate = async () => {
    if (image === null && !isDeleted) {
      return onClose(false);
    }
    const payload = {
      image,
      isDeleted,
    };
    try {
      await User.updateProfileImage(payload, token);
      getProfile();
      setDeleted(false);
    } catch (e) {
      console.error('Failed:', e);
    } finally {
      onClose();
    }
  };

  const changeProfileHeading = user.image_url
    ? 'Update Profile Image'
    : 'Add Profile Image';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={changeProfileHeading}
      bottonButtonStyle='!py-2'
      titleStyle='text-xl md:text-2xl'
    >
      <ProfileImageUpload
        image={image}
        onImageUploaded={(image) => setImage(image)}
        setDeleted={setDeleted}
        setImage={setImage}
        handleProfileUpdate={handleProfileUpdate}
      />
    </Dialog>
  );
};

PrimaryImageDialog.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
};

export default PrimaryImageDialog;
