/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment';

import FavoriteIcon from '@material-ui/icons/Favorite';

import UserCourses from 'src/Api/GetCourses';
import { HomeContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { getLocalUser } from 'src/Utils/utils';

import arrow from '../../Assets/images/v1/icons/arrow-narrow-right.svg';
import avatar from '../../Assets/images/v1/icons/courses.webp';
import { Auth as AuthConstants } from '../../Constants';
import LoaderButton from '../LoaderButton';

import Modal from './Modal/Modal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UpskillCard = ({
  upskill,
  saved,
  // _index,
  savedCourses,
  isNotSaved,
  className,
}) => {
  const [showModal, setShowModal] = useState(false);
  const homeContext = useContext(HomeContext);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const savedCourseHandler = async (url) => {
    const parsedData = await getLocalUser();

    UserCourses.save_course(token, {
      link: url,
      userId: parsedData.user.id,
    })
      .then(() => {
        // homeContext.getUserCourses();
        homeContext.getSavedCourses();
      })
      .catch(() => {});
  };

  const handleImageError = (event) => {
    event.target.src = avatar;
  };

  const isSaved = savedCourses?.some(
    (savedCourse) => savedCourse?.url === upskill.url,
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const modifiedImageUrl = upskill?.image_url
    .replace('https://ccweb.imgix.net/', '')
    .replace(/%3A/g, ':')
    .replace(/%2F/g, '/')
    .replace(/&blur=200.*$/, '');

  return (
    <div
      className={`border font-[karla-regular] h-full bg-white p-3 md:p-0 roundedCard ${className}`}
    >
      <img
        className='bg-DarkBluePurple w-full h-56 roundedCard'
        src={modifiedImageUrl ?? avatar}
        onError={handleImageError}
      />
      <div className='px-2 h-24'>
        <h1 className='my-4 overflow-hidden text-lg w-full truncate overflow-ellipsis text-gray-900 font-bold'>
          {upskill?.title}
        </h1>
        {upskill?.author ? (
          <p className=' w-full truncate overflow-ellipsis'>
            Author: <span className='font-bold'>{upskill?.author}</span>
          </p>
        ) : null}
        {upskill?.course_source ? (
          <p className=' w-full truncate overflow-ellipsis'>
            Source:{' '}
            <span className='font-bold capitalize'>
              {upskill?.course_source}
            </span>
          </p>
        ) : null}
      </div>
      <hr className='my-2' />
      <div className='flex flex-row justify-end bg-re h-12 my-2 items-center'>
        {saved ? (
          <div>
            <FavoriteIcon
              onClick={() => {
                savedCourseHandler(upskill?.url);
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              }}
              className={`mx-3 cursor-pointer -mt-4 ${
                isSaved || saved ? 'text-DarkBluePurple' : 'text-gray-400'
              }`}
              fontSize={'large'}
            />
            <button className='mx-3 md:px-5 px-2 border-2 rounded-lg py-2 '>
              <div className='flex'>
                <img className='mr-2' src={arrow} alt='' />
                <a href={upskill?.url} target='_blank' rel='noreferrer'>
                  Take Course
                </a>
              </div>
            </button>
          </div>
        ) : (
          <div className='flex md:ml-32'>
            {isNotSaved ? null : (
              <button
                onClick={() => {
                  savedCourseHandler(upskill?.url);
                  setLoading(true);
                  setTimeout(() => {
                    setLoading(false);
                  }, 1000);
                }}
                className={`font-semibold mt-2 text-gray-600 cursor-pointer ${
                  saved ? 'text-gray-300' : ''
                }`}
                disabled={loading}
              >
                {loading ? (
                  <LoaderButton />
                ) : (
                  <FavoriteIcon
                    className={`mx-3 -mt-2 cursor-pointer ${
                      isSaved ? 'text-DarkBluePurple' : 'text-gray-400'
                    }`}
                    fontSize={'large'}
                  />
                )}
              </button>
            )}

            <button
              className='mx-3 md:px-5 px-2 border-2 rounded-lg py-1 md:py-2'
              onClick={() => FirebaseAnalyticsEvent('take_course')}
            >
              <div className='flex'>
                <img className='mr-2' src={arrow} alt='' />
                <a href={upskill?.url} target='_blank' rel='noreferrer'>
                  Take Course
                </a>
              </div>
            </button>
          </div>
        )}
      </div>
      <Modal isOpen={showModal} onClose={toggleModal}>
        <div className='w-fit font-[karla-regular] rounded-lg bg-white mx-5 my-5'>
          <div className='flex justify-between'>
            <img
              className='bg-DarkBluePurple h-56 rounded-md'
              src={modifiedImageUrl ?? avatar}
            />
            <button
              onClick={toggleModal}
              className='rounded-2xl border h-7 w-20 border-red-600 text-red-600'
            >
              Close
            </button>
          </div>
          <div>
            <h1 className='my-2 overflow-hidden text-xl text-gray-900 font-bold w-full md:w-80'>
              {upskill?.title}
            </h1>
            <div className='flex'>
              <p className='my-2 mr-96 text-lighterGrey'>
                Posted {moment(upskill?.postedDate).startOf('day').fromNow()}
              </p>
              <p className='ml-48'></p>
            </div>
            <p className='text-justify h-96 overflow-hidden'>
              {upskill?.shortDescription}
            </p>
          </div>
          <hr className='my-5' />
          <div className='flex px-6 justify-end'>
            <button
              className='px-2 border-2 bg-DarkBluePurple text-white rounded-lg h-10'
              onClick={() => FirebaseAnalyticsEvent('take_course')}
            >
              <a target='_blank' href={upskill?.url} rel='noreferrer'>
                Take Course
              </a>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UpskillCard;
