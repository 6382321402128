import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    'width': '100%',
    'height': '100%',
    'display': 'grid',
    'gridTemplateColumns': 'repeat(2, 1fr)',
    'gridTemplateRows': '1fr',
    '&:hover $editContainer': {
      display: 'flex',
    },
  },
  hoverView: {
    gridArea: '1 / 2 / 2 / 3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  contentContainer: {
    gridArea: '1 / 1 / 2 / 3',
    zIndex: 1,
  },
  editContainer: (props) => ({
    display: props.isSticky ? 'flex' : 'none',
    padding: '0.5vh',
    margin: '1vh',
    borderRadius: '50%',
    zIndex: 3,
    background: '#20254B',
    justifyContent: 'center',
    alignItems: 'center',
  }),
}));

const HoverEditPane = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <div className={classes.hoverView}>
        {props.onAction ? (
          <ButtonBase
            onClick={props.onAction}
            className={classes.editContainer}
          >
            {props.icon}
          </ButtonBase>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.contentContainer}>{props.children}</div>
    </div>
  );
};

HoverEditPane.propTypes = {
  icon: PropTypes.any.isRequired,
  onAction: PropTypes.func,
  editIcon: PropTypes.any,
  onEditAction: PropTypes.func,
  isSticky: PropTypes.bool.isRequired,
};

HoverEditPane.defaultProps = {
  isSticky: true,
};

export default HoverEditPane;
