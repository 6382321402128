import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HoverEditPane from '../HoverEditPane';

import Card from './Card';
import EmptyPlaceholder from './EmptyPlaceholder';
import Title from './Title';

const useStyles = makeStyles(() => ({
  container: (props) => ({
    padding: props.noPadding ? null : '1.5rem 1.5rem',
    color: '#505050',
  }),
  contentContainer: (props) => ({
    marginTop: props.noPadding ? null : '1.5rem',
  }),
}));

const BasicPane = (props) => {
  const classes = useStyles(props);

  return (
    <HoverEditPane
      onAction={props.onAction}
      icon={props.icon}
      editIcon={props.editIcon}
      onEditAction={props.onEditAction}
    >
      <Grid component={Card}>
        <Grid className={classes.container} item>
          <Grid component={Box} direction='column' container>
            <Grid item>
              <Title>{props.title}</Title>
            </Grid>
            <Grid className={classes.contentContainer} item>
              {props.children ? (
                props.children
              ) : (
                <EmptyPlaceholder
                  image={props.placeholderImage}
                  text={props.placeholderText}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HoverEditPane>
  );
};

BasicPane.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  onAction: PropTypes.func,
  editIcon: PropTypes.any,
  onEditAction: PropTypes.func,
  placeholderText: PropTypes.string,
  placeholderImage: PropTypes.string,
  noPadding: PropTypes.bool,
};

BasicPane.defaultProps = {
  noPadding: false,
};

export default BasicPane;
