import { placeholderImage } from 'src/Assets';

const EmptyCard = ({ text }) => (
  <div className='my-20 w-full flex justify-center'>
    <div>
      <img className='mx-20' src={placeholderImage} alt='Placeholder' />
      <h1 className='text-xl text-center mt-5 font-semibold'>{text}</h1>
    </div>
  </div>
);

export default EmptyCard;
