import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ProfessionalModal from 'src/Components/ProfessionalModal';
import { USER_PROFILE } from 'src/Constants/Common';

import { User } from '../../../Api';
import certificatesPlaceholder from '../../../Assets/images/profile/certificates-placeholder.svg';
import educationPlaceholder from '../../../Assets/images/profile/education-placeholder.svg';
import experiencePlaceholder from '../../../Assets/images/profile/experience-placeholder.svg';
import interestsPlaceholder from '../../../Assets/images/profile/interests-placeholder.svg';
import skillsPlaceholder from '../../../Assets/images/profile/skills-placeholder.svg';
import InterestInputDialog from '../../../Components/InterestInputDialog';
import Loader from '../../../Components/Loader';
import PrimaryInfoInputDialog from '../../../Components/PrimaryInfoInputDialog';
import ChipsPane from '../../../Components/Profile/ChipsPane';
import ListPane from '../../../Components/Profile/ListPane';
import PrimaryInfo from '../../../Components/Profile/PrimaryInfo';
import ProjectsPane from '../../../Components/Profile/ProjectsPane';
import SkillsInputForm from '../../../Components/Profile/SkillsInputForm';
import UploadCVPane from '../../../Components/Profile/UploadCVPane';
import ProfileProgressIndicator from '../../../Components/ProfileProgressIndicator';
import SkillsInputDialog from '../../../Components/SkillsInputDialog';
import VerticalTabs from '../../../Components/VerticalTabs';
import { Auth as AuthConstants } from '../../../Constants';
import { AppContext } from '../../../Contexts';

import {
  mapEducation,
  mapExperience,
  mapProjects,
} from './../../../Utils/utils';

const useStyles = makeStyles(() => ({
  section: {
    paddingBottom: '1.5rem',
  },
  navigator: {
    paddingLeft: '1.5rem',
    paddingBottom: '1.5rem',
  },
  rightContainer: {
    backgroundColor: '#FFFFFF',
  },
  progressContainer: {
    padding: '2rem',
  },
}));

const Update = (props) => {
  const classes = useStyles(props);
  const [loader, setLoader] = React.useState(false);
  const [helperText, setHelperText] = React.useState(false);

  const tabIndex = {
    0: 'Basic Info',
    1: 'Skills',
    2: 'Experience',
    3: 'Education',
    4: 'Interests',
    5: 'Certificates & Courses',
    6: 'Projects',
    7: 'Resume',
  };

  const context = useContext(AppContext);
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  // const homeContext = useContext(HomeContext);
  const [modalOpen, setModalOpen] = useState(false);

  const verifiedEmail = context?.user?.email_verified;
  const history = useHistory();

  useEffect(() => {
    if (!verifiedEmail) {
      return history.push('/home');
    }
  }, [verifiedEmail]);

  const getUserProfileInfo = () => {
    User.getUserProfile(token).then((res) => {
      const user = res?.data?.data;
      if (!user?.profession?.id || !user.city || !user.country) {
        setModalOpen(true);
      }

      // Basic Info
      setName(user.name);
      setProfession(user.profession);
      setCity(user.city);
      setCountry(user.country);
      setSummary(user.description);
      setImage(user.image_url);

      // Skills
      const soft = {},
        hard = {},
        unassigned = {};
      user.user_skills.map((skill) => {
        if (skill.type === 's') soft[skill.id] = skill;
        if (skill.type === 'h') hard[skill.id] = skill;
        if (skill.type === 'u') unassigned[skill.id] = skill;
      });
      setSoftSkills(soft);
      setHardSkills(hard);
      setUnassignedSkills(unassigned);

      // Interests
      // const interests = [];
      // user.area_of_interest?.map((interest) => interests.push(interest));
      // setInterests(interests);

      // Experience
      const experiences = mapExperience(user.experience);
      // experiences = orderBy(experiences, ['end_year', 'start_year', 'createdAt'], ['desc', 'desc', 'desc']);
      setExperiences(experiences);

      // Education
      const educations = mapEducation(user.education);
      setEducations(educations);

      // Project
      refreshProjects(
        user.projects.map((project) => ({
          ...project,
        })),
      );

      // Certificates
      const certificates = [];
      user.certificates.map((item) =>
        certificates.push({
          title: `${item.title}`,
          // "subtitle": `${item.field_name}, ${item.location?? ''}`,
          // "fieldOfStudy": {"id": item.study_field_id, "title": item.field_name},
          // "locationText": item.location,
          // "startYear": item.start_year,
          // "endYear": item.end_year,
          ...item,
        }),
      );
      setCertifications(certificates);
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    setHelperText('Fetching User Information...');
    getUserProfileInfo();
  }, []);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const [tab, setTab] = useState(0);

  const [name, setName] = useState('');
  const [profession, setProfession] = useState({});
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [summary, setSummary] = useState('');
  const [image, setImage] = useState(null);

  const [openSkill, setOpenSkill] = useState(false);
  const [openEditSkill, setOpenEditSkill] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);
  const [openPrimaryInfo, setOpenPrimaryInfo] = useState(false);
  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [unassignedSkills, setUnassignedSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [educations, setEducations] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [projects, setProjects] = useState([]);

  const [interests, setInterests] = useState([]);
  const refs = [
    useRef(tabIndex['0']),
    useRef(tabIndex['1']),
    useRef(tabIndex['2']),
    useRef(tabIndex['3']),
    useRef(tabIndex['4']),
    useRef(tabIndex['5']),
    useRef(tabIndex['6']),
    useRef(tabIndex['7']),
  ];

  const executeScroll = (ref) => scrollToRef(ref);

  const updateUser = () => context.updateUser(token);

  const handleProfileUpdate = async (data) => {
    try {
      const res = await User.updateGeneralProfile(data, token);
      const user = res.data.data;

      // Basic Info
      setName(user.name);
      setProfession({ id: user.profession_id, name: user.profession_name });
      setImage(user.image_url ? user.image_url : image);
      setCity(user.city);
      setCountry(user.country);
      setSummary(user.description);
      setOpenPrimaryInfo(false);
      updateUser();
      getUserProfileInfo();
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshExperience = (data) => {
    const experiences = mapExperience(data);
    setExperiences(experiences);
    updateUser();
  };

  const handleExperienceUpdate = async (data) => {
    try {
      const res = await User.updateExperience(data, token, { id: data.id });
      refreshExperience(res.data.data);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshEducation = (data) => {
    const educations = mapEducation(data);
    setEducations(educations);
    updateUser();
  };

  const handleEducationUpdate = async (data) => {
    try {
      const res = await User.updateEducation(data, token, { id: data.id });
      refreshEducation(res.data.data);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshSkills = (skills) => {
    const soft = [],
      hard = [],
      unassigned = [];
    skills.forEach((skill) => {
      if (skill.type === 's') soft.push(skill);
      if (skill.type === 'h') hard.push(skill);
      if (skill.type === 'u') unassigned.push(skill);
    });
    setSoftSkills(soft);
    setHardSkills(hard);
    setUnassignedSkills(unassigned);
    updateUser();
  };

  const handleSkillsUpdate = async (skills) => {
    try {
      const res = await User.updateSkills(skills, token);
      refreshSkills(res.data.data);
      setOpenSkill(false);
      setOpenEditSkill(false);
      // homeContext.fetchUpskills({ refresh: true });
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshInterests = (data) => {
    const interests = [];
    data.forEach((interest) => {
      interests.push(interest);
    });
    setInterests(interests);
    // updateUser();
  };

  const handleInterestsUpdate = async (interests) => {
    try {
      await User.updateInterests(interests, token);
      refreshInterests(interests);
      setOpenInterest(false);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshCertificates = (data) => {
    const certificates = [];
    data.map((item) =>
      certificates.push({
        title: `${item.title}`,
        // "subtitle": `${item.field_name}, ${item.location?? ''}`,
        // "fieldOfStudy": {"id": item.study_field_id, "title": item.field_name},
        // "locationText": item.location,
        // "startYear": item.start_year,
        // "endYear": item.end_year,
        ...item,
      }),
    );
    setCertifications(certificates);
    updateUser();
  };

  const handleCertificateUpdate = async (data) => {
    try {
      const res = await User.updateCertificate(data, token, { id: data.id });
      refreshCertificates(res.data.data);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const refreshProjects = (data) => {
    const projects = mapProjects(data);
    setProjects(projects);
    updateUser();
  };

  const handleProjectUpdate = async (data) => {
    try {
      const res = await User.updateProject(data, token, { id: data.id });
      refreshProjects(res.data.data);
    } catch (e) {
      // console.log('Failed:', e);
    }
  };

  const handleDeleteExperience = (data) => {
    User.deleteExperience(data.id, token).then((res) => {
      refreshExperience(res.data.data);
    });
  };

  const handleDeleteEducation = (data) => {
    User.deleteEducation(data.id, token).then((res) => {
      refreshEducation(res.data.data);
    });
  };

  const handleDeleteCertificate = (data) => {
    User.deleteCertificate(data.id, token).then((res) => {
      refreshCertificates(res.data.data);
    });
  };

  const handleDeleteProject = (data) => {
    User.deleteProject(data.id, token).then((res) => {
      refreshProjects(res.data.data);
    });
  };

  const deleteSkills = (item) => {
    let newSkills = { ...softSkills };
    delete newSkills[item.id];
    setSoftSkills(newSkills);

    newSkills = { ...hardSkills };
    delete newSkills[item.id];
    setHardSkills(newSkills);

    newSkills = { ...unassignedSkills };
    delete newSkills[item.id];
    setUnassignedSkills(newSkills);
  };

  const handleDeleteSkills = (data) => {
    deleteSkills(data);
    User.deleteSkills(data.id, token).then(() => {
      // homeContext.fetchUpskills({ refresh: true });
    });
  };

  const handleDeleteInterests = (data) => {
    User.deleteInterests(data.id, token).then(
      (res) => {
        refreshInterests(res.data.data);
      },
      () => {},
    );
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: props.isProfileCompletion ? '80vh' : '90vh',
            width: props.isProfileCompletion ? '80%' : '100%',
          }}
        >
          <Loader />
          <h6>{helperText}</h6>
        </div>
      ) : (
        <>
          <Grid className=''>
            <Grid>
              <Grid xs={12}>
                <Grid xs={12} className={classes.section} item>
                  <PrimaryInfo
                    name={name}
                    image={image}
                    profession={profession.name}
                    city={city}
                    country={country}
                    onAction={() => setOpenPrimaryInfo(true)}
                    summary={summary}
                  />
                  <PrimaryInfoInputDialog
                    data={{
                      name,
                      image,
                      profession,
                      city,
                      country,
                      summary,
                    }}
                    open={openPrimaryInfo}
                    onClose={() => setOpenPrimaryInfo(false)}
                    onSubmitted={handleProfileUpdate}
                  />
                </Grid>
                <Grid xs={12} ref={refs[1]} className={classes.section} item>
                  <ChipsPane
                    onDeleteItem={(item) => handleDeleteSkills(item)}
                    placeholderImage={skillsPlaceholder}
                    placeholderText={USER_PROFILE.NO_SKILLS}
                    title='Skills'
                    data={[
                      ...Object.values(softSkills),
                      ...Object.values(hardSkills),
                      ...Object.values(unassignedSkills),
                    ]}
                    onEditAction={() => setOpenEditSkill(true)}
                    onAction={() => setOpenSkill(true)}
                  />
                  <SkillsInputDialog
                    onSubmitted={async ({ softSkills, hardSkills }) => {
                      await handleSkillsUpdate([
                        ...softSkills,
                        ...hardSkills,
                        ...Object.values(unassignedSkills),
                      ]);
                    }}
                    open={openSkill}
                    hardSkills={Object.values(hardSkills)}
                    softSkills={Object.values(softSkills)}
                    profession_id={profession.id}
                    onClose={() => setOpenSkill(false)}
                  />
                  <SkillsInputForm
                    open={openEditSkill}
                    onClose={() => setOpenEditSkill(false)}
                    data={[
                      ...Object.values(softSkills),
                      ...Object.values(hardSkills),
                      ...Object.values(unassignedSkills),
                    ]}
                    onChange={async (skills) => {
                      await handleSkillsUpdate(skills);
                    }}
                  />
                </Grid>
                <Grid xs={12} ref={refs[2]} className={classes.section} item>
                  <ListPane
                    variant='experience'
                    placeholderImage={experiencePlaceholder}
                    placeholderText={USER_PROFILE.NO_EXPERIENCE}
                    title='Experience'
                    data={experiences}
                    onEdit={() => {}}
                    onDelete={(item) => {
                      handleDeleteExperience(item);
                    }}
                    onSubmitted={handleExperienceUpdate}
                  />
                </Grid>
                <Grid xs={12} ref={refs[3]} className={classes.section} item>
                  <ListPane
                    variant='education'
                    placeholderImage={educationPlaceholder}
                    placeholderText={USER_PROFILE.NO_EDUCATION}
                    title='Education'
                    data={educations}
                    onEdit={() => {}}
                    onDelete={handleDeleteEducation}
                    onSubmitted={handleEducationUpdate}
                  />
                </Grid>
                <Grid xs={12} ref={refs[4]} className={classes.section} item>
                  <ChipsPane
                    onDeleteItem={(item) => handleDeleteInterests(item)}
                    placeholderImage={interestsPlaceholder}
                    placeholderText={USER_PROFILE.NO_INTEREST}
                    title='Interests'
                    data={interests}
                    onAction={() => setOpenInterest(true)}
                  />
                  <InterestInputDialog
                    onSubmitted={handleInterestsUpdate}
                    interests={interests}
                    open={openInterest}
                    onClose={() => setOpenInterest(false)}
                  />
                </Grid>
                <Grid xs={12} ref={refs[5]} className={classes.section} item>
                  <ListPane
                    variant='certificates'
                    placeholderImage={certificatesPlaceholder}
                    placeholderText={USER_PROFILE.NO_CERTIFICATE}
                    title='Certificates & Courses'
                    data={certifications}
                    onEdit={() => {}}
                    onDelete={handleDeleteCertificate}
                    onSubmitted={handleCertificateUpdate}
                  />
                </Grid>
                <Grid xs={12} ref={refs[6]} className={classes.section} item>
                  <ProjectsPane
                    onSubmitted={handleProjectUpdate}
                    data={projects}
                    onDelete={handleDeleteProject}
                  />
                </Grid>
              </Grid>
            </Grid>
            {!props.isProfileCompletion ? (
              <Grid xs={12} md={3} item>
                <Grid container>
                  <Grid className={classes.navigator} xs={12} item>
                    <UploadCVPane />
                  </Grid>
                  <Grid className={classes.navigator} xs={12} item>
                    <Grid className={classes.rightContainer}>
                      <Grid className={classes.progressContainer} item>
                        <ProfileProgressIndicator
                          percentage={
                            context.user.percentage
                              ? parseInt(context.user.percentage)
                              : 0
                          }
                        />
                      </Grid>
                      <Divider fullWidth={true} />
                      <VerticalTabs
                        value={tab}
                        onChange={(oldValue, newValue) => {
                          executeScroll(refs[newValue]);
                          setTab(newValue);
                        }}
                        items={[
                          {
                            name: tabIndex[0],
                            isCompleted:
                              name !== null &&
                              profession !== null &&
                              image !== null &&
                              city !== null &&
                              country !== null,
                          },
                          {
                            name: tabIndex[1],
                            isCompleted:
                              (softSkills &&
                                Object.values(softSkills).length > 0) ||
                              (hardSkills &&
                                Object.values(hardSkills).length > 0) ||
                              (unassignedSkills &&
                                Object.values(unassignedSkills).length > 0),
                          },
                          {
                            name: tabIndex[2],
                            isCompleted: experiences && experiences.length > 0,
                          },
                          {
                            name: tabIndex[3],
                            isCompleted: educations && educations.length > 0,
                          },
                          {
                            name: tabIndex[4],
                            isCompleted: interests && interests.length > 0,
                          },
                          {
                            name: tabIndex[5],
                            isCompleted:
                              certifications && certifications.length > 0,
                          },
                          {
                            name: tabIndex[6],
                            isCompleted: projects && projects.length > 0,
                          },
                          {
                            name: tabIndex[7],
                            isCompleted: context.user.resume,
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <ProfessionalModal
            {...{ modalOpen, setModalOpen, setOpenPrimaryInfo }}
          />
        </>
      )}
    </>
  );
};

Update.propTypes = {
  onUploadCV: PropTypes.func.isRequired,
};

export default withRouter(Update);
