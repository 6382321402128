import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Box, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { Months } from '../../Constants';
import CertificateAndCourseInputDialog from '../CertificatesAndCoursesInputDialog';
import DeletionDialog from '../DeletionDialog';
import EducationInputDialog from '../EducationInputDialog';
import ExperienceInputDialog from '../ExperienceInputDialog';
import ExperienceItem from '../ExperienceItem';

import BasicPane from './BasicPane';

const useStyles = makeStyles(() => ({
  desc: {
    lineHeight: '1.2em',
  },
  dividerStyle: {
    margin: '1vh 0',
  },
}));

const ListPane = (props) => {
  const classes = useStyles(props);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState(props.data);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
    if (props.onEdit) props.onEdit(item);
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setOpenDelete(true);
    if (props.onDelete) props.onDelete(item);
  };

  useEffect(() => {
    const objects = props.data;
    for (const obj of objects) {
      let start_date = '';
      let end_date = '';
      if (obj.startUTC) {
        start_date = `${capitalize(
          Months[new Date(obj.startUTC).getMonth()],
        )} ${new Date(obj.startUTC).getUTCFullYear()}`;
      } else {
        start_date = obj.startYear;
      }
      if (obj.endUTC) {
        end_date = `${capitalize(
          Months[new Date(obj.endUTC).getMonth()],
        )} ${new Date(obj.endUTC).getUTCFullYear()}`;
      } else if (!obj.endUTC) {
        end_date = obj.endYear;
      }

      obj.date = start_date && end_date ? `${start_date} - ${end_date}` : '';
    }
    setData(objects);
  }, [props.data]);

  const [selectedItem, setSelectedItem] = useState(props.data[0]);

  return (
    <>
      <Grid
        component={BasicPane}
        placeholderText={props.placeholderText}
        placeholderImage={props.placeholderImage}
        icon={<AddIcon fontSize={'small'} color='secondary' />}
        title={props.title}
        onAction={() => setOpen(true)}
        container
      >
        {props.data && props.data.length > 0 ? (
          <>
            <Grid
              component={Box}
              className={classes.desc}
              style={{ overflow: 'visible', height: 'auto' }}
              item
            >
              <Grid direction='column' container>
                {data.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <ExperienceItem
                        key={index.toString()}
                        isSticky={true}
                        id={item.id}
                        title={item.title}
                        subtitle={item.subtitle}
                        date={item.date}
                        startYear={item.startYear}
                        endYear={item.endYear}
                        onEdit={() => handleEdit(item)}
                        onDelete={() => {
                          setSelectedItem(item);
                          setOpenDelete(true);
                        }}
                      />
                      {index < props.data.length - 1 ? (
                        <Divider className={classes.dividerStyle} />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>

      {
        // eslint-disable-next-line no-nested-ternary
        props.variant === 'experience' ? (
          <ExperienceInputDialog
            {...selectedItem}
            company={selectedItem ? selectedItem.title : null}
            designation={selectedItem ? selectedItem.subtitle : null}
            isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
            open={open}
            onClose={() => {
              setSelectedItem(null);
              setOpen(false);
            }}
            onSubmitted={async (data) => {
              await props.onSubmitted(data);
              setOpen(false);
            }}
          />
        ) : props.variant === 'education' ? (
          <EducationInputDialog
            {...selectedItem}
            degree={selectedItem ? selectedItem.title : null}
            fieldOfStudy={selectedItem ? selectedItem.subtitle : null}
            isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
            open={open}
            onClose={() => {
              setSelectedItem(null);
              setOpen(false);
            }}
            onSubmitted={async (data) => {
              await props.onSubmitted(data);
            }}
          />
        ) : (
          <CertificateAndCourseInputDialog
            {...selectedItem}
            onSubmitted={async (data) => {
              await props.onSubmitted(data);
              setOpen(false);
            }}
            open={open}
            onClose={() => {
              setSelectedItem(null);
              setOpen(false);
            }}
          />
        )
      }
      <DeletionDialog
        title='Delete'
        description='Are you sure you want to delete this item?'
        open={openDelete}
        onClose={() => {
          setSelectedItem(null);
          setOpenDelete(false);
        }}
        onPositiveAction={() => handleDelete(selectedItem)}
      />
    </>
  );
};

ListPane.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onAction: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmitted: PropTypes.func,
  placeholderText: PropTypes.string,
  placeholderImage: PropTypes.string,
  variant: PropTypes.oneOf(['education', 'experience', 'certificates']),
};

ListPane.propTypes = {
  variant: 'experience',
};

export default ListPane;
