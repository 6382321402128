import React from 'react';

const IconButton = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className=' hover:text-BluePurple  text-gray-400 mx-1 rounded-full transition-all ease-in duration-300 hover:border-BluePurple border px-2 py-1'
    >
      {children}
    </button>
  );
};

export default IconButton;
