/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from './Dialog';

const useStyles = makeStyles(() => ({
  container: {
    fontSize: '16px',
    color: '#505050',
    fontFamily: 'Avenir',
  },
}));

const SkillReadinessDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      dashboard={true}
      open={props.open}
      onClose={props.onClose}
      title='Future Readiness'
    >
      <Grid className={classes.container}>
        <p>
          In a rapidly evolving world, future readiness has become a prized
          asset. It encompasses vital skills and attributes that empower
          individuals to survive and thrive in constant change. Future-ready
          individuals can anticipate and adapt to emerging trends, technologies,
          and challenges:
        </p>
        <br />
        <p className='text-lg font-semibold text-DarkBluePurple'>
          Here's How You Can Enhance Your Future Readiness Score
        </p>

        <br />

        <div className='font-semibold text-DarkBluePurple '>
          1. Continuous Learning and Growth Commitment:
        </div>
        <div className='flex'>
          <span>a.</span>
          <p className='ml-1'>
            Online Certifications: Pursue online certifications and
            micro-credentials to gain specialized knowledge and skills.
          </p>
        </div>
        <div className='flex'>
          <span>b.</span>
          <p className='ml-1'>
            Mentoring and Coaching: Seek mentorship or coaching to accelerate
            personal and professional growth.
          </p>
        </div>
        <div className='flex'>
          <span>c.</span>
          <p className='ml-1'>
            Learning Communities: Join online or local learning communities and
            exchange peer-to-peer knowledge.
          </p>
        </div>
        <br />
        <div className='font-semibold text-DarkBluePurple '>
          2. Adaptability and Change Management:
        </div>
        <div className='flex'>
          <span>a.</span>
          <p className='ml-1'>
            Embrace Change Diversity: Develop an understanding and appreciation
            for diverse perspectives and cultures, preparing you to adapt to
            different environments effectively.
          </p>
        </div>
        <div className='flex'>
          <span>b.</span>
          <p className='ml-1'>
            Digital Literacy: Enhance your digital literacy by familiarising
            yourself with various digital tools and platforms, enabling seamless
            adaptation to technological shifts.
          </p>
        </div>
        <br />
        <div className='font-semibold text-DarkBluePurple '>
          3. Innovation and Creative Thinking:
        </div>
        <div className='flex'>
          <span>a.</span>
          <p className='ml-1'>
            Brainstorming Techniques: Learn and apply various brainstorming
            techniques to generate innovative ideas.
          </p>
        </div>
        <div className='flex'>
          <span>b.</span>
          <p className='ml-1'>
            Intrapreneurship: Explore opportunities for intrapreneurship within
            your organisation, fostering innovation from within.
          </p>
        </div>
        <div className='flex'>
          <span>c.</span>
          <p className='ml-1'>
            Creative Hobbies: Pursue creative hobbies outside work to keep your
            creative juices flowing and maintain a fresh perspective.
          </p>
        </div>
      </Grid>
    </Dialog>
  );
};

SkillReadinessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SkillReadinessDialog.defaultProps = {};

export default SkillReadinessDialog;
