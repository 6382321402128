import React, { useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const Pagination = ({
  totalCourses,
  onPageChange,
  getUserCourses = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalCourses / 18);

  const pagesCount = totalPages - 1;
  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
      getUserCourses(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < pagesCount) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
      getUserCourses(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= pagesCount; i++) {
      pageNumbers.push(i);
    }

    const maxPagesToShow = 6;
    const startIndex = Math.max(1, currentPage - maxPagesToShow / 2);
    const endIndex = Math.min(startIndex + maxPagesToShow - 1, pagesCount);

    const visiblePageNumbers = pageNumbers.slice(startIndex - 1, endIndex);

    return visiblePageNumbers.map((page) => (
      <p
        key={page}
        className={`text-normal font-medium leading-none cursor-pointer ${
          page === currentPage
            ? ' text-DarkBluePurple font-extrabold text-lg border-t-2 border-t-DarkBluePurple'
            : 'text-gray-500'
        } pt-3 mr-4 px-2`}
        onClick={() => {
          setCurrentPage(page);
          getUserCourses(page);
          onPageChange(page);
        }}
      >
        {page}
      </p>
    ));
  };

  return (
    <div className='flex items-center justify-center py-10 lg:px-0 sm:px-6 px-4'>
      <div className='lg:w-3/5 w-full  flex items-center justify-between border-t border-gray-200'>
        <div
          className={`flex items-center pt-3 text-gray-600 ${
            currentPage === 1
              ? '!cursor-not-allowed !hover:text-gray-600'
              : 'hover:text-indigo-700 cursor-pointer'
          }`}
          onClick={handlePrevClick}
        >
          <BsArrowLeft size='22' />
          <p className='text-sm ml-3 font-medium leading-none'>Previous</p>
        </div>
        <div className='sm:flex hidden mx-4 items-center'>
          {renderPageNumbers()} <span className='mt-4'>...</span>
          <span
            className='text-normal font-medium leading-none text-gray-500 mt-3 ml-6 cursor-pointer'
            onClick={() => {
              setCurrentPage(pagesCount);
              onPageChange(pagesCount);
              getUserCourses(pagesCount);
            }}
          >
            {pagesCount}
          </span>
        </div>
        <div
          className={`flex items-center pt-3 text-gray-600 cursor-pointer ${
            currentPage === pagesCount
              ? '!cursor-not-allowed !hover:text-gray-600'
              : 'hover:text-indigo-700'
          }`}
          onClick={handleNextClick}
        >
          <p className='text-sm font-medium leading-none mr-3'>Next</p>
          <BsArrowRight size='22' />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
