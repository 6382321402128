/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Payment from 'src/Api/UserPayment';
import { EasyPaisa } from 'src/Assets/images/dashboard';
import Dialog from 'src/Components/Dialog';
import LoaderButton from 'src/Components/LoaderButton';
import {
  EASY_PAISA_APPROVAL_POINTS,
  EASY_PAISA_STATUS,
} from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { capitalize } from 'src/Utils/utils';

const EasyPaisaDialog = ({
  easyPaisaModal,
  setEasyPaisaModal,
  planDetails,
  dollarToPKR,
  discount,
  setIsModalOpen,
  subscriptionId,
  getDiscount,
  paymentStatusHandler,
}) => {
  const { token } = useContext(HomeContext);
  const [loader, setLoader] = useState(false);
  const [easyPaisaError, setEasyPaisaError] = useState(null);
  const [approvalModal, setApprovalModal] = useState(false);
  const [countdownActive, setCountdownActive] = useState(false);
  const [countdown, setCountdown] = useState(80);

  const { fetchUserPayment } = useContext(AppContext);

  const totalAmountBeforeDiscount =
    Number(planDetails?.amountPKR) ||
    Math.floor(dollarToPKR * planDetails?.amount);
  const discountAmount = Math.floor(
    (discount / 100) * totalAmountBeforeDiscount,
  );
  const totalAmountAfterDiscount = totalAmountBeforeDiscount - discountAmount;

  const validationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\+92\s\d{10}$/, 'Invalid mobile number')
      .required('Mobile number is required'),
    emailAddress: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
  });
  const secretKey = 'SkillScoreSecretKey';

  const easyPaisaHandler = async () => {
    startCountdown();
    setLoader(true);
    setEasyPaisaError(null);
    try {
      const response = await Payment.easyPaisaPayment(token, {
        number: formik.values.mobileNumber,
        email: formik.values.emailAddress,
        amount: CryptoJS.AES.encrypt(
          totalAmountAfterDiscount.toString(),
          secretKey,
        ).toString(),
        discountAmount: CryptoJS.AES.encrypt(
          discountAmount.toString(),
          secretKey,
        ).toString(),
        priceId: planDetails.priceId,
        subscriptionId,
      });

      if (response.data.data.responseDesc === EASY_PAISA_STATUS.SUCCESS) {
        fetchUserPayment();
        setApprovalModal(false);
        formik.values.mobileNumber = '';
        formik.values.emailAddress = '';
        setIsModalOpen(true);
        getDiscount();
        paymentStatusHandler();
      } else {
        setEasyPaisaError(
          response.data.data.responseDesc ===
            EASY_PAISA_STATUS.ACCOUNT_DOES_NOT_EXIST
            ? response.data.data.responseDesc
            : EASY_PAISA_STATUS.SYSTEM_ERROR,
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
      setCountdownActive(false);
      setCountdown(80);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobileNumber: '',
      emailAddress: '',
    },
    validationSchema,
    onSubmit: () => {
      setApprovalModal(true);
      setEasyPaisaModal(false);
    },
  });

  const handleMobileNumberChange = (e) => {
    let input = e.target.value;

    if (input.startsWith('+92 ')) {
      input = input.slice(4);
    }
    if (input !== '') {
      input = `+92 ${input}`;
    }
    formik.setFieldValue('mobileNumber', input);
  };

  const onClose = () => {
    setEasyPaisaModal(false);
    setEasyPaisaError(null);
    formik.resetForm();
  };

  const approvalModalOnClose = () => {
    setEasyPaisaModal(true);
    setApprovalModal(false);
    setEasyPaisaError(null);
  };

  const startCountdown = () => {
    setCountdownActive(true);
  };

  useEffect(() => {
    let timer;
    if (countdownActive) {
      timer = setTimeout(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        } else {
          setCountdownActive(false);
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown, countdownActive]);

  useEffect(() => {
    if (countdown === 0) {
      setLoader(false);
    }
  }, [countdown]);

  return (
    <div>
      <Dialog
        iconUrl={EasyPaisa}
        title=''
        open={easyPaisaModal}
        onClose={onClose}
      >
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className='flex justify-between'>
              <p className='font-medium text-base text-DarkBluePurple'>
                {planDetails?.planTitle}
              </p>
              <p className='font-bold font-sans text-base text-DarkBluePurple'>
                {totalAmountBeforeDiscount.toLocaleString()}.00
              </p>
            </div>
            <div className='flex justify-between border-b py-2'>
              <p className='font-medium text-base text-DarkBluePurple'>
                Discount ({discount}%)
              </p>
              <p className='font-bold font-sans text-base text-[#63B446]'>
                {discount ? '-' : null}
                {discountAmount}.00
              </p>
            </div>
            <div className='flex justify-between border-b py-2'>
              <p className='font-medium text-base text-DarkBluePurple'>
                Total:
                <span className='text-sm text-red-600 ml-2'>
                  (Note: This is a one time payment)
                </span>
              </p>
              <p className='font-bold font-sans text-base text-DarkBluePurple'>
                {totalAmountAfterDiscount}.00
              </p>
            </div>
            <div className=' mt-4'>
              <div className='space-y-4'>
                <label className='block'>
                  <span className='text-DarkBluePurple font-medium'>
                    Mobile Number:
                  </span>
                  <input
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    type='text'
                    name='mobileNumber'
                    value={formik.values.mobileNumber}
                    onChange={handleMobileNumberChange}
                    onBlur={formik.handleBlur}
                    placeholder='+92XXXXXXXXXX'
                    maxLength={14}
                  />
                  {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber && (
                      <div className='text-red-600'>
                        {formik.errors.mobileNumber}
                      </div>
                    )}
                </label>
                <label className='block'>
                  <span className='text-DarkBluePurple font-medium'>
                    Email Address:
                  </span>
                  <input
                    className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
                    type='email'
                    name='emailAddress'
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder='Email'
                  />
                  {formik.touched.emailAddress &&
                    formik.errors.emailAddress && (
                      <div className='text-red-600'>
                        {formik.errors.emailAddress}
                      </div>
                    )}
                </label>
                <div className='flex justify-end'>
                  <button
                    type='submit'
                    className='text-white mt-3 bg-DarkBluePurple font-medium w-full rounded-lg text-sm px-3 py-2.5  '
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
      <Dialog
        title='EasyPaisa Approval'
        open={approvalModal}
        onClose={loader ? null : approvalModalOnClose}
        loader={loader}
      >
        <div>
          <div>
            <p className='text-lg font-medium pb-2 pt-1'>
              Payment will be approved via the EasyPaisa app. Click{' '}
              <span className='text-DarkBluePurple font-bold'>PAY NOW </span>
              below to initiate payment process and follow these steps:
            </p>

            <ul className='list-disc p-2'>
              {EASY_PAISA_APPROVAL_POINTS.map((item, index) => (
                <li key={index} className='font-medium pb-1'>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          {easyPaisaError && (
            <p className='text-red-500 text-sm font-semibold capitalize'>
              {capitalize(easyPaisaError)}
            </p>
          )}

          {countdownActive && (
            <p className='text-sm font-medium text-DarkBluePurple mt-2'>
              Payment expires in{' '}
              <span className='font-semibold'>{countdown}</span> seconds
            </p>
          )}
          <div className=' shadow-sm'>
            {loader ? (
              <div className='shadow-sm border mt-3 border-gray-300 w-full rounded-lg'>
                <LoaderButton text='Please Wait' />
              </div>
            ) : (
              <button
                onClick={easyPaisaHandler}
                className='text-white bg-DarkBluePurple font-medium w-full rounded-lg text-sm px-3 py-2.5 mt-5 '
              >
                Pay Now
              </button>
            )}
            {loader ? (
              <p className='text-xs italic font-semibold mt-1 text-red-500'>
                {
                  'Attention! Please DO NOT press Back or Refresh this page!. Your payment is being processed securely.'
                }
              </p>
            ) : null}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EasyPaisaDialog;
