import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

const YearAndMonthSelector = (props) => {
  const useStyles = makeStyles(() => ({
    pickerText: {
      '& .MuiInputBase-input': {
        color: '#BCBCBC',
      },
      'width': '100%',
    },
    pickerTextSelected: {
      '& .MuiInputBase-input': {
        color: '#000000',
      },
      'width': '100%',
    },
    pickerTextDisabled: {
      '& .MuiInputBase-input': {
        // color: 'red',
      },
      'width': '100%',
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let inputClassName = classes.pickerText;

  if (props.value) {
    inputClassName = classes.pickerTextSelected;
  } else if (props.disabled) {
    inputClassName = classes.pickerTextDisabled;
  }

  return (
    <KeyboardDatePicker
      views={['year', 'month']}
      inputVariant='outlined'
      className={inputClassName}
      variant='inline'
      disabled={props.disabled}
      disableFuture={true}
      emptyLabel={props.placeholder}
      open={open}
      minDate={props.minDate}
      onClick={() => {
        if (props.disabled) return;
        setOpen(true);
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={props.value}
      onChange={(value) => {
        props.onChange(value);
        setOpen(false);
      }}
    />
  );
};

YearAndMonthSelector.propTypes = {
  onChange: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  minDate: PropTypes.any,
};

YearAndMonthSelector.defaultProps = {
  minDate: new Date('1900-01-01'),
};

export default YearAndMonthSelector;
