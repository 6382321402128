import React from 'react';

import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';

const RemovePromoCodeDialog = ({ isOpen, onClose, onRemove }) => {
  return (
    <Dialog title='Remove Promo Code' open={isOpen} onClose={() => onClose()}>
      <div>
        <h1>Are you sure you want to remove promo code?</h1>
        <div className='flex justify-end'>
          <Button onClick={onRemove} title='Remove' />
        </div>
      </div>
    </Dialog>
  );
};

export default RemovePromoCodeDialog;
