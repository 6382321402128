import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import ProjectForm from './ProjectForm';

const ProjectInputDialog = (props) => {
  const { open, onClose, onSubmitted, ...data } = props;

  const onSubmit = async (data) => {
    await onSubmitted(data);
    props.onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={props.id ? 'Edit Project' : 'Add Project'}
    >
      <ProjectForm {...data} onSubmitted={onSubmit} />
    </Dialog>
  );
};

ProjectInputDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  startYear: PropTypes.string,
  startUTC: PropTypes.any,
  endYear: PropTypes.string,
  endUTC: PropTypes.any,
  company: PropTypes.string,
  location: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
};

export default ProjectInputDialog;
