import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import { COMMON } from 'src/Constants';

import Tabs from './Tabs';

const TabbedContainer = (props) => {
  const state = useLocation();
  const [tab, setTab] = useState(state?.state?.tabNumber ?? 0);

  const sectionName = props.name;

  const HandleTextChange = (newValue) => {
    if (sectionName === 'job') {
      return newValue === 1
        ? 'List of your favourite jobs'
        : 'Career opportunities tailored just for you  ';
    }
    if (sectionName === 'survey') {
      return newValue === 1
        ? 'Completed Surveys you have already submitted'
        : 'All your surveys are here in one place';
    }
    if (sectionName === 'payment') {
      return newValue === 1 ? COMMON.PAYMENT_HISTORY : COMMON.PAYMENT_TEXT;
    }
    if (sectionName === 'upskill') {
      if (newValue === 2) {
        return 'List of your favourite courses';
      }
      return newValue === 1
        ? 'List of your recommended pathways'
        : 'Our AI recommended these valuable courses for your career upgrades';
    }
  };

  return (
    <div>
      <Tabs
        value={tab}
        onChange={(oldValue, newValue) => {
          setTab(newValue);
          if (props.setState) {
            props.setState(HandleTextChange(newValue));
          }
        }}
        items={props.tabs}
      />
      <div>{props.tabs[tab].content}</div>
    </div>
  );
};

export default withRouter(TabbedContainer);
