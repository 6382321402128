/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import ApexCharts from 'apexcharts';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import html2canvas from 'html2canvas';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import UploadImage from 'src/Api/userScore';
import { Growth, Impact, Learning } from 'src/Assets/images/dashboard';
import addIcon from 'src/Assets/images/v1/icons/add-skill-icon.svg';
import LocationIcon from 'src/Assets/images/v1/icons/change-location-icon.svg';
import CareerOpportunityDialog from 'src/Components/CareerOpportunityDialog';
import FutureReadinessDialog from 'src/Components/futureReadinessDialog';
import ILODialog from 'src/Components/ILODialog';
import SkilledSCoreDialog from 'src/Components/skilledScoreDialog';
import SkillReadinessDialog from 'src/Components/skillReadinessDialog';
import LockOverlay from 'src/Components/v1/LockOverlay';
import { PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';
import { getILOScore } from 'src/Utils/utils';

// import Pyramid from '../../../Assets/images/dashboard/ilo-pyramid.svg';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Loader from '../../../Components/Loader';
import { Auth as AuthConstants } from '../../../Constants';
import DoughnutChart from '../Doughnut';
import SocialShare from '../SocialShare';

ChartJS.register(ArcElement, Tooltip, Legend);

const ScoreItem = ({ color, title, className, boxStyle }) => {
  return (
    <div
      className={`flex md:my-0 justify-start items-center text-left w-max mr-5 ${className}`}
    >
      <div
        className={`w-3 h-3 rounded-full mr-2 ${boxStyle}`}
        style={{ backgroundColor: color }}
      ></div>
      <p className='text-sm text-lighterGrey'>{title}</p>
    </div>
  );
};

const Phase = ({ title, icon }) => {
  return (
    <div className=' flex items-center'>
      <img
        width={17}
        height={17}
        src={icon}
        className='mr-2'
        alt={`${icon}-icon`}
      />
      <p className='text-sm text-lighterGrey'>{title}</p>
    </div>
  );
};

const TotalAssessmentScore = ({ dialog, isPremium }) => {
  const context = useContext(HomeContext);
  const [imageUrl, setImageUrl] = useState('');

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const divRef = React.useRef(null);

  const snapShotHandler = async () => {
    if (divRef.current) {
      try {
        const canvas = await html2canvas(divRef.current);
        const blob = await new Promise((resolve) => {
          canvas.toBlob((b) => resolve(b), 'image/png');
        });

        if (blob) {
          const capturedImageFile = new File([blob], 'captured-image.png', {
            type: 'image/png',
          });

          try {
            const response = await UploadImage.uploadImage(
              token,
              capturedImageFile,
            );
            setImageUrl(response?.data?.data?.file?.location);
            return response?.data?.data?.file?.location;
          } catch (error) {
            throw new Error('Image upload error:', error);
          }
        }
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      }
    }
  };

  const { employability, future, ilo, skills, total } = context;

  const scoreHandler = () => {
    return (
      <p>
        {total ? (
          <>
            Your Calculated Score is <span className='font-bold'>{total}</span>
          </>
        ) : (
          'Enter profile information to calculate your score'
        )}
      </p>
    );
  };

  const getColorFromPhase = (total) => {
    if (total >= 0 && total <= 30) {
      return 'shadow-md shadow-[#1E90FF] ';
    } else if (total > 30 && total <= 70) {
      return 'shadow-md shadow-[#3CB371]';
    } else if (total > 70) {
      return 'shadow-md shadow-[#DAA520]';
    }
  };

  const phaseMessage = (total) => {
    if (total >= 0 && total <= 30) {
      return (
        <span className='text-[#1E90FF] font-semibold'>Learning Phase</span>
      );
    } else if (total > 30 && total <= 70) {
      return <span className='text-[#3CB371] font-semibold'>Growth Phase</span>;
    } else if (total > 70) {
      return <span className='text-[#DAA520] font-semibold'>Impact Phase</span>;
    }
  };

  const borderColor = getColorFromPhase(total);

  return (
    <div
      className={`bg-white w-full rounded-[20px] py-4 flex flex-col xl:flex-col md:gap-2 justify-between ${
        isPremium ? `${borderColor}` : ''
      }`}
    >
      <div
        className={`flex justify-start  ${
          isPremium ? 'sm:justify-between' : 'sm:justify-end'
        }`}
      >
        {isPremium ? (
          <div className='px-1 text-start hidden sm:block'>
            <p className='font-normal mx-2 '>
              You're In {phaseMessage(Number(total))}
            </p>
          </div>
        ) : null}

        <SocialShare {...{ imageUrl, total, snapShotHandler }} />
      </div>
      <div
        className='flex flex-col sm:flex-row items-center py-4'
        id='score-chart'
      >
        <div className='mx-2 my-8 sm:my-2 sm:ml-10'>
          <div className='w-32 relative h-32 flex rounded-full justify-center items-center'>
            <div className='absolute w-32 h-32 flex justify-center  items-center text-center top-0 left-0'>
              <h2 className='text-5xl p-0 -mt-2 font-bold'>{total}</h2>
            </div>
            <DoughnutChart
              employability={employability}
              future={future}
              skills={skills}
              ilo={ilo}
              total={total}
            />
          </div>
        </div>
        <div className='px-2  sm:ml-2'>
          <div className='flex justify-between flex-wrap'>
            <h3 className='text-3xl font-semibold'>
              Skilled Score{' '}
              <InfoOutlinedIcon
                className='cursor-pointer'
                onClick={() => dialog(true)}
                size={16}
              />
            </h3>
          </div>
          <div className='py-2 text-base text-gray-600'>{scoreHandler()}</div>
          <div className='px-1 text-start block sm:hidden'>
            <p className='text-base mx-1 text-gray-600'>
              You're In {phaseMessage(Number(total))}
            </p>
          </div>
          <div
            className='w-[450px] fixed -top-[10000px] text-center px-10 py-10 text-white font-[karla-regular] rounded-[35px] bg-primaryBg'
            ref={divRef}
          >
            <img src={Logo} className='mx-auto mb-5' />
            <h1 className='text-3xl mb-5'>🚀 EXCITING NEWS! 🚀</h1>
            <p className='text-xl opacity-90 mb-5'>
              I’m pleased to share that I’ve achieved a skilled score of{' '}
              <span className='font-extrabold text-[#f5b105]'>{total}</span>,
              reflecting my current career readiness
            </p>
            <p className='text-xl opacity-90 mb-5'>
              Visit <span className='text-blue-500'> www.skilledscore.com</span>{' '}
              to find your score today
            </p>
          </div>
        </div>
      </div>
      <div className='mx-4'>
        {!context?.isFetchingScore && isPremium ? (
          <div className='flex flex-col sm:flex-row justify-between'>
            <div className='my-3 px-4 w-8/12'>
              <h2 className='text-base font-semibold my-2'>
                Breakdown of Score
              </h2>
              <div>
                <div className='flex justify-between flex-col flex-wrap'>
                  <ScoreItem color='#6FCF97' title='ILO Level' />
                  <ScoreItem
                    color='#5A359B'
                    title='Skills Readiness'
                    className='mr-7'
                  />
                  <ScoreItem color='#7E44E4' title='Geographic Opportunities' />
                  <ScoreItem color='#FF7E6B' title='Future Readiness' />
                </div>
              </div>
            </div>
            <div className='my-3 px-4 w-full md:w-4/12'>
              <h2 className='text-base font-semibold my-2'>Phases</h2>
              <div className='flex justify-between flex-col'>
                <Phase title='Impact (70+)' icon={Impact} />
                <Phase title='Growth (31-70)' icon={Growth} />
                <Phase title='Learning (0-30)' icon={Learning} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const getChartOptions = ({ height = 160, colorIndex, series }) => {
  const darkColor = ['#222B6D', '#7E44E4', '#FF7E6B', '#2DBEB6'];
  return {
    series: [series, 100 - series],
    colors: [series ? darkColor[colorIndex] : '#eeeeee', '#eeeeee'],
    chart: {
      height: height,
      width: '100%',
      type: 'donut',
    },
    stroke: {
      colors: ['transparent'],
      lineCap: '',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            total: {
              showAlways: true,
              show: false,
              label: 'Unique visitors',
              fontFamily: 'Inter, sans-serif',
              formatter: function (w) {
                const sum = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
                return `${sum}k`;
              },
            },
            value: {
              show: true,
              fontFamily: 'Inter, sans-serif',
              offsetY: -20,
              formatter: function (value) {
                return `${value}k`;
              },
            },
          },
          size: '80%',
        },
      },
    },
    grid: {
      padding: {
        top: -2,
      },
    },
    labels: ['Direct', 'Sponsor', 'Affiliate', 'Email marketing'],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
      position: 'bottom',
      fontFamily: 'Inter, sans-serif',
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${value}k`;
        },
      },
    },
    xaxis: {
      labels: {
        formatter: function (value) {
          return `${value}k`;
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
  };
};

const Chart = ({ level }) => {
  return (
    <div className='flex w-16 my-3'>
      {Array.from({ length: 4 }, (_, index) => (
        <div
          key={index + 1}
          className={`flex-1 h-4 border ${
            index + 1 <= Math.min(level, 4)
              ? ' bg-DarkBluePurple'
              : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

const BoxWithDonut = ({
  level,
  CTALabel,
  uniqueKey,
  CTAAction,
  levelName,
  description,
  colorIndex,
  series,
  isPremium,
  totalScore,
}) => {
  const isIlo = levelName === 'ILO Level';
  const history = useHistory();
  const [skillsDialog, setSkillsDialog] = useState(true);
  const [profileDialog, setProfileDialog] = useState(true);

  const HandleRoute = () => {
    setSkillsDialog(true);
    history.push('/home/profile', {
      skillsDialog,
    });
  };

  const HandleLocationRoute = () => {
    setProfileDialog(true);
    history.push('/home/profile', {
      profileDialog,
    });
  };

  const renderChart = () => {
    if (
      document.getElementById(uniqueKey.toString()) &&
      typeof ApexCharts !== 'undefined'
    ) {
      const chart = new ApexCharts(
        document.getElementById(uniqueKey.toString()),
        getChartOptions({
          colorIndex: colorIndex,
          height: 120,
          series,
        }),
      );
      const sourceElement = document.getElementById(uniqueKey.toString());

      while (sourceElement.hasChildNodes())
        sourceElement.removeChild(sourceElement.firstChild);

      chart.render();
    }
  };

  useEffect(() => {
    if (isIlo) return;
    renderChart();
  }, [ApexCharts, uniqueKey, series]);
  const [show, setShow] = useState(false);

  const isAddSkillIcon =
    levelName === 'Future Readiness' || levelName === 'Skills Readiness';

  const renderSkillLocationButton = ({
    isAddSkillIcon,
    levelName,
    handleRoute,
    handleLocationRoute,
  }) => {
    if (isAddSkillIcon || levelName === 'Geographic Opportunities') {
      const title = isAddSkillIcon
        ? 'Want to add more skills'
        : 'Change location ';
      const buttonText = isAddSkillIcon ? 'Add skills' : 'Change location';

      return (
        <div
          title={title}
          className='flex items-center cursor-pointer'
          onClick={isAddSkillIcon ? handleRoute : handleLocationRoute}
        >
          <h2 className='text-BluePurple text-[14px]'>
            <div>{buttonText}</div>
          </h2>
          <img
            className='ml-1'
            src={isAddSkillIcon ? addIcon : LocationIcon}
            alt=''
          />
        </div>
      );
    }
    return <div />;
  };
  const iloScore = totalScore > 80 ? 4 : level;
  return (
    <div className='bg-white h-52 w-full rounded-[20px]'>
      <div className='flex h-4/5 border-b-2 pb-10 py-2 px-4'>
        <div className='w-24 relative h-24 flex rounded-full justify-center items-center'>
          <div
            id={uniqueKey.toString()}
            className={`${!isPremium ? 'blur-sm' : ''}`}
          />
          <div
            className={`absolute w-24 h-24 flex justify-center items-center text-center top-0 left-0 ${
              !isPremium ? 'blur-sm' : ''
            }`}
          >
            {isIlo ? (
              <div className='flex justify-center items-center'>
                {/* we need to use this pyramid in future may be */}
                {/* <div className='flex flex-col justify-center items-center h-24 mt-4'>
                  {Array.from({ length: 9 }, (_, index) => (
                    <div
                      key={index}
                      style={getIloChartStyle(level, index + 1, isPremium)}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div> */}
                <div className='mt-2'>
                  <h2 className='font-bold text-4xl text-DarkBluePurple mt-8'>
                    {iloScore}
                  </h2>
                  <div className='container mx-auto'>
                    <Chart level={iloScore} />
                  </div>
                </div>
              </div>
            ) : (
              <h2 className='text-base p-0 -mt-2 font-semibold w-10'>
                {isPremium ? (
                  <h2 className={`${level < 10 ? 'ml-1' : ''} text-[26px]`}>
                    {level}
                    <span className='text-gray-400 text-[8px]'>/100</span>
                  </h2>
                ) : null}
              </h2>
            )}
          </div>
          {!isPremium ? <LockOverlay isIlo={isIlo} /> : null}
        </div>
        <div className='flex-1 px-2 h-[140px] overflow-y-hidden'>
          <h2 className='text-[16px] font-semibold pb-1'>{levelName}</h2>
          <p className='text-[14px] mb-5 '>{description}</p>
        </div>
      </div>
      <div className={'flex justify-between px-4 py-2 items-center'}>
        {renderSkillLocationButton({
          isAddSkillIcon,
          levelName,
          handleRoute: HandleRoute,
          handleLocationRoute: HandleLocationRoute,
        })}

        <h2
          className='cursor-pointer text-BluePurple text-[14px]'
          onClick={CTAAction ? () => CTAAction() : {}}
        >
          {isIlo ? (
            <div className='cursor-pointer' onClick={() => setShow(true)}>
              {CTALabel}
            </div>
          ) : (
            CTALabel
          )}
        </h2>
        <ILODialog open={show} onClose={() => setShow(false)} />
      </div>
    </div>
  );
};

const ScoreCard = () => {
  const context = useContext(HomeContext);
  const { userPayment } = useContext(AppContext);
  const [helperText, setHelperText] = React.useState(false);
  const [show, setShow] = useState(false);
  const [showSkillScore, setShowSkillScore] = useState(false);

  const [showSkill, setShowSkill] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const { employability, future, skills, total, iloLevel, ilo } = context;
  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const SCORES_BREAKDOWN = [
    {
      CTAAction: () => setShowSkill(true),
      level: skills,
      levelName: 'Skills Readiness',
      CTALabel: 'Learn more',
      description:
        'Skills readiness means being prepared to effectively apply specific skills to achieve goals.',
    },
    {
      CTAAction: () => setShow(true),
      level: employability,
      levelName: 'Geographic Opportunities',
      CTALabel: 'Learn more',
      description:
        'Geographic locations tie to economics, industry, culture, impacting career development.',
    },
    {
      CTAAction: () => setShowFuture(true),
      level: future,
      levelName: 'Future Readiness',
      CTALabel: 'Learn more',
      description:
        'Prioritizing future readiness & acquiring vital skills for thriving amid change are essential.',
    },
  ];

  useEffect(() => {
    setHelperText('Calculating Score...');
  }, []);

  return (
    <>
      {context?.isFetchingScore ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            width: '100%',
          }}
        >
          <Loader />
          <h6>{helperText}</h6>
        </div>
      ) : (
        <div className='grid 2xl:grid-cols-2 gap-4 my-4'>
          <TotalAssessmentScore
            dialog={setShowSkillScore}
            className='lg:col-span-1'
            isPremium={isPremium}
          />

          <div
            className='grid grid-cols-1 xl:grid-cols-2 gap-4 relative'
            id='breakdown-scores'
          >
            <BoxWithDonut
              colorIndex={3}
              value={true}
              uniqueKey='ILO Level'
              levelName='ILO Level'
              level={iloLevel}
              // level={`${context?.ilo ? `Level ${context?.ilo}` : 'N/A'}`}
              series={getILOScore(ilo)}
              CTALabel=''
              CTAAction={() => {}}
              isPremium={isPremium}
              totalScore={total}
              description={
                // eslint-disable-next-line no-nested-ternary
                isPremium ? (
                  <p>
                    Level{' '}
                    <span className='font-bold'>
                      {total > 80 ? 4 : iloLevel}
                    </span>
                    . It measures your expertise in labor rights and global work
                    standards.
                  </p>
                ) : (
                  <p>
                    It measures your expertise in labor rights and global work
                    standards.
                  </p>
                )
              }
            />

            {SCORES_BREAKDOWN.map((item, index) => (
              <div className='lg:col-span-1' key={index}>
                <BoxWithDonut
                  colorIndex={index}
                  value={false}
                  CTAAction={item.CTAAction}
                  series={Number(item.level)}
                  level={Number(item.level) > 99 ? 99 : Number(item.level)}
                  uniqueKey={item.levelName}
                  CTALabel={item.CTALabel}
                  levelName={item.levelName}
                  description={item.description}
                  isPremium={isPremium}
                />
              </div>
            ))}
            {/* {isEmailVerified &&
            userPayment?.payment_status !== PAYMENT_STATUS.PAID ? (
              <OverlayCard />
            ) : null} */}
          </div>
        </div>
      )}
      <CareerOpportunityDialog open={show} onClose={() => setShow(false)} />
      <SkilledSCoreDialog
        open={showSkillScore}
        onClose={() => setShowSkillScore(false)}
      />
      <SkillReadinessDialog
        open={showSkill}
        onClose={() => setShowSkill(false)}
      />
      <FutureReadinessDialog
        open={showFuture}
        onClose={() => setShowFuture(false)}
      />
    </>
  );
};

export default ScoreCard;
