import React, { useState } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { GoDotFill } from 'react-icons/go';

import SurveyModal from './Modal/SurveyModal';

const SurveyCard = ({ status, questionsData, available }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const questionLength = questionsData?.survey_questions?.length;
  return (
    <div className='border w-80 mx-auto md:w-96 font-[karla-regular] rounded-lg bg-white'>
      <div className='mx-5 mb-8'>
        <h1 className='text-xl my-3 font-bold'>{questionsData?.title}</h1>
        <div className='flex'>
          {available && (
            <div className='border mr-2 px-2 rounded-md'>
              <p className='flex items-center'>
                <GoDotFill className='text-greenBlue p-0' /> 2 mins
              </p>
            </div>
          )}
          <div className='border px-2 rounded-md'>
            <p className='flex items-center'>
              <GoDotFill className='text-greenBlue' /> {questionLength} Question
              {questionLength > 1 ? 's' : ''}
            </p>
          </div>
        </div>
        <p className='my-3'>
          We will ask you only {questionLength} question
          {questionLength > 1 ? 's' : ''} in this survey about your{' '}
          {questionsData?.title}
        </p>
      </div>
      <hr />
      <div className='flex justify-end'>
        {available ? (
          <button
            className='mx-3 my-3 border-2 bg-DarkBluePurple text-white rounded-lg py-2 px-3'
            onClick={() => setIsModalOpen(true)}
          >
            Start Survey
          </button>
        ) : (
          <>
            <button className='font-bold' onClick={() => setIsModalOpen(true)}>
              Edit
            </button>
            <button
              className='flex mx-3 my-3 border-2 items-center text-gray-300 rounded-lg py-2 px-3'
              disabled
            >
              <AiOutlineCheckCircle />
              <span className='ml-1'>Completed</span>
            </button>
          </>
        )}
      </div>
      {isModalOpen && (
        <SurveyModal
          status={status}
          questions={questionsData.survey_questions}
          surveyTitle={questionsData.title}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default SurveyCard;
