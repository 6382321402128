import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import { FormGroup, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Button from './Button';

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
  },
}));

const CertificateAndCourseForm = (props) => {
  const classes = useStyles();

  const [title, setTitle] = useState(props.title);

  const onSubmit = async () => {
    await props.onSubmitted({ id: props.id, title });
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormGroup>
          <Grid spacing={2} container>
            <Grid xs={12} sm={12} item>
              <TextField
                variant='outlined'
                autoFocus={true}
                fullWidth={true}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label='Certificate Or Course'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} direction='row' justify='flex-end' item container>
              <Button onClick={onSubmit} title='Save' />
            </Grid>
          </Grid>
        </FormGroup>
      </MuiPickersUtilsProvider>
    </div>
  );
};

CertificateAndCourseForm.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  fieldOfStudy: PropTypes.object,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  grade: PropTypes.string,
  locationText: PropTypes.string,
  onSubmitted: PropTypes.any,
};

CertificateAndCourseForm.defaultProps = {
  id: -1,
  title: '',
  fieldOfStudy: null,
  grade: '',
  locationText: '',
  onSubmitted: () => {},
};

export default withRouter(CertificateAndCourseForm);
