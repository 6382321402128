import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import Threads from 'src/Api/Threads';
import ResumeMessage from 'src/Components/v1/ResumeMessage';
import { MESSAGES, PAYMENT_STATUS, USER_PROFILE } from 'src/Constants/Common';

import { Chats, User } from '../../../Api';
import certificatesPlaceholder from '../../../Assets/images/profile/certificates-placeholder.svg';
import educationPlaceholder from '../../../Assets/images/profile/education-placeholder.svg';
import experiencePlaceholder from '../../../Assets/images/profile/experience-placeholder.svg';
import interestsPlaceholder from '../../../Assets/images/profile/interests-placeholder.svg';
import skillsPlaceholder from '../../../Assets/images/profile/skills-placeholder.svg';
import InterestInputDialog from '../../../Components/InterestInputDialog';
import Loader from '../../../Components/Loader';
import ChipsPane from '../../../Components/Profile/ChipsPane';
import ListPane from '../../../Components/Profile/ListPane';
import ProjectsPane from '../../../Components/Profile/ProjectsPane';
import ProjectInputDialog from '../../../Components/ProjectInputDialog';
import SkillsInputDialog from '../../../Components/SkillsInputDialog';
// import Header from '../../../Components/UploadCV/Header';
import LinkedInResume from '../../../Components/UploadCV/LinkedInResume';
import SectionsPane from '../../../Components/UploadCV/SectionsPane';
import SkillsInputForm from '../../../Components/UploadCV/SkillsInputForm';
import UploadPane from '../../../Components/UploadCV/UploadPane';
import { Auth as AuthConstants } from '../../../Constants';
import { AppContext, HomeContext } from '../../../Contexts';
import { parseLocalStorage } from '../../../Utils';

import ProfileDialog from './profileModal';

const UploadCV = (props) => {
  const [step, setStep] = useState(0);
  const [loader, setLoader] = React.useState(false);
  const [helperText, setHelperText] = React.useState(false);
  const [isLinkedIn, setLinkedIn] = useState(false);

  const [user, setUser] = useState({});

  const [, setFile] = useState(null);

  const [openSkill, setOpenSkill] = useState(false);
  const [setOpenEducation] = useState(false);
  const [setOpenExperience] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [openUpdateProfileModal, setUpdateProfileModal] = useState(false);
  const [setOpenCertificate] = useState(false);

  const { showError, userPayment } = useContext(AppContext);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];

  const [skills, setSkills] = useState([]);
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [interests, setInterests] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [projects, setProjects] = useState([]);

  const [setSelectedItem] = useState({});

  const {
    setResumeLoader,
    setResumeError,
    setResumeEligibility,
    resumeEligibility,
  } = useContext(HomeContext);

  const isUserPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const resumeValidation = async () => {
    const res = await Chats.getResumeValidation(token);
    setResumeEligibility(res?.data?.data);
  };

  useEffect(() => {
    isUserPremium ? resumeValidation() : null;
  }, [isUserPremium]);

  const getExperience = (item) => {
    const title = [item.company, item.location].filter((item) => item !== null);
    return {
      id: item.id,
      title: item.designation,
      subtitle: title.join(', '),
      locationText: item.location,
      startYear: item.start_year,
      endYear: item.end_year,
      ...item,
    };
  };

  const getEducation = (item) => {
    const title = [
      item.degree.title,
      item.fieldOfStudy.title,
      item.grade,
    ].filter((item) => item !== null);
    return {
      id: `${item.id}`,
      title: title.join(', '),
      subtitle: `${item.institute}, ${item.locationText}`,
      locationText: item.location,
      startYear: item.startYear,
      endYear: item.endYear,
      ...item,
    };
  };

  const handleDeleteCV = (item) => {
    User.deleteCV(item.id, token).then((res) => {
      setPrevFiles(res.data.data);
    });
  };

  const handleDeleteItem = (selectedItem, data, setter) => {
    setter(data.filter((item) => item.id !== selectedItem.id));
  };

  const handleSubmitCVExtract = async (data) => {
    try {
      await User.submitCVData(data, token);
      props.history.push('/home/profile');
    } catch (e) {
      // console.log(e)
    }
  };

  const handleExperienceUpdate = (data) => {
    if (data.id) {
      const updatedExperiences = [...experiences];
      updatedExperiences[parseInt(data.id)] = getExperience(data);
      setExperiences(updatedExperiences);
    } else {
      setExperiences([
        ...experiences,
        getExperience({ id: experiences.length, ...data }),
      ]);
    }
  };

  const handleEducationUpdate = (data) => {
    if (data.id) {
      const updatedEducations = [...educations];
      updatedEducations[parseInt(data.id)] = getEducation(data);
      setEducations(updatedEducations);
    } else {
      setEducations([
        ...educations,
        getEducation({ id: educations.length, ...data }),
      ]);
    }
  };

  const handleSkillsUpdate = () => {};

  const handleAction = (action) => {
    if (action === 'linkedin') {
      setLinkedIn(true);
    }
  };

  const fetchLocalStorage = async () => {
    const data = localStorage.getItem('loginData');
    const parsedData = parseLocalStorage(data);

    const threadData = await Threads.getThread(parsedData?.user?.token);

    parsedData.user.thread_id = threadData?.data?.data?.id;
    parsedData.user.content = threadData?.data?.data?.content;
    localStorage.setItem('loginData', JSON.stringify(parsedData));
    setUser(parsedData?.user);
  };

  const [, setHasData] = useState(false);

  const uploadResume = async (selectedFile) => {
    setLoader(true);
    setResumeLoader(true);

    if (!selectedFile) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('user_id', user?.id);
    let shouldDisplayMessages = true;

    const displayMessages = async (index) => {
      if (!shouldDisplayMessages) {
        return setHelperText('Adding Information to Profile...');
      }
      if (index < MESSAGES.length) {
        setHelperText(MESSAGES[index]);
        await new Promise((resolve) => setTimeout(resolve, 15000));
        await displayMessages(index + 1);
      }
    };

    const displayMessagesPromise = displayMessages(0);

    try {
      const res = await Chats.getDataFromResume(formData);
      displayMessagesPromise;

      localStorage.setItem('resumeStatus', 'true');

      if (!res?.data?.data) {
        throw res;
      }
      if (res?.data?.data) {
        shouldDisplayMessages = false;
      }
      if (res?.data?.data?.message) {
        return setResumeError(res.data.data.message);
      }

      await User.updateUserProfile({
        education: res.data.data.formatedEducation,
        experience: res.data.data.formatedExperience,
        soft_skills: res.data.data.formatedSoftSkills,
        hard_skills: res.data.data.formatedHardSkills,
        certificate: res.data.data.formatedCertificate,
        project: res.data.data.formatedProject,
        profession: res.data.data.profession,
        location: res.data.data.location,
        city: res.data.data.city,
        country: res.data.data.country,
        user_id: user?.id,
        description: res.data.data.description,
        interests: res.data.data.formatedInterests,
      });

      setHelperText('Resume Data Added. Please wait...');
      setUpdateProfileModal(true);
      fetchLocalStorage();
    } catch (e) {
      console.error(e);
      setResumeError(e.response?.data?.error_code || 'Unknown Error');
    } finally {
      setResumeLoader(false);
      setLoader(false);
      setFile(null);
      setHasData(true);
    }
  };

  useEffect(() => {
    fetchLocalStorage();
  }, []);

  useEffect(() => {
    User.getCVs(token).then((res) => {
      setPrevFiles(res.data.data);
    });
  }, []);

  const [sections] = useState([]);

  const sectionComponents = {
    experiences: (
      <>
        <ListPane
          variant='experience'
          placeholderImage={experiencePlaceholder}
          placeholderText={USER_PROFILE.NO_EXPERIENCE}
          onDelete={(item) =>
            handleDeleteItem(item, experiences, setExperiences)
          }
          title='Experience'
          data={experiences}
          onSubmitted={handleExperienceUpdate}
          onAction={() => setOpenExperience(true)}
        />
      </>
    ),
    educations: (
      <>
        <ListPane
          onEdit={(item) => {
            setSelectedItem(item);
            setOpenEducation(true);
          }}
          variant='education'
          placeholderImage={educationPlaceholder}
          onDelete={(item) => handleDeleteItem(item, educations, setEducations)}
          placeholderText={USER_PROFILE.NO_EDUCATION}
          onSubmitted={handleEducationUpdate}
          title='Education'
          data={educations}
          onAction={() => setOpenEducation(true)}
        />
      </>
    ),
    skills: (
      <>
        {sections[step - 1] === 'skills' ? (
          <SkillsInputForm
            data={skills}
            onChange={(skills) => {
              setSkills(skills);
            }}
            onOpen={() => setOpenSkill(true)}
          />
        ) : (
          <ChipsPane
            onDeleteItem={(item) => handleDeleteItem(item, skills, setSkills)}
            placeholderImage={skillsPlaceholder}
            placeholderText={USER_PROFILE.NO_SKILLS}
            title='Skills'
            data={skills}
            onAction={() => setOpenSkill(true)}
          />
        )}
        <SkillsInputDialog
          softSkills={skills.filter((skill) => skill.type === 's')}
          hardSkills={skills.filter((skill) => skill.type === 'h')}
          open={openSkill}
          onClose={() => setOpenSkill(false)}
          onSubmitted={handleSkillsUpdate}
        />
      </>
    ),
    interests: (
      <>
        <ChipsPane
          onDeleteItem={(item) =>
            handleDeleteItem(item, interests, setInterests)
          }
          placeholderImage={interestsPlaceholder}
          placeholderText={USER_PROFILE.NO_INTEREST}
          title='Interests'
          data={interests}
          onAction={() => setOpenInterest(true)}
        />
        <InterestInputDialog
          open={openInterest}
          onClose={() => setOpenInterest(false)}
        />
      </>
    ),
    certifications: (
      <>
        <ListPane
          variant='certificates'
          placeholderImage={certificatesPlaceholder}
          placeholderText={USER_PROFILE.NO_CERTIFICATE}
          onDelete={(item) =>
            handleDeleteItem(item, certifications, setCertifications)
          }
          title='Certificates & Courses'
          data={certifications}
          onAction={() => setOpenCertificate(true)}
        />
      </>
    ),
    projects: (
      <>
        <ProjectsPane
          data={projects}
          onDelete={(item) => handleDeleteItem(item, projects, setProjects)}
          onAction={() => setOpenProject(true)}
        />
        <ProjectInputDialog
          open={openProject}
          onClose={() => setOpenProject(false)}
        />
      </>
    ),
  };

  const handleCVUpload = (file) => {
    setFile(file);
    return new Promise((res) => {
      res(file);
    });
  };

  return (
    <>
      {loader ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            width: '100%',
          }}
        >
          <Loader />
          <h6>{helperText}</h6>
        </div>
      ) : (
        <Grid>
          {isUserPremium ? (
            <ResumeMessage resumeEligibility={resumeEligibility} />
          ) : null}
          <ProfileDialog
            open={openUpdateProfileModal}
            onClose={() => {
              setUpdateProfileModal(false);
              props.history.push('/home');
            }}
          />

          <Grid>
            <Grid>
              <Grid />
              <Grid>
                <Grid>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    step === 0 ? (
                      <Grid
                        component={isLinkedIn ? LinkedInResume : UploadPane}
                        onAction={handleAction}
                        onUploadFailed={(err) => {
                          showError(err);
                        }}
                        onDelete={handleDeleteCV}
                        prevFiles={prevFiles}
                        onUpload={(file) => handleCVUpload(file)}
                        onUploadComplete={(res) => {
                          uploadResume(res);
                        }}
                      />
                    ) : step === sections.length + 1 ? (
                      <SectionsPane
                        onNext={async () =>
                          await handleSubmitCVExtract({
                            skills,
                            experience: experiences,
                            education: educations,
                            certificate: certifications,
                          })
                        }
                        onBack={() => setStep(step - 1)}
                        progress={step * (100 / (sections.length + 1))}
                      >
                        {
                          <Grid>
                            {sections.map((section, key) => (
                              <Grid key={key}>
                                {sectionComponents[section]}
                              </Grid>
                            ))}
                          </Grid>
                        }
                      </SectionsPane>
                    ) : (
                      <SectionsPane
                        onNext={() => {
                          setStep(step + 1);
                        }}
                        onBack={() => {
                          setStep(step - 1);
                        }}
                        progress={step * (100 / (sections.length + 1))}
                      >
                        {sectionComponents[sections[step - 1]]}
                      </SectionsPane>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withRouter(UploadCV);
