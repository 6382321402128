import React, { useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Projects from '../../../Api/Projects';
import placeholder from '../../../Assets/images/upskills/no-recommended-placeholder.svg';
import AssessmentCard from '../../../Components/AssessmentCard';
import AssessmentProjectDialog from '../../../Components/AssessmentProjectDialog';
import ItemsGrid from '../../../Components/Dashboard/ItemsGrid';
import { HomeContext } from '../../../Contexts';

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: '20px',
    color: '#505050',
    margin: '1% 0px',
  },
  desc: (props) => ({
    overflow: props ? 'visible' : 'hidden',
    height: props ? 'auto' : '3.6em',
  }),
  loadMore: {
    margin: '2rem 0',
  },
}));

const Recommended = (props) => {
  const classes = useStyles();

  const [projects, setProjects] = useState(null);
  const context = useContext(HomeContext);

  const [, setOpenStartDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [user, setUser] = useState({});

  const onCardClick = (index) => {
    setSelected(index);
    setOpen(true);
  };

  const fetchAllProjects = () => {
    Projects.allProjects(user.id)
      .then((data) => {
        setProjects(data.data.data);
      })
      .catch(() => {
        // console.log({ err });
      });
  };

  const fetchLocalStorage = async () => {
    const data = await localStorage.getItem('loginData');
    const parsedData = await JSON.parse(data);
    setUser(parsedData?.user);
  };
  useEffect(() => {
    fetchLocalStorage();
  }, []);

  const onSubmitProject = (project_id) => {
    Projects.updateProjects({ user_id: user?.id, project_id }).then(() => {
      fetchAllProjects();
      setOpen(false);
    });
  };

  useEffect(() => {
    if (user.id) {
      fetchAllProjects();
    }
  }, [user]);

  return (
    <Grid direction='row' justify='center' container>
      <Grid xs={12} item>
        <Typography className={classes.heading} variant='h4'>
          Projects
        </Typography>
      </Grid>
      <Grid xs={12} item>
        <ItemsGrid
          data={projects}
          placeholderImage={placeholder}
          placeholderText='No Projects available'
          loader={projects ? false : true}
        >
          <Grid spacing={4} container>
            {projects === null
              ? [0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => (
                  <Grid key={index} lg={3} md={4} sm={6} xs={12} item>
                    <AssessmentCard loading={true} />
                  </Grid>
                ))
              : projects.map((upSkill, index) => (
                  <Grid key={upSkill.id} lg={3} md={4} sm={6} xs={12} item>
                    <AssessmentCard
                      onViewDetails={() => onCardClick(index)}
                      {...upSkill}
                      onDelete={() =>
                        context.removeItem.upskills(upSkill, 'recommended')
                      }
                      onStart={() => {
                        setSelected(index);
                        setOpenStartDialog(true);
                      }}
                      onAnswer={(answer) => {
                        setSelected(index);
                        if (answer) {
                          setOpenStartDialog(true);
                        } else {
                          context.markStatusItem.upskills(
                            context.upskills.recommended[selected],
                            'enrolled',
                            { value: answer },
                          );
                        }
                      }}
                      onSaved={() => {
                        context.markStatusItem.upskills(
                          context.upskills.recommended[index],
                          'saved',
                        );
                      }}
                      variant={'recommended'}
                      {...props}
                    />
                  </Grid>
                ))}
          </Grid>
        </ItemsGrid>
      </Grid>

      {projects > 0 ? (
        <AssessmentProjectDialog
          onSubmitProject={onSubmitProject}
          {...projects[selected]}
          onClose={() => setOpen(false)}
          open={open}
        />
      ) : null}
    </Grid>
  );
};

export default Recommended;
