import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import format from 'date-fns/format';
import randomMC from 'random-material-color';

import UserCourses from 'src/Api/GetCourses';
import UserJobs from 'src/Api/GetJobs';
import GetUser from 'src/Api/GetSummary';
import Skills from 'src/Api/Interests';
import Pathway from 'src/Api/Pathway';
import { getLocalUser } from 'src/Utils/utils';

import { Courses, Jobs, Notifications, Surveys, User } from '../Api';
import { Auth as AuthConstants } from '../Constants';

const HomeContext = React.createContext({});

const HomeContextProvider = (props) => {
  const [cookies] = useCookies([AuthConstants.JWT_TOKEN]);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const [, , removeCookie] = useCookies([AuthConstants.JWT_TOKEN]);

  const [notifications, setNotifications] = useState({
    total: 0,
    unread: 0,
    data: [],
  });

  const [recommendedUpskills, setRecommendedUpskills] = useState(null);
  const [savedUpskills, setSavedUpskills] = useState([]);
  const [enrolledUpskills, setEnrolledUpskills] = useState(null);
  const [completedUpskills, setCompletedUpskills] = useState(null);
  const [isFetchingScore, setIsFetchingScore] = useState(null);
  // const [userPayment, setUserPayment] = useState(null);
  const [resumeLoader, setResumeLoader] = useState(false);
  const [resumeError, setResumeError] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [userSavedJobs, setUserSavedJobs] = useState(null);
  const [userSavedCourses, setUserSavedCourses] = useState(null);
  const [userCourses, setUserCourses] = useState(null);
  const [userJobs, setUserJobs] = useState(null);
  const [userPathways, setUserPathways] = useState([]);

  const [newSurveys, setNewSurveys] = useState([]);
  const [completedSurveys, setCompletedSurveys] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState(null);
  const [savedJobs, setSavedJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [surveyData, setSurveyData] = useState(null);
  const [submittedSurveyData, setSubmittedSurveyData] = useState([]);
  const [liveJobLoader, setLiveJobLoader] = useState(true); // TODO: Need to make 1 loader for all
  const [upSkillsLoader, setUpSkillsLoader] = useState(true);
  const [skillsLoader, setSkillsLoader] = useState(true);
  const [careerTrajectory, setCareerTrajectory] = useState([]);
  const [coursesCount, setCoursesCount] = useState(0);

  const [employability, setEmployability] = useState(0);
  const [skills, setSkills] = useState(0);
  const [total, setTotal] = useState(0);
  const [future, setFuture] = useState(0);
  const [ilo, setILO] = useState(null);
  const [iloLevel, setIloLevel] = useState(0);

  const [jobRecPage, setJobRecPage] = useState(1);
  const [jobSavedPage, setJobSavedPage] = useState(1);
  const [jobAppliedPage, setJobAppliedPage] = useState(1);

  const [courseRecPage, setCourseRecPage] = useState(1);
  const [courseEnrolledPage, setCourseEnrolledPage] = useState(1);
  const [courseSavedPage, setCourseSavedPage] = useState(1);
  const [courseCompletedPage, setCourseCompletedPage] = useState(1);
  const [isWalkthroughRunning, setIsWalkthroughRunning] = useState(false);
  const [resumeEligibility, setResumeEligibility] = useState('');
  const [areaOfInterest, setAreaOfInterest] = useState([]);

  const [jobSize] = useState(20);
  const [courseSize] = useState(20);

  const surveySetters = { new: setNewSurveys, completed: setCompletedSurveys };
  const surveys = { new: newSurveys, completed: completedSurveys };

  const upskillsSetters = {
    recommended: setRecommendedUpskills,
    enrolled: setEnrolledUpskills,
    saved: setSavedUpskills,
    completed: setCompletedUpskills,
  };
  const upskills = {
    recommended: recommendedUpskills,
    enrolled: enrolledUpskills,
    saved: savedUpskills,
    completed: completedUpskills,
  };

  const jobSetters = {
    recommended: setRecommendedJobs,
    saved: setSavedJobs,
    applied: setAppliedJobs,
  };
  const jobs = {
    recommended: recommendedJobs,
    saved: savedJobs,
    applied: appliedJobs,
  };

  const jobPage = {
    recommended: jobRecPage,
    saved: jobSavedPage,
    applied: jobAppliedPage,
  };
  const jobPageSetter = {
    recommended: setJobRecPage,
    saved: setJobSavedPage,
    applied: setJobAppliedPage,
  };
  const coursePage = {
    recommended: courseRecPage,
    saved: courseSavedPage,
    enrolled: courseEnrolledPage,
    completed: courseCompletedPage,
  };
  const coursePageSetter = {
    recommended: setCourseRecPage,
    saved: setCourseSavedPage,
    enrolled: setCourseEnrolledPage,
    completed: setCourseCompletedPage,
  };

  const mapResponseToView = {
    upskills: (upskill) => ({
      id: upskill.id,
      title: upskill.title,
      image: upskill.image_url,
      description: upskill.description,
      shortDescription: upskill.short_description,
      link: upskill.url,
      duration: upskill.duration,
      author: upskill.author,
      skillLevel: upskill.difficulty_level,
      skills: upskill.matched_skills.split(','),
      visited:
        upskill.status === 'visited' || upskill.status === 'visited_saved',
      saved: upskill.status === 'saved' || upskill.status === 'visited_saved',
      enrolled: upskill.status === 'enrolled',
      completed: upskill.status === 'completed',
    }),
    surveys: (survey) => ({
      link: survey.url,
      visited: survey.type === 'visited',
      completed: survey.type === 'completed',
      ...survey,
    }),
    jobs: (job) => ({
      id: job.id,
      company: job.company_name,
      location: `${job.company_city}, ${job.company_country}`,
      position: job.job_role,
      image: job.company_image_url,
      imageColor: randomMC.getColor({ text: job.id }),
      description: job.job_description,
      shortDescription: job.short_description,
      industry: job.company_industry,
      experience: job.job_level,
      visited: job.status === 'visited' || job.status === 'visited_saved',
      monthlySalaryRange: job.job_salary,
      numberOfVacancies: job.job_vacancies,
      title: job.job_title,
      subtitle: job.company_name,
      link: job.job_url,
      microtitle: job.job_experience,
      skills: job.job_skills.split(','),
      saved: job.status === 'saved' || job.status === 'visited_saved',
      applied: job.status === 'applied',
      postedDate: job.posted_date,
    }),
    notifications: (notification) => ({
      ...notification,
      id: notification.id,
      title: notification.payload.title,
      description: notification.payload.description,
      date: format(new Date(notification.created_at), 'yyyy-MM-dd'),
    }),
  };

  const checkSave = (item, data, setter) => {
    const newData = [...data];
    newData.forEach((value) => {
      if (value.id === item.id) value.saved = !value.saved;
    });
    setter(newData);
  };

  const removeFromList = (item, data, setter) => {
    setter(data.filter((value) => value.id !== item.id));
    Surveys.deleteSurvey(item.id, token).then(
      () => {},
      () => {
        fetchSubmittedSurveys();
      },
    );
  };

  const markStatusItem = {
    surveys: async (survey, status) => {
      await Surveys.updateStatus(survey.id, token, status);
      const newData = [...surveys.new];
      if (status === 'completed') {
        setNewSurveys(newSurveys.filter((item) => item.id !== survey.id));
        setCompletedSurveys([...completedSurveys, survey]);
      } else {
        newData.forEach((item) => {
          if (item.id === survey.id) {
            item.status = status;
            ['visited', 'completed', 'new'].map(
              (state) => (item[state] = state === status),
            );
          }
        });
        surveySetters.new(newData);
      }
    },

    upskills: (course, status, { value = true } = {}) => {
      if (status === 'visited' && course.visited) return;
      Courses.updateStatus(course.id, token, status, { value }).then(() => {
        // fetchUpskills({ refresh: true });
      });
    },
  };

  const checkSaveItem = {
    jobs: async (job, type) => {
      await Jobs.save(job.id, token);
      if (job.saved) removeFromList(job, savedJobs, setSavedJobs);
      else setSavedJobs([...savedJobs, job]);
      checkSave(job, jobs[type], jobSetters[type]);
    },
  };

  const removeItem = {
    upskills: (upskill, type) => {
      removeFromList(upskill, upskills[type], upskillsSetters[type]);
      if (type === 'saved') {
        Courses.updateStatus(upskill.id, token, 'saved', {
          value: false,
        }).finally();
      } else if (type === 'recommended') {
        Courses.deleteCourse(upskill.id, token, { type }).finally();
      }
    },
    jobs: (job, type) => {
      removeFromList(job, jobs[type], jobSetters[type]);
      if (type === 'saved') {
        Jobs.updateStatus(job.id, token, 'saved', { value: false }).finally();
      } else if (type === 'recommended') {
        Jobs.deleteJob(job.id, token, { type }).finally();
      }
    },
    surveys: (survey, type) =>
      removeFromList(survey, surveys[type], surveySetters[type]),
  };

  const loadMoreJobs = ({ callback = () => {}, type }) => {
    Jobs.list(token, { type, beg: jobPage[type], size: jobSize }).then(
      (res) => {
        if (
          res.data.data === null ||
          (res.data.data?.length && res.data.data?.length === 0)
        ) {
          callback(res.data.data?.length);
          return;
        }
        jobSetters[type]([
          ...jobs[type],
          ...(res.data.data?.map((job) => mapResponseToView.jobs(job)) || []),
        ]);
        jobPageSetter[type](jobPage[type] + 1);
        callback(res.data.data?.length);
      },
    );
  };

  const loadMoreCourses = ({ callback = () => {}, type }) => {
    Courses.list(token, { type, beg: coursePage[type], size: courseSize }).then(
      (res) => {
        if (
          res?.data?.data === null ||
          (res?.data?.data?.length && res.data.data?.length === 0)
        ) {
          callback(res.data.data?.length);
          return;
        }
        upskillsSetters[type]([
          ...upskills[type],
          ...(res.data.data?.map((upskill) =>
            mapResponseToView.upskills(upskill),
          ) || []),
        ]);
        coursePageSetter[type](coursePage[type] + 1);
        callback(res.data.data?.length);
      },
    );
  };

  const fetchUpskills = ({
    callback = () => {},
    refresh = false,
    first = false,
  } = {}) => {
    ['recommended'].map((type) =>
      Courses.list(token, {
        type,
        beg: refresh ? 0 : coursePage[type],
        // eslint-disable-next-line no-nested-ternary
        size: refresh
          ? coursePage[type] === 0
            ? courseSize
            : courseSize * coursePage[type]
          : courseSize,
      }).then((res) => {
        if (
          res?.data?.data === null ||
          (res?.data?.data?.length && res.data.data?.length === 0)
        )
          return;

        upskillsSetters[type]([
          ...(refresh ? [] : upskills[type]),
          ...(res.data.data?.map((upskill) =>
            mapResponseToView.upskills(upskill),
          ) || []),
        ]);
        if (!refresh && !first) coursePageSetter[type](coursePage[type] + 1);
        callback();
        setUpSkillsLoader(false);
      }),
    );
  };

  const fetchSurveys = () => {
    Surveys.list(token)
      .then((data) => {
        setSurveyData(data.data.data);
      })
      .catch()
      .finally(() => {});
  };

  const fetchSubmittedSurveys = () => {
    Surveys.listSubmitted(token)
      .then((data) => {
        setSubmittedSurveyData(data.data.data);
      })
      .catch()
      .finally(() => {});
  };

  const markRead = (notification) => {
    Notifications.markRead(notification.id, token).then(
      () => {
        setNotifications({
          ...notifications,
          unread: !notification.is_read
            ? notifications.unread - 1
            : notifications.unread,
        });
      },
      (err) => {
        console.error('Notifications.markRead:', err);
      },
    );
  };

  const getUser = async () => {
    try {
      const res = await User.getUserProfile(token);
      const user = res?.data?.data;
      if (user) {
        setUserProfile(user);
      }
      if (res.status === 401) {
        removeCookie(AuthConstants.JWT_TOKEN, {
          path: '/',
        });
        localStorage.removeItem('loginData');
        localStorage.removeItem('login');
        removeCookie(AuthConstants.PROFILE_STATUS, {
          path: '/',
        });
        history.push('/sign-in');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const getAreaOfInterest = async () => {
    const response = await Skills.getAreaOfInterest(token);
    setAreaOfInterest(response?.data?.data);
  };

  const getUserJobs = async (isScrapping) => {
    if (userProfile?.profession) {
      const response = await fetch(process.env.REACT_APP_GEO_LOCATION_API);
      const data = await response.json();
      // setUserJobs(null);
      // setLiveJobLoader(true);
      const city = userProfile?.city;

      await UserJobs.jobs(token, {
        city: city?.name || data?.city,
        country: userProfile?.country?.name || data?.country,
        profession: userProfile?.profession?.name,
        isScrapping: isScrapping,
        userExperience: userProfile?.experience?.length,
      })
        .then((data) => {
          if (data?.data?.data) {
            setUserJobs(data.data.data);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLiveJobLoader(false);
        });
    } else {
      setUserJobs([]);
      setLiveJobLoader(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const fetchUserScore = () => {
    setIsFetchingScore(true);
    User.getScores(token)
      .then((score) => {
        if (!score) return;
        setSkills(score.skills || 0);
        setEmployability(score.employability || 0);
        setTotal(score.total || 0);
        setFuture(score.future || 0);
        setILO(score?.ilo || 0);
        setIloLevel(score?.iloLevel || 0);
      })
      .finally(() => {
        setIsFetchingScore(false);
      });
  };

  const getSavedJobs = async () => {
    const parsedData = await getLocalUser();

    UserJobs.get_save_job(token, {
      userId: parsedData.user.id,
    })
      .then((response) => {
        if (response?.data?.data) setUserSavedJobs(response.data.data);
      })
      .catch(() => {});
  };

  const getSavedCourses = async () => {
    const parsedData = await getLocalUser();

    UserCourses.get_save_course(token, {
      userId: parsedData.user.id,
    })
      .then((response) => {
        if (response?.data?.data) setUserSavedCourses(response.data.data);
      })
      .catch(() => {});
  };

  const getCareerTrajectory = async () => {
    const parsedData = await getLocalUser();

    await GetUser.summary(token, {
      thread_id: parsedData.user.thread_id,
    }).then((response) => {
      setCareerTrajectory(response);
    });
  };

  const getUserCourses = async (pageNum, forDashboard) => {
    const skillsArray = userProfile.user_skills.map((skill) => skill.name);
    setSkillsLoader(true);
    await UserCourses.get(token, {
      skills: skillsArray,
      pageNum,
      forDashboard,
    })
      .then((response) => {
        if (!response?.data?.data) return;
        if (response?.data?.data) {
          setUserCourses(response?.data?.data);
          setCoursesCount(response?.data?.data?.count);
        }
      })
      .catch(() => {})
      .finally(() => {
        setSkillsLoader(false);
      });
  };

  const getUserPathways = async () => {
    await Pathway.list(token)
      .then((response) => {
        setUserPathways(response?.data?.data);
      })
      .catch(() => {})
      .finally(() => {});
  };

  return (
    <HomeContext.Provider
      value={{
        upskills,
        surveys,
        jobs,
        removeItem,
        checkSaveItem,
        markStatusItem,
        fetchSubmittedSurveys,
        fetchSurveys,
        employability,
        skills,
        total,
        future,
        ilo,
        iloLevel,
        notifications,
        isFetchingScore,
        markRead,
        fetchUpskills,
        jobSetters,
        upskillsSetters,
        loadMoreJobs,
        loadMoreCourses,
        fetchUserScore,
        upSkillsLoader,
        submittedSurveyData,
        surveyData,
        setResumeLoader,
        resumeLoader,
        setResumeError,
        resumeError,
        setUserProfile,
        userProfile,
        getSavedJobs,
        userSavedJobs,
        getSavedCourses,
        userSavedCourses,
        getUserCourses,
        userCourses,
        coursesCount,
        getUserJobs,
        userJobs,
        liveJobLoader,
        skillsLoader,
        careerTrajectory,
        getCareerTrajectory,
        getUserPathways,
        userPathways,
        setIsWalkthroughRunning,
        isWalkthroughRunning,
        resumeEligibility,
        setResumeEligibility,
        token,
        getAreaOfInterest,
        areaOfInterest,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

export { HomeContext, HomeContextProvider };
