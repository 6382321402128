import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBase, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import deleteIcon from '../../Assets/images/icon-delete-cv.svg';
import pdfIcon from '../../Assets/images/icon-pdf.svg';

const useStyles = makeStyles(() => ({
  name: {
    flexGrow: 1,
  },
  nameStyle: {
    color: '#848484',
    fontSize: '16px',
    fontFamily: 'Avenir',
    fontWeight: 'normal',
  },
  iconStyle: {
    padding: '8px',
  },
  deleteIcon: {
    padding: '16px',
  },
}));

const CVItem = (props) => {
  const classes = useStyles(props);

  return (
    <Grid alignItems='center' justify='space-between' container>
      <Grid item>
        <Grid alignItems='center' container>
          <Grid className={classes.iconStyle} item>
            <img alt='pdf' src={pdfIcon} />
          </Grid>
          <Grid className={classes.name} item>
            <Typography className={classes.nameStyle} variant='h4'>
              {props.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        component={ButtonBase}
        className={classes.deleteIcon}
        onClick={(e) => {
          e.stopPropagation();
          props.onDelete();
        }}
        item
      >
        <img alt='pdf' src={deleteIcon} />
      </Grid>
    </Grid>
  );
};

CVItem.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  onDelete: PropTypes.func,
};

CVItem.defaultProps = {
  onDelete: () => {},
};

export default CVItem;
