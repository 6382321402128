import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { Box, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { USER_PROFILE } from 'src/Constants/Common';

import projectsPlaceholder from '../../Assets/images/profile/projects-placeholder.svg';
import { Months } from '../../Constants';
import DeletionDialog from '../DeletionDialog';
import ProjectInputDialog from '../ProjectInputDialog';
import ProjectsItem from '../ProjectsItem';

import BasicPane from './BasicPane';

const useStyles = makeStyles(() => ({
  desc: {
    lineHeight: '1.2em',
  },
  dividerStyle: {
    margin: '1vh 0',
  },
}));

const ProjectsPane = (props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [data, setData] = useState(props.data);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDelete = (item) => {
    props.onDelete(item);
    setOpenDelete(false);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const objects = props.data;
    for (const obj of objects) {
      let start_date = '';
      let end_date = '';
      if (obj.startUTC) {
        start_date = `${capitalize(
          Months[new Date(obj.startUTC).getMonth()],
        )} ${new Date(obj.startUTC).getUTCFullYear()}`;
      } else {
        start_date = obj.startYear;
      }
      if (obj.endUTC) {
        end_date = `${capitalize(
          Months[new Date(obj.endUTC).getMonth()],
        )} ${new Date(obj.endUTC).getUTCFullYear()}`;
      } else if (!obj.endUTC) {
        end_date = obj.endYear;
      }

      obj.date = start_date && end_date ? `${start_date} - ${end_date}` : '';
    }
    setData(objects);
  }, [props.data]);

  return (
    <>
      <Grid
        component={BasicPane}
        placeholderText={USER_PROFILE.NO_PROJECT}
        placeholderImage={projectsPlaceholder}
        icon={<AddIcon fontSize={'small'} color='secondary' />}
        title='Projects'
        onAction={() => setOpen(!open)}
        container
      >
        {props.data?.length ? (
          <>
            <Grid
              component={Box}
              className={classes.desc}
              style={{ overflow: 'visible', height: 'auto' }}
              item
            >
              <Grid direction='column' container>
                {data.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <ProjectsItem
                        key={index.toString()}
                        onDelete={() => {
                          setSelectedItem(item);
                          setOpenDelete(true);
                        }}
                        {...item}
                        onEdit={() => handleEdit(item)}
                        isSticky={true}
                        startYear={item.startYear}
                        endYear={item.endYear}
                        id={item.id}
                        image={item.image}
                        title={item.title}
                        subtitle={item.subtitle}
                        date={item.date}
                        onActionPerformed={() => {}}
                      />
                      {index < props.data.length - 1 ? (
                        <Divider className={classes.dividerStyle} />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      <ProjectInputDialog
        {...selectedItem}
        onSubmitted={async (data) => await props.onSubmitted(data)}
        isCurrent={!selectedItem?.endYear && selectedItem?.startYear}
        open={open}
        onClose={() => {
          setSelectedItem(null);
          setOpen(false);
        }}
      />
      <DeletionDialog
        title='Delete'
        description='Are you sure you want to delete this item?'
        open={openDelete}
        onClose={() => {
          setSelectedItem(null);
          setOpenDelete(false);
        }}
        onPositiveAction={() => handleDelete(selectedItem)}
      />
    </>
  );
};

ProjectsPane.propTypes = {
  data: PropTypes.array.isRequired,
  onAction: PropTypes.func,
  onSubmitted: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ProjectsPane;
