import React from 'react';

import SearchInput from './SearchInput';

const Search = ({
  searchQuery,
  setSearchQuery,
  onSearch,
  placeholder = 'Search by job title',
  label = 'Search Jobs :',
}) => {
  return (
    <div className='flex items-center flex-wrap sm:flex-nowrap'>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder={placeholder}
        label={label}
      />
      <button
        className={` sm:mx-3 border-2 bg-DarkBluePurple text-white w-full sm:w-24 h-[45px] rounded-lg px-5 mt-3 py-1 ${
          !searchQuery && 'opacity-80 cursor-not-allowed'
        }`}
        type='submit'
        onClick={onSearch}
        disabled={!searchQuery}
      >
        Search
      </button>
    </div>
  );
};

export default Search;
