import { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Dialog from 'src/Components/Dialog';

import ResumeImage from '../../Assets/images/v1/resume.svg';

const ResumeSection = () => {
  const history = useHistory();
  const [resumeDialog, setResumeDialog] = useState(false);

  const resumeStatus = localStorage.getItem('resumeStatus');

  return (
    <div className='md:mr-2 rounded-lg px-4 pb-4 bg-white'>
      <img src={ResumeImage} alt='resume' />
      <div className=' flex items-center mt-4'>
        <h1 className='font-medium text-xl '>Upload your resume</h1>
        {resumeStatus === 'visible' ? (
          <InfoOutlinedIcon
            onClick={() => setResumeDialog(true)}
            className='cursor-pointer ml-1'
            size={16}
          />
        ) : null}
      </div>

      <p className='text-gray-500 text-sm my-2'>
        Uploading your resume will complete your profile.
      </p>
      <hr />
      <button
        className='px-2 py-2 my-2 font-medium w-full justify-center space-x-2 flex items-center border-2 bg-DarkBluePurple text-white rounded-lg'
        onClick={() => {
          history.push('/home/upload-cv');
        }}
      >
        <FiUpload />
        <span>Upload Resume</span>
      </button>

      <Dialog
        open={resumeDialog}
        onClose={() => setResumeDialog(false)}
        width
        dashboard={true}
        title='Re-upload Resume'
      >
        <Grid direction='row'>
          <p>Re-upload your resume up to 5 MB of file size.</p>
        </Grid>
      </Dialog>
    </div>
  );
};

export default ResumeSection;
