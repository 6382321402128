import React, { useMemo, useState } from 'react';

import EmptyCard from 'src/Components/v1/EmptyCard';
import SearchInput from 'src/Components/v1/SearchInput';
import { filterUpskillsByQuery, getEmptyCardText } from 'src/Utils/utils';

import UpskillCard from '../../Components/v1/UpskillsCard';

const SavedCourses = ({ userCourses }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredUpskills = useMemo(() => {
    return filterUpskillsByQuery(userCourses, searchQuery);
  }, [userCourses, searchQuery]);

  return (
    <>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search by courses by skill'
        label='Search Courses :'
      />

      <div
        className={`${
          filteredUpskills?.length
            ? 'grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3'
            : 'flex flex-wrap'
        } mt-5`}
      >
        {filteredUpskills?.length ? (
          filteredUpskills?.map((courses, index) => (
            <>
              <div key={index} className=' my-3 '>
                <UpskillCard upskill={courses} saved={true} />
              </div>
            </>
          ))
        ) : (
          <EmptyCard
            text={getEmptyCardText(
              userCourses?.length,
              filteredUpskills,
              searchQuery,
              'No Favourite Upskills to show',
            )}
          />
        )}
      </div>
    </>
  );
};

export default SavedCourses;
