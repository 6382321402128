import React from 'react';

const ProfileEmptyCard = ({ placeholderImage, placeholderText }) => {
  return (
    <div className='flex justify-center w-full'>
      <div>
        <div className='flex justify-center w-full mb-2'>
          <img src={placeholderImage} />
        </div>
        <p className='text-gray-400 font-medium'>{placeholderText}</p>
      </div>
    </div>
  );
};

export default ProfileEmptyCard;
