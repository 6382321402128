import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Hidden, SvgIcon, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { FILE_SIZES, PAYMENT_STATUS } from 'src/Constants/Common';
import { AppContext, HomeContext } from 'src/Contexts';

import linkedInImage from '../../Assets/images/profile/leftpanel.png';
import uploadingGif from '../../Assets/images/uploading-animation.gif';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '50%',
    paddingTop: '20vh',
  },
  uploadIcon: {
    width: '30px',
    height: '30px',
    marginTop: theme.spacing(2),
  },
  uploadContainer: {
    margin: '27px',
    width: 'inherit',
    [theme.breakpoints.up('xl')]: {
      minHeight: '200px',
    },
  },
  padding: {
    margin: theme.spacing(1),
  },
  rowContainer: {
    marginTop: theme.spacing(4),
  },
  card: {
    backgroundColor: 'transparent',
    border: '1px solid #c4c4c4',
    boxShadow: 'unset',
  },
  cardLeft: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
  },
}));

const LinkedInResume = (props) => {
  const classes = useStyles(props);
  const [resumeSizeError, setResumeSizeError] = useState('');
  const { setResumeError, resumeError } = useContext(HomeContext);
  const { userPayment } = useContext(AppContext);

  const isUserPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;

  const fileSize = isUserPremium
    ? FILE_SIZES.PREMIUM_USER
    : FILE_SIZES.FREE_USER;

  const [isUploading, setUploading] = useState(false);
  let upload;

  return (
    <Grid
      className='lg:ml-[500px] !w-1/2 md:mt-20 mx-auto'
      container
      direction={'column'}
      alignItems={'center'}
      justify={'center'}
    >
      <Grid item>
        <Typography align={'center'} variant={'h3'}>
          Import your Linkedin Profile
        </Typography>
      </Grid>

      <Grid
        className={classes.rowContainer}
        item
        container
        direction={'row'}
        alignItems={'flexStart'}
      >
        <Grid style={{ margin: '1vh', textAlign: 'center' }} xs={12} item>
          {isUploading ? <img alt='uploading-gif' src={uploadingGif} /> : <></>}
        </Grid>
        <Hidden smDown>
          <Grid item xl={5} md={5}>
            <Card
              variant='outlined'
              square
              classes={{ root: classes.cardLeft }}
            >
              <img style={{ width: '100%' }} src={linkedInImage} alt='' />
            </Card>
          </Grid>

          <Grid
            item
            xl={1}
            md={1}
            align={'center'}
            style={{ marginTop: '5rem' }}
          >
            <ArrowForwardIcon />
          </Grid>
        </Hidden>

        <Grid item xl={5} md={5} sm={12}>
          <Card variant='outlined' square classes={{ root: classes.card }}>
            <Grid
              className={classes.uploadContainer}
              container
              justify={'center'}
              alignItems={'center'}
              direction={'column'}
            >
              <Grid item>
                <SvgIcon className={classes.uploadIcon}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 39 41'
                  >
                    <path
                      fill='#5C5B5D'
                      d='M20.5 32.262V4.36l11.958 11.958 1.709-1.512L19.36 0 4.556 14.806l1.708 1.512L18.222 4.36v27.902H20.5zM0 34.167h2.278v4.555h34.166v-4.555h2.278V41H0v-6.833z'
                    />
                  </svg>
                </SvgIcon>
              </Grid>

              <Grid item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Typography variant={'subtitle1'}>
                  Please select your resume to upload
                </Typography>
              </Grid>

              <Grid item className={classes.padding}>
                <input
                  id='fileInput'
                  type='file'
                  accept='application/pdf'
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) return;
                    // eslint-disable-next-line prefer-destructuring
                    const selectedFile = e.target.files[0];

                    if (selectedFile.size > fileSize * 1024 * 1024) {
                      setResumeError('');
                      setResumeSizeError(
                        `File size exceeds the ${fileSize}MB limit.`,
                      );
                      props.onUploadFailed(
                        `File size exceeds the ${fileSize}MB limit.`,
                      );
                      return;
                    }

                    if (selectedFile.name.split('.').pop() !== 'pdf') {
                      props.onUploadFailed('Please Select a .pdf format');
                      return;
                    }
                    setUploading(true);
                    props
                      .onUpload(selectedFile)
                      .then(
                        (res) => {
                          props.onUploadComplete(res);
                        },
                        () => {
                          props.onUploadFailed(
                            'Please use a format which is not from LinkedIn',
                          );
                        },
                      )
                      .finally(() => {
                        setUploading(false);
                      });
                  }}
                  ref={(ref) => (upload = ref)}
                  style={{ display: 'none' }}
                />

                <Button
                  variant='outlined'
                  size='small'
                  disabled={isUploading}
                  fullWidth={true}
                  color={'primary'}
                  onClick={() => {
                    upload.click();
                  }}
                >
                  Choose File to Upload
                </Button>
                {resumeSizeError && (
                  <Typography
                    variant={'subtitle1'}
                    className='!text-red-500 !mt-2'
                    align={'center'}
                  >
                    {resumeSizeError}
                  </Typography>
                )}
                {resumeError && (
                  <Typography
                    variant={'subtitle1'}
                    className='!text-red-500 !mt-2'
                    align={'center'}
                  >
                    {resumeError}
                  </Typography>
                )}
                <Typography
                  variant={'subtitle1'}
                  className='!text-gray-400 !mt-2'
                  align={'center'}
                >
                  Note: Upload resume upto {fileSize} MB.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid
        style={{ textAlign: 'center', marginTop: '5px' }}
        item
        container
        direction={'row'}
        alignItems={'flexStart'}
        justify={'center'}
        spacing={2}
      >
        <Grid item direction={'column'} xl={4} md={4}>
          <Typography variant={'h4'}>Step 1</Typography>
          <Typography variant={'caption'}>
            Log in to your LinkedIn profile page
          </Typography>
        </Grid>
        <Grid item direction={'column'} xl={4} md={4}>
          <Typography variant={'h4'}>Step 2</Typography>
          <Typography variant={'caption'}>
            <span>
              Click the <b>More...</b> button and select <b>Save to PDF</b>
            </span>
          </Typography>
        </Grid>
        <Grid item direction={'column'} xl={4} md={4}>
          <Typography variant={'h4'}>Step 3</Typography>
          <Typography variant={'caption'}>
            Upload your LinkedIn profile
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

LinkedInResume.propTypes = {
  onUpload: PropTypes.any,
  onUploadComplete: PropTypes.func,
  onUploadFailed: PropTypes.func,
  prevFiles: PropTypes.array,
  onDelete: PropTypes.func,
  onAction: PropTypes.func,
};

LinkedInResume.defaultProps = {
  prevFiles: [],
  onDelete: () => {},
};

export default LinkedInResume;
