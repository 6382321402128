import React from 'react';

import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';

const UpgradePlanDialog = ({ isOpen, onClose, onUpgrade, status }) => {
  return (
    <Dialog title={`${status} Plan`} open={isOpen} onClose={() => onClose()}>
      <div>
        <h1>Are you sure you want to {status} your plan?</h1>
        <div className='flex justify-end'>
          <Button onClick={onUpgrade} title={status} />
        </div>
      </div>
    </Dialog>
  );
};

export default UpgradePlanDialog;
