import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { COMMON } from 'src/Constants';
import { AppContext } from 'src/Contexts';

import CertificatesAndCoursesForm from './CertificatesAndCoursesForm';
import Dialog from './Dialog';

const CertificateAndCourseInputDialog = (props) => {
  const context = useContext(AppContext);

  const onSubmit = async (data) => {
    if (!data.title) {
      return context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
    await props.onSubmitted(data);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title='Add Your Certificates & Courses'
    >
      <CertificatesAndCoursesForm
        id={props.id}
        locationText={props.locationText}
        endYear={props.endYear}
        startYear={props.startYear}
        grade={props.grade}
        fieldOfStudy={props.fieldOfStudy}
        title={props.title}
        onSubmitted={onSubmit}
      />
    </Dialog>
  );
};

CertificateAndCourseInputDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func,
  title: PropTypes.string,
  fieldOfStudy: PropTypes.object,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  grade: PropTypes.string,
  locationText: PropTypes.string,
};

export default CertificateAndCourseInputDialog;
