import React from 'react';
import { useHistory } from 'react-router-dom';

const OverlayCard = () => {
  const history = useHistory();

  return (
    <div className='absolute inset-0 w-full h-full flex items-center justify-center bg-opacity-20 bg-white backdrop-blur-sm'>
      <div className='flex flex-col items-center space-y-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='w-6 h-6'
        >
          <rect x='3' y='11' width='18' height='11' rx='2' ry='2' />
          <path d='M7 11V7a5 5 0 0 1 10 0v4' />
        </svg>
        <h3
          className='text-xl font-bold text-BluePurple cursor-pointer'
          onClick={() => history.push('/home/payment')}
        >
          Upgrade to premium to view
        </h3>
      </div>
    </div>
  );
};

export default OverlayCard;
