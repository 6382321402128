import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { COMMON } from 'src/Constants';

import { AppContext } from '../Contexts';

import YearAndMonthSelector from './OnBoarding/monthAndYearSelector';
import UploadFile from './Profile/UploadFile';
import Button from './Button';

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
  },
}));

const ProjectForm = (props) => {
  const classes = useStyles();

  const context = useContext(AppContext);
  const [isCurrent, setIsCurrent] = useState(props.isCurrent);
  const [title, setTitle] = useState(props.title);
  const [company, setCompany] = useState(props.company_name);
  const [link, setLink] = useState(props.link);
  const [startYear, setStartYear] = useState(
    props.startUTC !== null ? props.startUTC : props.startYear,
  );
  const [endYear, setEndYear] = useState(
    props.endUTC !== null ? props.endUTC : props.endYear,
  );
  const [image, setImage] = useState(props.image);

  const onSubmit = async () => {
    if (title && company && (endYear || isCurrent) && startYear) {
      await props.onSubmitted({
        id: props.id,
        title,
        company,
        link,
        image,
        startYear: new Date(startYear).getFullYear(),
        endYear: isCurrent !== true ? new Date(endYear).getFullYear() : null,
        startUTC: new Date(startYear).toUTCString(),
        endUTC: isCurrent !== true ? new Date(endYear).toUTCString() : null,
      });
    } else {
      context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormGroup>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <Typography variant='subtitle1'>
                Drag & Drop your picture here or
              </Typography>
              <div className='flex'>
                <div className='w-fit'>
                  <UploadFile
                    typography={
                      <Link
                        onClick={() => {}}
                        style={{ cursor: 'pointer', marginLeft: '6px' }}
                      >
                        Browse
                      </Link>
                    }
                    image={image}
                    onImageUploaded={(image) => setImage(image)}
                  />
                </div>
                <Typography variant='subtitle1' className='px-1 pt-1'>
                  to upload
                </Typography>
              </div>
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                variant='outlined'
                autoFocus={true}
                fullWidth={true}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label='Project Name*'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                variant='outlined'
                fullWidth={true}
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                label='Company / Client*'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                variant='outlined'
                fullWidth={true}
                value={link}
                onChange={(event) => setLink(event.target.value)}
                label='Project URL'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearAndMonthSelector
                value={startYear}
                placeholder='Starting Year*'
                onChange={setStartYear}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearAndMonthSelector
                value={endYear}
                placeholder={isCurrent ? 'Disabled' : 'Completion Year*'}
                minDate={new Date(startYear)}
                disabled={isCurrent}
                onChange={(value) => {
                  setEndYear(value);
                }}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={isCurrent}
                    onChange={() => {
                      setIsCurrent(!isCurrent);
                      setEndYear(null);
                    }}
                    value={'current'}
                  />
                }
                label='On Going'
              />
            </Grid>
            <Grid xs={12} direction='row' justify='flex-end' item container>
              <Button
                onClick={onSubmit}
                title={props.id === -1 ? 'Save' : 'Update'}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </MuiPickersUtilsProvider>
    </div>
  );
};

ProjectForm.propTypes = {
  id: PropTypes.any,
  isCurrent: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.string,
  startYear: PropTypes.string,
  startUTC: PropTypes.any,
  endYear: PropTypes.string,
  endUTC: PropTypes.any,
  company: PropTypes.string,
  onSubmitted: PropTypes.func,
};

ProjectForm.defaultProps = {
  id: -1,
  isCurrent: false,
  startYear: null,
  startUTC: null,
  endYear: null,
  endUTC: null,
  onSubmitted: () => {},
};

export default withRouter(ProjectForm);
