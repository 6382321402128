/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

import GetPaymentHistory from 'src/Api/GetUserPayment';
import Refer from 'src/Api/Refer';
import Stripe from 'src/Api/Stripe';
import Payment from 'src/Api/UserPayment';
import { EasyPaisa, Stripe as StripeLogo } from 'src/Assets/images/dashboard';
// import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';
import PaymentCard from 'src/Components/PaymentCard';
import PlanBanner from 'src/Components/v1/PlanBanner';
import {
  COMMON,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  PLAN_INFO,
  PREMIUM_PLAN,
} from 'src/Constants/Common';
import { AppContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import {
  facebookEvent,
  findPlanByPaymentId,
  formatDate,
} from 'src/Utils/utils';

import { Auth as AuthConstants } from '../../Constants';

import CancelSubscriptionDialog from './components/CancelSubscriptionDialog';
import EasyPaisaDialog from './components/EasyPaisaDialog';
import PromoCodeDialog from './components/PromoCodeDialog';
import RemovePromoCodeDialog from './components/RemovePromoDialog';
import SuccessfulPaymentDialog from './components/SuccessfulPaymentDialog';
import UpgradePlanDialog from './components/UpgradePlanDialog.js';

const PaymentPlan = ({ shouldChangeRoute, onRouteChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [selectPaymentMethodModal, setSelectPaymentMethodModal] =
    useState(false);
  const [easyPaisaModal, setEasyPaisaModal] = useState(false);
  const [removeDiscountModal, setRemoveDiscountModal] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [determineStatus, setDetermineStatus] = useState('');
  const [cancelationHistory, setCancelationHistory] = useState('');
  const [discountStatus, setDiscountStatus] = useState('');
  const [referralDiscount, setReferralDiscount] = useState('');
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  const [cookies] = useCookies([AuthConstants.JWT_TOKEN, 'currencyData']);
  const token = cookies[AuthConstants.JWT_TOKEN];
  const { user, userPayment, fetchUserPayment } = useContext(AppContext);
  const userData = localStorage.getItem('loginData');
  const parsedData = JSON.parse(userData);

  const isPremium = userPayment?.payment_status === PAYMENT_STATUS.PAID;
  const cancelationDate = new Date(cancelationHistory?.cancel_at * 1000);
  const currentDate = new Date();
  const isRemainingPeriod = cancelationDate >= currentDate;
  const verifiedEmail = user?.email_verified;
  const history = useHistory();
  const currentPath = history.location.pathname;

  const getButtonText = (plan) => {
    if (isRemainingPeriod && userPayment.payment_id === plan.priceId) {
      return `Valid Upto ${formatDate(cancelationDate / 1000)}`;
    }
    if (!userPayment?.payment_id) {
      return 'Upgrade';
    }
    if (
      userPayment.payment_id === plan.priceId &&
      userPayment.payment_method === PAYMENT_METHODS.EASY_PAISA
    ) {
      return 'You are on this plan';
    }
    if (userPayment.payment_id === plan.priceId) {
      return 'Cancel Subscription';
    }
    if (
      PREMIUM_PLAN.findIndex((p) => p.priceId === userPayment.payment_id) >
      PREMIUM_PLAN.findIndex((p) => p.priceId === plan.priceId)
    ) {
      return 'Downgrade';
    }

    return 'Upgrade';
  };

  const paymentCardHandler = (plan) => {
    const { payment_id, payment_method } = userPayment || {};

    if (
      payment_id === plan.priceId &&
      payment_method === PAYMENT_METHODS.EASY_PAISA
    ) {
      return () => {};
    }

    if (payment_id === plan.priceId) {
      return () =>
        cancelHandler(plan.priceId, plan.title, plan.amount, plan.amountPKR);
    }

    return () =>
      checkoutHandler(plan.priceId, plan.title, plan.amount, plan.amountPKR);
  };

  const planName = PREMIUM_PLAN.find(
    (plan) => plan.priceId === userPayment?.payment_id,
  );

  const paymentStatusHandler = async () => {
    const response = await Stripe.checkPaymentStatus(token);
    setCancelationHistory(response?.data?.data);
  };

  const paymentHandler = async () => {
    if (shouldChangeRoute) {
      const isSuccessPath = currentPath.includes('/successful');

      if (isSuccessPath) {
        try {
          const response = await GetPaymentHistory.UserPayment(token);

          const plan = findPlanByPaymentId(response?.data?.data?.payment_id);
          facebookEvent('Subscribe', {
            value: plan?.amount || '0.00',
            currency: 'USD',
          });

          facebookEvent('Purchase', {
            value: plan?.amount || '0.00',
            currency: 'USD',
          });
          const resumeStatus = localStorage.getItem('resumeStatus');
          if (resumeStatus === 'true') {
            localStorage.setItem('resumeStatus', 'visible');
          }
          setIsModalOpen(true);

          // window.history.replaceState(null, 'SkilledScore', '/home/payment');
          fetchUserPayment();

          setTimeout(() => {
            fetchUserPayment();
          }, 2000);

          onRouteChange();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const stripePaymentHandler = async (
    priceId,
    planTitle,
    status = 'initial',
  ) => {
    try {
      facebookEvent('InitiateCheckout');
      const data = await Stripe.payment(token, {
        email: user.email,
        userId: parsedData.user.id,
        priceId,
        planTitle,
        currency: userLocation === 'PK' ? 'PKR' : 'USD',
      });
      FirebaseAnalyticsEvent('subscription_plan', { type: planTitle, status });

      const stripeCheckoutUrl = data.data.data.url;
      paymentStatusHandler();
      window.location.href = stripeCheckoutUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const determineUpgradeStatus = (clickedPriceId) => {
    const currentIndex = PREMIUM_PLAN.findIndex(
      (p) => p.priceId === userPayment.payment_id,
    );
    const newIndex = PREMIUM_PLAN.findIndex(
      (p) => p.priceId === clickedPriceId,
    );
    const checkPlan = currentIndex > newIndex ? 'Downgrade' : 'Upgrade';
    setDetermineStatus(checkPlan);
    return checkPlan;
  };

  const cancelSubscription = async () => {
    try {
      const data = await Stripe.cancelPayment(token, {
        email: user.email,
        userId: parsedData.user.id,
        priceId: planDetails.priceId,
        subscription_id: userPayment?.subscription_id,
      });
      if (data) {
        FirebaseAnalyticsEvent('cancel_subscription', {
          type: planDetails.planTitle,
        });
        facebookEvent('cancel_subscription', {
          type: planDetails.planTitle,
        });
        setIsCancelModal(false);
        fetchUserPayment();
        paymentStatusHandler();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cancelHandler = (priceId, planTitle, amount, amountPKR) => {
    setIsCancelModal(true);
    setPlanDetails({ priceId, planTitle, amount, amountPKR: amountPKR || '' });
  };

  const stripeHandler = () => {
    if (userPayment?.payment_id) {
      determineUpgradeStatus(planDetails.priceId, planDetails.planTitle);
      setIsUpgradePlan(true);
    } else {
      stripePaymentHandler(planDetails.priceId, planDetails.planTitle);
    }
  };

  const checkoutHandler = (priceId, planTitle, amount, amountPKR) => {
    setPlanDetails({ priceId, planTitle, amount, amountPKR: amountPKR || '' });
    if (userPayment?.payment_id) {
      determineUpgradeStatus(priceId, planTitle);
      setIsUpgradePlan(true);
    } else {
      setSelectPaymentMethodModal(true);
    }
  };

  const getDiscount = async () => {
    const response = await Refer.getDiscount(token);
    setDiscountStatus(response?.data?.data?.response?.promo_discount);
    setReferralDiscount(response?.data?.data?.totalReferralDiscount);
  };

  const removeDiscountHandler = async () => {
    const response = await Refer.removeDiscount(token);
    if (response) {
      getDiscount();
      setRemoveDiscountModal(false);
    }
  };

  const checkEasyPaisaPlanExpiration = async () => {
    await Payment.checkEasyPaisaPlanExpiration(token, userPayment.order_id);
  };

  useEffect(() => {
    paymentHandler();
  }, [currentPath, shouldChangeRoute]);

  useEffect(() => {
    if (!verifiedEmail) {
      history.push('/home');
    }
  }, [verifiedEmail, history]);

  useEffect(() => {
    if (isPremium) {
      paymentStatusHandler();
      if (userPayment.payment_method === PAYMENT_METHODS.EASY_PAISA) {
        checkEasyPaisaPlanExpiration();
      }
    }
  }, [isPremium]);

  useEffect(() => {
    setCurrencyLoading(true);
    const fetchUserLocation = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GEO_LOCATION_API);
        const data = await response.json();

        setUserLocation(data?.data?.country === 'PK' ? 'PK' : 'Somewhere else');
        setCurrencyLoading(false);
      } catch (error) {
        console.error('Error fetching user location:', error);
        setUserLocation('Somewhere else');
      }
    };
    fetchUserLocation();
  }, []);

  useEffect(() => {
    getDiscount();
  }, []);

  return (
    <>
      <div>
        <PlanBanner
          userPayment={userPayment}
          cancelationHistory={cancelationHistory}
          planName={planName}
        />
        <div className='w-11/12 mx-auto md:w-full'>
          <div className='flex justify-between items-center flex-wrap'>
            <div className='flex flex-wrap items-center'>
              {discountStatus ? (
                <span className='mt-3 font-bold bg-green-100 text-green-800 px-4 py-2 rounded-2xl flex justify-center items-center'>
                  {discountStatus}% promotional discount
                  <AiOutlineCloseCircle
                    className='ml-1 cursor-pointer'
                    size='20'
                    onClick={() => setRemoveDiscountModal(true)}
                  />
                </span>
              ) : (
                <p
                  className='mt-3 font-bold ml-1 text-DarkBluePurple cursor-pointer'
                  onClick={() => setPromoCodeModal(true)}
                >
                  Apply Promo Code
                </p>
              )}
              {referralDiscount ? (
                <span className='mt-3 md:ml-2 font-bold bg-[#D6EDFF] text-[#3e83d0] px-4 py-2 rounded-2xl flex justify-center items-center'>
                  {referralDiscount}% affiliate discount
                </span>
              ) : null}
            </div>

            <p className='mt-3 text-right font-semibold text-gray-600'>
              {COMMON.NO_REFUND}
            </p>
          </div>

          <div className='flex flex-wrap justify-center items-center my-10'>
            {[
              ...PREMIUM_PLAN.filter(
                (plan) => plan.title === 'One Time Payment',
              ),
            ].map((plan) => (
              <PaymentCard
                key={plan.title}
                planInfo={PLAN_INFO}
                title={plan.title}
                duration={plan.duration}
                amount={plan.amount}
                amountPKR={plan.amountPKR}
                userLocation={userLocation}
                currencyLoading={currencyLoading}
                disable={
                  isRemainingPeriod && userPayment?.payment_id === plan.priceId
                }
                isActive={userPayment?.payment_id === plan.priceId}
                buttonText={getButtonText(plan)}
                onClick={paymentCardHandler(plan)}
              />
            ))}
          </div>
        </div>
      </div>

      <PromoCodeDialog
        isOpen={promoCodeModal}
        onClose={() => setPromoCodeModal(false)}
        setDiscountStatus={setDiscountStatus}
        setPromoCodeModal={setPromoCodeModal}
      />

      <RemovePromoCodeDialog
        isOpen={removeDiscountModal}
        onClose={() => setRemoveDiscountModal(false)}
        onRemove={removeDiscountHandler}
      />
      <SuccessfulPaymentDialog
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <UpgradePlanDialog
        isOpen={isUpgradePlan}
        onClose={() => setIsUpgradePlan(false)}
        onUpgrade={() => {
          setSelectPaymentMethodModal(true);
          setIsUpgradePlan(false);
        }}
        status={determineStatus}
      />
      <CancelSubscriptionDialog
        isOpen={isCancelModal}
        onClose={() => setIsCancelModal(false)}
        onCancel={cancelSubscription}
      />
      <Dialog
        title='Select Payment Method'
        open={selectPaymentMethodModal}
        onClose={() => setSelectPaymentMethodModal(false)}
      >
        <div className='flex flex-col justify-around md:flex-row'>
          <div
            className='border flex justify-center items-center px-4 bg-purple-50 border-purple-600 cursor-pointer rounded-2xl h-16'
            onClick={() =>
              userPayment?.payment_id
                ? stripePaymentHandler(
                    planDetails?.priceId,
                    planDetails?.planTitle,
                  )
                : stripeHandler()
            }
          >
            <img src={StripeLogo} className='w-40 h-16 ' />
          </div>
          {userLocation === 'PK' && discountStatus < 100 ? (
            <div
              className='border px-4 border-green-500 bg-green-50 cursor-pointer rounded-2xl flex justify-center items-center h-16 mt-4 md:mt-0'
              onClick={() => {
                setEasyPaisaModal(true);
                setSelectPaymentMethodModal(false);
              }}
            >
              <img src={EasyPaisa} className='w-40 h-10 ' />
            </div>
          ) : null}
        </div>
      </Dialog>
      <EasyPaisaDialog
        easyPaisaModal={easyPaisaModal}
        setEasyPaisaModal={setEasyPaisaModal}
        planDetails={planDetails}
        discount={discountStatus + referralDiscount}
        setIsModalOpen={setIsModalOpen}
        subscriptionId={userPayment?.subscription_id}
        getDiscount={getDiscount}
        paymentStatusHandler={paymentStatusHandler}
      />
    </>
  );
};

export default PaymentPlan;
