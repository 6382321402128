import React from 'react';

import Button from 'src/Components/Button';
import Dialog from 'src/Components/Dialog';

const SuccessfulPaymentDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog title='Payment Successful' open={isOpen} onClose={() => onClose()}>
      <div>
        <h1>Your payment has been successful</h1>
        <div className='flex justify-end'>
          <Button onClick={() => onClose()} title='Close' />
        </div>
      </div>
    </Dialog>
  );
};

export default SuccessfulPaymentDialog;
