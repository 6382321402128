import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, FormGroup, Grid, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
  },
  summaryTextBox: {
    height: '100%',
  },
}));

const SummaryForm = (props) => {
  const classes = useStyles();

  const [summary, setSummary] = useState();

  return (
    <div className={classes.container}>
      <FormGroup>
        <Grid container>
          <Grid xs={12} item>
            <OutlinedInput
              autoFocus={true}
              fullWidth={true}
              value={summary}
              onChange={(event) => setSummary(event.target.value)}
              defaultValue={props.summary}
              multiline
              placeholder='Write Summary'
              margin='dense'
              notched={false}
              labelWidth={200}
              rows='12'
              className={classes.summaryTextBox}
            />
          </Grid>
          <Grid xs={12} direction='row' justify='flex-end' item container>
            <Button
              size='medium'
              variant='contained'
              color='primary'
              onClick={() => {
                props.onSubmitted({ summary });
              }}
              className={classes.textCaseTransform}
            >
              {props.isNewSummary ? 'add' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  );
};

SummaryForm.propTypes = {
  summary: PropTypes.string,
  isNewSummary: PropTypes.bool,
  onSubmitted: PropTypes.func,
};

SummaryForm.defaultProps = {
  isNewSummary: false,
  onSubmitted: () => {},
};

export default withRouter(SummaryForm);
